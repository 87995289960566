import React, { useState } from 'react';

const Modal = (props) => {
  const data = props.data;
  console.log(data);
  const submit = (e) => {
    e.preventDefault();
  };
  const [formData, setFormData] = useState({
    telephone: data.telephone,
    'contact-email': data['contact-email'],
    'location-url': data['location-url'],
    'location-name': data['location-name'],
    address1: data['address1'],
    town: data['town'],
    region: data['region'],
    country: data['country'],
    postcode: data['postcode'],
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    data.address = formData.address1
    data.business_name = formData["location-name"]
    data.city = formData.town
    data.country = formData.country
    data.email = formData["contact-email"]
    data.phone_number =formData.telephone
      data.postcode = formData.postcode
    data.state = formData.region
    data.website_url = formData["location-url"]
    // JSON.stringify({ formData })
    // console.log(dataval)
    const res = await fetch(
      'https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=website_stats_edit',
      {
        method: 'post',
        body: JSON.stringify({data}),

      },
        
    );
     const json = await res.json();
    console.log(json.status)
    if(json.status=="success"){
      alert(json.message)
          props.setPopup(false);
    }
    else{
      props.setPopup(true);
    }
    // const json = await res.json();

    // if (json.status) {
    //   alert('Data Submitted Successfully', 'info');
    //   // window.location.href="website_stats";
    //   props.loadpopup();
    // }
  };

  return (
    <div className="modal12 fixed inset-0 flex items-center justify-center overflow-y-auto ">
      {/* <div className="fixed inset-0 bg-gray-800 bg-opacity-20 backdrop-filter backdrop-blur-sm"></div> */}
      <div className="fixed inset-0 bg-gray-800 bg-opacity-20 backdrop-blur-sm backdrop-filter"></div>

      {/* Modal content */}
      <div className="relative mt-5 h-[450px] lg:h-[500px] 2xl:h-[600px] 3xl:h-[850px] lg:w-1/2 overflow-auto rounded bg-white p-8 shadow-md">
        <button
          className="absolute right-2 top-2 text-gray-600 hover:text-gray-800"
          onClick={props.loadpopup}
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        <form action="#" class="w-full" method="POST" onSubmit={submit}>
          <div class="grid grid-cols-1 gap-3">
            <div class="w-full">
              <label
                for=""
                class="mb-1 block text-sm font-medium text-gray-900"
              >
                Phone
              </label>
              <input
                type="text"
                value={formData['telephone']}
                onChange={handleChange}
                name="telephone"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div class="w-full">
              <label
                for="email"
                class="mb-1 block text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                value={formData['contact-email']}
                onChange={handleChange}
                name="contact-email"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div class="w-full">
              <label
                for=""
                class="mb-1 block text-sm font-medium text-gray-900"
              >
                Website
              </label>
              <input
                type="text"
                value={formData['location-url']}
                onChange={handleChange}
                name="location-url"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <h4 class="font-bold">Address</h4>

            <div class="w-full">
              <label
                for=""
                class="mb-1 block text-sm font-medium text-gray-900"
              >
                Name
              </label>
              <input
                type="text"
                value={formData['location-name']}
                onChange={handleChange}
                name="location-name"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div class="w-full">
              <label
                for=""
                class="mb-1 block text-sm font-medium text-gray-900"
              >
                Address
              </label>
              <input
                type="text"
                value={formData['address1']}
                onChange={handleChange}
                name="address1"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div class="w-full">
              <label
                for=""
                class="mb-1 block text-sm font-medium text-gray-900"
              >
                City
              </label>
              <input
                type="text"
                value={formData['town']}
                onChange={handleChange}
                name="town"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div class="w-full">
              <label
                for=""
                class="mb-1 block text-sm font-medium text-gray-900"
              >
                State
              </label>
              <input
                type="text"
                value={formData['region']}
                onChange={handleChange}
                name="region"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div class="w-full">
              <label
                for=""
                class="mb-1 block text-sm font-medium text-gray-900"
              >
                Country
              </label>
              <input
                type="text"
                value={formData['country']}
                onChange={handleChange}
                name="country"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div class="w-full">
              <label
                for=""
                class="mb-1 block text-sm font-medium text-gray-900"
              >
                Postcode
              </label>
              <input
                type="text"
                value={formData['postcode']}
                onChange={handleChange}
                name="postcode"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div class="w-full">
              <button
                type="submit"
                class="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
