import React, { useEffect, useState } from 'react';
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Card from "components/card";
import axios from 'axios';

const Modal = (props) => {
  //state
  let user = JSON.parse(localStorage.getItem('auth'));

  const [username,setusername]=useState('');

  const [adminemail,setadminemail]=useState(user?.email);

  useEffect(() => {      
    const fetchData = async () => {
      try {
        console.log(user)
        setadminemail(user?.email);
        // Perform your asynchronous task here
        const response = await fetch(`https://dcp-backend.onrender.com/wrapper/reviewURLfindone?username=${user?.email}`);
        const result = await response.json();
        setusername(result?.username)
        console.log(result)
        // setfname(result?.fname);
        // setlname(result?.lname);
        // setemail(result?.email);
        // setpnumber(result?.pnumber);
        // setMessage(result?.message);
        // setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }; 
    console.log(props);

    // Call the async function inside useEffect
    fetchData();
   
    // Since this is a cleanup function, you can return a function
    // to clean up any resources or subscriptions if necessary
    return () => {
      // Cleanup code here, if needed
    };
  }, [])
   
   const handleChangeusername=(e)=>{
    console.log(e.target.value)
    setadminemail(user?.email);
    setusername(e.target.value);
   }



 const handleSendClick=async()=>{
  try {
    const res = await axios.post(`https://dcp-backend.onrender.com/wrapper/reviewURLUpdate`, {
      username,
      adminemail
    });
    console.log(res);
    props.setToggle2(false);
    // if (res && res.status === 200) {
      alert("Updated successful");
    // } 
  } catch (error) {
    console.log(error);
   alert("Something went wrong");
  }
 }

  return (
    <div className="!z-10 fixed inset-0 flex items-center justify-center overflow-y-auto">
      <div className="!z-10 fixed inset-0 bg-gray-800 bg-opacity-20 backdrop-blur-sm backdrop-filter"></div>


      <div className="!z-10 relative w-1/2 rounded bg-white p-8 shadow-md h-fit text-gray-800" >
        <button
          className="absolute right-2 top-2 text-gray-800 hover:text-gray-800"
          onClick={props.loadremaining1}
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>

        {/* 123 Stars Popup start */}
      <div>
              <Card extra={"w-full px-6 py-6"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Send Notification
        </h4>
     
      </div>
      {/* inputs */}
      <div className="mt-11 grid grid-cols-1 gap-3">
        <InputField
          label="Email"
          placeholder= "email"
          id="username"
          value={username}
          onChange={handleChangeusername}
          type="text"
        />
   
     
        {/* button */}
        <div className="mt-3 flex w-full justify-end">
          <button onClick={handleSendClick} className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Send 
          </button>
        </div>
      </div>
      {/* full width inputs */}
    </Card>
        </div>

     
      </div>

    </div>
  );
};

export default Modal;