import React, { useEffect, useState } from 'react';
import MiniStatistics from '../organic/components/MiniStatistics';
import {
  MdOutlineBarChart,
  MdPerson,
  MdFileCopy,
  MdPhone,
  MdChat,
} from 'react-icons/md';
import ColumnsTable from '../organic/components/ComplexTable';
import { SiGoogleforms } from 'react-icons/si';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  kanbanRenderThumb,
  kanbanRenderTrack,
  kanbanRenderView,
} from 'components/scrollbar/Scrollbar';
import ModalComponent from '../organic/components/Modal';

const VerifiedLeads = () => {
  const [nodatapreview, setnodatapreview] = useState(false);

  let user = JSON.parse(localStorage.getItem('auth'));
  const [user_email, setuser_email] = useState(user?.email);
  const [user_id, setuser_id] = useState(user?.uid);

  useEffect(() => {
    setuser_id(user?.uid);
    setuser_email(user?.email);
  }, []);
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  let response_data_d_change = [];

  let [verified_forms_count, setverified_forms_count] = useState(0);
  let [verified_organic_calls_count, setverified_organic_calls_count] =
    useState(0);
  let [verified_chatbot_count, setverified_chatbot_count] = useState(0);

  const [enquirydisplay, setenquirydisplay] = useState(true);
  const [newleaddisplay, setnewleaddisplay] = useState(false);
  const [noconsultationdisplay, setnoconsultationdisplay] = useState(false);
  let verified_booked_filter = [];
  let verified_booked_filter_calls = [];
  let verified_booked_filter_chatbots = [];
  let verified_forms_count_filter = 0;
  let verified_organic_calls_count_filter = 0;
  let verified_chatbot_count_filter = 0;
  let [data1_dashboard_organic, setdata1_dashboard_organic] = useState([]);
  const [verified_booked, setverified_booked] = useState([]);
  const [verified_booked_Calls, setverified_booked_Calls] = useState([]);
  const [verified_booked_Chatbots, setverified_booked_Chatbots] = useState([]);

  let currentDate = new Date().toJSON().slice(0, 10);
  let open = false;
  let organic_booked_filter = [];
  let organic_newlead_filter = [];
  let organic_no_consultation_filter = [];
  const [organic_booked, setorganic_booked] = useState([]);
  const [columnsDataComplex, setcolumnsDataComplex] = useState([]);
  const [calls_display, setcalls_display] = useState([]);
  const [forms_display, setforms_display] = useState([]);
  const [chatbots, setchatbots] = useState([]);
  // let organic_booked = [];
  // let organic_newlead = [];

  const [organic_newlead, setorganic_newlead] = useState([]);
  const [organic_no_consultation, setorganic_no_consultation] = useState([]);
  // let organic_no_consultation = [];
  const [leadsData, setLeadsData] = useState('');
  async function filter_data(e) {
    const formData = new FormData(e.target);
    const data = {};
    for (let field of formData) {
      const [key, value] = field;
      data[key] = value;
    }
    console.log(data);
    // nodejs
    //  const apiURL_dashboard_channel=`http://192.168.1.11:8003:8003/wrapper/organicfilter`;
    const res_filter = await fetch(
      // `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_leads_new&user_email=${user_email}&user_id=${user_id}&order=sortorder`,
      `https://dcp-backend.onrender.com/wrapper/organicForm?user_email=${user_email}&user_id=${user_id}&order=sortorder`,

      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data,
        }),
      }
    );

    let data1_dashboard_organic1 = await res_filter.json();
    setdata1_dashboard_organic(data1_dashboard_organic1);
    console.log(data1_dashboard_organic1);

    verified_booked_filter = [];
    verified_booked_filter_calls = [];
    verified_booked_filter_chatbots = [];
    verified_forms_count_filter = 0;
    verified_organic_calls_count_filter = 0;
    verified_chatbot_count_filter = 0;

    for (let item of data1_dashboard_organic1.total_forms) {
      // console.log(item.status);
      item.type = 'website_form';
      if (item.status == 'booked' || item.status == 'enquiry') {
        // organic_booked_filter.push(item);
      }
      if (item.status == 'newlead') {
        // organic_newlead_filter.push(item);
        verified_booked_filter.push(item);
        verified_forms_count_filter = verified_forms_count_filter + 1;
      }
      if (
        item.status == 'no_consultation' ||
        item.status == 'existing_patient'
      ) {
        //organic_no_consultation_filter.push(item);
        verified_booked_filter.push(item);

        verified_forms_count_filter = verified_forms_count_filter + 1;
      }
    }

    // verified_forms_count_filter;
    // verified_organic_calls_count_filter;
    for (let item of data1_dashboard_organic1.total_organic_calls) {
      // console.log(item.status);
      item.type = 'phone_call';
      if (item.status == 'booked' || item.status == 'enquiry') {
        // organic_booked_filter.push(item);
      }
      if (item.status == 'newlead') {
        //organic_newlead_filter.push(item);
        verified_booked_filter_calls.push(item);

        verified_organic_calls_count_filter =
          verified_organic_calls_count_filter + 1;
      }
      if (
        item.status == 'no_consultation' ||
        item.status == 'existing_patient'
      ) {
        // organic_no_consultation_filter.push(item);
        verified_booked_filter_calls.push(item);
        verified_organic_calls_count_filter =
          verified_organic_calls_count_filter + 1;
      }
    }
    for (let item of data1_dashboard_organic1.total_chatboat) {
      // console.log(item.status);
      item.type = 'chat_boat';
      if (item.status == 'booked' || item.status == 'enquiry') {
        // organic_booked_filter.push(item);
      }
      if (item.status == 'newlead') {
        // organic_newlead_filter.push(item);
        verified_booked_filter_chatbots.push(item);
        verified_chatbot_count_filter = verified_chatbot_count_filter + 1;
      }
      if (
        item.status == 'no_consultation' ||
        item.status == 'existing_patient'
      ) {
        //organic_no_consultation_filter.push(item);
        verified_booked_filter_chatbots.push(item);
        verified_chatbot_count_filter = verified_chatbot_count_filter + 1;
      }
    }

    setverified_booked(verified_booked_filter);
    setverified_booked_Calls(verified_booked_filter_calls);
    setverified_booked_Chatbots(verified_booked_filter_chatbots);
    setverified_forms_count(verified_forms_count_filter);
    console.log('verified', verified_forms_count);
    setverified_organic_calls_count(verified_organic_calls_count_filter);
    setverified_chatbot_count(verified_chatbot_count_filter);

    console.log(verified_booked);
    console.log(verified_forms_count);
    console.log(verified_organic_calls_count);
    console.log(verified_chatbot_count);

    // organic_booked = [];
  }

  async function change_status(rid, tname, event) {
    console.log(rid, tname, event.target.value);
    const apiURL_change = `https://dcp-backend.onrender.com/wrapper/dashboardstatus?rid=${rid}&status_type=${event.target.value}`;

    // const apiURL_change = "https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=dashboard_change_status_data&id="+rid+"&tname="+tname+'&status='+event.target.value;
    console.log(apiURL_change);
    const response_change = await fetch(apiURL_change);
    response_data_d_change = await response_change.json();
    console.log(response_data_d_change);
    if (response_data_d_change.status) {
      alert('Status Changed Successfully', 'info');
      // window.location.href="/";

      //nodejs
      // const apiURL_dashboard_organic = `http://192.168.1.11:8003:8003/wrapper/organic?user_email=${user_email}&user_id=${user_id}`;

      //php
      // auto load code start
      //    let usr_id = 225;
      const apiURL_dashboard_organic = `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_leads_new&user_email=${user_email}&user_id=${user_id}&order=sortorder`;

      console.log(apiURL_dashboard_organic);
      const response_dashboard_organic = await fetch(apiURL_dashboard_organic);
      let data1_dashboard_organic_autoload =
        await response_dashboard_organic.json();
      let data1_dashboard_organic1 = data1_dashboard_organic_autoload;
      setdata1_dashboard_organic(data1_dashboard_organic);
      console.log(data1_dashboard_organic);

      let organic_booked_autoload = [];
      let organic_newlead_autoload = [];
      let verified_booked_autoload = [];
      let verified_forms_count_autoload = 0;
      let organic_no_consultation_autoload = [];
      let verified_organic_calls_count_autoload = 0;
      let verified_chatbot_count_autoload = 0;
      for (let item of data1_dashboard_organic1.total_forms) {
        // console.log(item.status);
        item.type = 'website_form';
        if (item.status == 'booked' || item.status == 'enquiry') {
          organic_booked_autoload.push(item);
        }
        if (item.status == 'newlead') {
          organic_newlead_autoload.push(item);
          verified_booked_autoload.push(item);
          verified_forms_count_autoload = verified_forms_count_autoload + 1;
        }
        if (
          item.status == 'no_consultation' ||
          item.status == 'existing_patient'
        ) {
          organic_no_consultation_autoload.push(item);
          verified_booked_autoload.push(item);
          verified_forms_count_autoload = verified_forms_count_autoload + 1;
        }
      }
      for (let item of data1_dashboard_organic1.total_organic_calls) {
        // console.log(item.status);
        item.type = 'phone_call';
        if (item.status == 'booked' || item.status == 'enquiry') {
          organic_booked_autoload.push(item);
        }
        if (item.status == 'newlead') {
          organic_newlead_autoload.push(item);
          verified_booked_autoload.push(item);
          verified_organic_calls_count_autoload =
            verified_organic_calls_count_autoload + 1;
        }
        if (
          item.status == 'no_consultation' ||
          item.status == 'existing_patient'
        ) {
          organic_no_consultation_autoload.push(item);
          verified_booked_autoload.push(item);
          verified_organic_calls_count_autoload =
            verified_organic_calls_count_autoload + 1;
        }
      }
      for (let item of data1_dashboard_organic1.total_chatboat) {
        // console.log(item.status);
        item.type = 'chat_boat';
        if (item.status == 'booked' || item.status == 'enquiry') {
          organic_booked_autoload.push(item);
        }
        if (item.status == 'newlead') {
          organic_newlead_autoload.push(item);
          verified_booked_autoload.push(item);
          verified_chatbot_count_autoload = verified_chatbot_count_autoload + 1;
        }
        if (
          item.status == 'no_consultation' ||
          item.status == 'existing_patient'
        ) {
          organic_no_consultation_autoload.push(item);
          verified_booked_autoload.push(item);
          verified_chatbot_count_autoload = verified_chatbot_count_autoload + 1;
        }
      }

      setorganic_booked(organic_booked_autoload);
      setorganic_newlead(organic_newlead_autoload);

      setverified_booked(verified_booked_autoload);
      setverified_forms_count(verified_forms_count_autoload);
      setorganic_no_consultation(organic_no_consultation_autoload);
      setverified_organic_calls_count(verified_organic_calls_count_autoload);
      setverified_chatbot_count(verified_chatbot_count_autoload);

      console.log(organic_booked);
      console.log(organic_newlead);
      console.log(organic_no_consultation);

      if (document.getElementById('outcome_' + rid)) {
        let div_block = document.getElementById('outcome_' + rid);
        div_block.style.display = 'none';
      }
      if (document.getElementById('outcome_v' + rid)) {
        let div_block = document.getElementById('outcome_v' + rid);
        div_block.style.display = 'none';
      }

      // end
    }
  }
  function loadremaining(rev_data) {
    setLeadsData(rev_data);
    openModal();
  }
  function open_popover(id) {
    let div_block = document.getElementById(id);
    // alert(div_block.style.display);
    if (div_block.style.display === 'none' || div_block.style.display === '') {
      div_block.style.display = 'block';
    } else {
      div_block.style.display = 'none';
    }
  }
  async function get_filter() {
    let search_type = document.getElementById('search_by').value;
    if (search_type == 'custom') {
      let element = document.getElementById('custom_search');
      element.classList.remove('hidden');
    } else {
      console.log(search_type);
      let range = dates_calculation(search_type);
      //console.log(range);
      const data = {};
      data['start_date'] = range.end_date;
      data['end_date'] = range.start_date;

      // nodejs
      //  const apiURL_dashboard_channel=`http://192.168.1.11:8003:8003/wrapper/organicfilter`;
      const res_filter = await fetch(
        `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_leads_new&user_email=${user_email}&user_id=${user_id}&order=sortorder`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data,
          }),
        }
      );

      //php
      // let usr_id = 225;
      // const res_filter = await fetch('https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_leads_new&user_email='+user_email+'&user_id='+usr_id, {
      //     method: 'POST',
      //     body: JSON.stringify({
      //         data
      //     })
      // })

      let data1_dashboard_organic1 = await res_filter.json();
      setdata1_dashboard_organic(data1_dashboard_organic1);
      console.log(data1_dashboard_organic1);

      verified_booked_filter = [];
      verified_booked_filter_calls = [];
      verified_booked_filter_chatbots = [];
      verified_forms_count_filter = 0;
      verified_organic_calls_count_filter = 0;
      verified_chatbot_count_filter = 0;

      for (let item of data1_dashboard_organic1.total_forms) {
        // console.log(item.status);
        item.type = 'website_form';
        if (item.status == 'booked' || item.status == 'enquiry') {
          // organic_booked_filter.push(item);
        }
        if (item.status == 'newlead') {
          // organic_newlead_filter.push(item);
          verified_booked_filter.push(item);
          verified_forms_count_filter = verified_forms_count_filter + 1;
        }
        if (
          item.status == 'no_consultation' ||
          item.status == 'existing_patient'
        ) {
          //organic_no_consultation_filter.push(item);
          verified_booked_filter.push(item);

          verified_forms_count_filter = verified_forms_count_filter + 1;
        }
      }

      // verified_forms_count_filter;
      // verified_organic_calls_count_filter;
      for (let item of data1_dashboard_organic1.total_organic_calls) {
        // console.log(item.status);
        item.type = 'phone_call';
        if (item.status == 'booked' || item.status == 'enquiry') {
          // organic_booked_filter.push(item);
        }
        if (item.status == 'newlead') {
          //organic_newlead_filter.push(item);
          verified_booked_filter_calls.push(item);

          verified_organic_calls_count_filter =
            verified_organic_calls_count_filter + 1;
        }
        if (
          item.status == 'no_consultation' ||
          item.status == 'existing_patient'
        ) {
          // organic_no_consultation_filter.push(item);
          verified_booked_filter_calls.push(item);
          verified_organic_calls_count_filter =
            verified_organic_calls_count_filter + 1;
        }
      }
      for (let item of data1_dashboard_organic1.total_chatboat) {
        // console.log(item.status);
        item.type = 'chat_boat';
        if (item.status == 'booked' || item.status == 'enquiry') {
          // organic_booked_filter.push(item);
        }
        if (item.status == 'newlead') {
          // organic_newlead_filter.push(item);
          verified_booked_filter_chatbots.push(item);
          verified_chatbot_count_filter = verified_chatbot_count_filter + 1;
        }
        if (
          item.status == 'no_consultation' ||
          item.status == 'existing_patient'
        ) {
          //organic_no_consultation_filter.push(item);
          verified_booked_filter_chatbots.push(item);
          verified_chatbot_count_filter = verified_chatbot_count_filter + 1;
        }
      }

      setverified_booked(verified_booked_filter);
      setverified_booked_Calls(verified_booked_filter_calls);
      setverified_booked_Chatbots(verified_booked_filter_chatbots);
      setverified_forms_count(verified_forms_count_filter);

      setverified_organic_calls_count(verified_organic_calls_count_filter);
      setverified_chatbot_count(verified_chatbot_count_filter);

      console.log(verified_booked);
      console.log(verified_forms_count);
      console.log(verified_organic_calls_count);
      console.log(verified_chatbot_count);

      // hide search block
      let element = document.getElementById('custom_search');
      element.classList.add('hidden');
    }
  }

  function dates_calculation(days) {
    if (days == '30 days') {
      let currentDate = new Date();
      let endDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
      let formattedStartDate = currentDate.toISOString().slice(0, 10);
      let formattedEndDate = endDate.toISOString().slice(0, 10);
      return {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
    }
    if (days == '60 days') {
      let currentDate = new Date();
      let endDate = new Date(currentDate.getTime() - 60 * 24 * 60 * 60 * 1000);
      let formattedStartDate = currentDate.toISOString().slice(0, 10);
      let formattedEndDate = endDate.toISOString().slice(0, 10);
      return {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
    }
    if (days == '90 days') {
      let currentDate = new Date();
      let endDate = new Date(currentDate.getTime() - 60 * 24 * 60 * 60 * 1000);
      let formattedStartDate = currentDate.toISOString().slice(0, 10);
      let formattedEndDate = endDate.toISOString().slice(0, 10);
      return {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
    }
    if (days == '--') {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      let formattedMonth = month < 10 ? '0' + month : month;
      let formattedStartDate = year + '-' + formattedMonth + '-01';
      let formattedEndDate = currentDate.toISOString().slice(0, 10);

      return {
        start_date: formattedEndDate,
        end_date: formattedStartDate,
      };
    }
  }

  const getverifieddata = async () => {
    // const res=await axios.get( `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_leads_new&user_email=${user_email}&user_id=${user_id}&order=sortorder`,)
    const res = await axios.get(
      `https://dcp-backend.onrender.com/wrapper/organicForm?user_email=${user_email}&user_id=${user_id}&order=sortorder`
    );
    if (!res?.data == '') {
      let data1_dashboard_organic1 = res.data;
      setdata1_dashboard_organic(data1_dashboard_organic1);
      const columnsDataComplexdata = [
        {
          Header: 'Type',
          accessor: 'contact_type',
        },
        {
          Header: 'Name',
          accessor: 'first_name',
        },
        {
          Header: 'Date',
          accessor: 'date',
        },
        {
          Header: 'ContactNumber',
          accessor: 'ph_number',
        },
        {
          Header: 'Email_Id',
          accessor: 'email',
        },
        // {
        //   Header: "Update",
        //   accessor: "",
        // },
        {
          Header: 'Status',
          accessor: 'status',
        },
        {
          Header: 'Review',
          accessor: 'message',
        },
      ];
      setcolumnsDataComplex(columnsDataComplexdata);
      console.log(data1_dashboard_organic1);

      verified_booked_filter = [];
      verified_booked_filter_calls = [];
      verified_booked_filter_chatbots = [];
      verified_forms_count_filter = 0;
      verified_organic_calls_count_filter = 0;
      verified_chatbot_count_filter = 0;

      for (let item of data1_dashboard_organic1.total_forms) {
        // console.log(item.status);
        item.type = 'website_form';
        if (item.status == 'booked' || item.status == 'enquiry') {
          // organic_booked_filter.push(item);
        }
        if (item.status == 'newlead') {
          // organic_newlead_filter.push(item);
          verified_booked_filter.push(item);
          verified_forms_count_filter = verified_forms_count_filter + 1;
        }
        if (
          item.status == 'no_consultation' ||
          item.status == 'existing_patient'
        ) {
          //organic_no_consultation_filter.push(item);
          verified_booked_filter.push(item);

          verified_forms_count_filter = verified_forms_count_filter + 1;
        }
      }

      // verified_forms_count_filter;
      // verified_organic_calls_count_filter;
      for (let item of data1_dashboard_organic1.total_organic_calls) {
        // console.log(item.status);
        item.type = 'phone_call';
        if (item.status == 'booked' || item.status == 'enquiry') {
          // organic_booked_filter.push(item);
        }
        if (item.status == 'newlead') {
          //organic_newlead_filter.push(item);
          verified_booked_filter_calls.push(item);

          verified_organic_calls_count_filter =
            verified_organic_calls_count_filter + 1;
        }
        if (
          item.status == 'no_consultation' ||
          item.status == 'existing_patient'
        ) {
          // organic_no_consultation_filter.push(item);
          verified_booked_filter_calls.push(item);
          verified_organic_calls_count_filter =
            verified_organic_calls_count_filter + 1;
        }
      }
      for (let item of data1_dashboard_organic1.total_chatboat) {
        // console.log(item.status);
        item.type = 'chat_boat';
        if (item.status == 'booked' || item.status == 'enquiry') {
          // organic_booked_filter.push(item);
        }
        if (item.status == 'newlead') {
          // organic_newlead_filter.push(item);
          verified_booked_filter_chatbots.push(item);
          verified_chatbot_count_filter = verified_chatbot_count_filter + 1;
        }
        if (
          item.status == 'no_consultation' ||
          item.status == 'existing_patient'
        ) {
          //organic_no_consultation_filter.push(item);
          verified_booked_filter_chatbots.push(item);
          verified_chatbot_count_filter = verified_chatbot_count_filter + 1;
        }
      }

      setverified_booked(verified_booked_filter);
      setverified_booked_Calls(verified_booked_filter_calls);
      setverified_booked_Chatbots(verified_booked_filter_chatbots);
      setverified_forms_count(verified_forms_count_filter);
      console.log('verified', verified_forms_count);
      setverified_organic_calls_count(verified_organic_calls_count_filter);
      setverified_chatbot_count(verified_chatbot_count_filter);
    } else {
      setnodatapreview(true);
    }
  };
  useEffect(() => {
    getverifieddata();
  }, []);

  async function get_filter_type(e) {
    console.log(e.target.value);
    if (e.target.value == 'form') {
      setenquirydisplay(false);
      setnewleaddisplay(true);
      setnoconsultationdisplay(false);
    } else if (e.target.value == 'chatbots') {
      setenquirydisplay(false);
      setnewleaddisplay(false);
      setnoconsultationdisplay(true);
    } else {
      setenquirydisplay(true);
      setnewleaddisplay(false);
      setnoconsultationdisplay(false);
    }
  }

  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Adjust as needed
  const totalItems = verified_booked.length; // Replace with your actual data length

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const pageCount = Math.ceil(totalItems / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  console.log(pageCount);
  // Example data fetching function (replace with your actual data fetching logic)
  const fetchData = (start, end) => {
    return verified_booked_Calls.slice(start, end);
  };

  function snakeToCamel(str) {
    return str
      .replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', ' ');
      })
      .replace(/\b\w/g, (c) => c.toUpperCase());
  }

  function snakeToCamel1(str) {
    return str.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', ' ');
    });
  }

  return (
    <div className="relative mt-3 h-full w-full rounded-[20px]">
  
      {verified_booked_Calls.length==0&& (
        <div class="absolute left-0 top-0 z-50 flex h-full w-full items-start justify-center bg-white/80 pt-96">
          <div class="  rounded-xl bg-[#d3f26a] p-5 text-center text-lg font-bold">
            There is no data.
            <br />
          </div>
        </div>
      )}
      <div class="grid grid-cols-1 gap-5 px-5 md:grid-cols-3 3xl:grid-cols-4">
        <MiniStatistics
          icon={<MdOutlineBarChart className="text-4xl" />}
          title="Phone calls"
          // value={data1_dashboard_organic?.total_organic_calls_count}
          value={0}
          growth={'+20%'}
          growthColor="text-white"
          cardBg="bg-gradient-to-r from-brand-400 to-brand-600"
          titleColor="text-white"
          valueColor="text-white"
          bgColor="bg-gradient-to-r from-brand-600 to-brand-400"
          detailColor="text-white"
          iconColor="text-white"
        />

        {/* <div>
           <div class="flex bg-white justify-between rounded-[20px] bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
             <div>
               <h5 class="font-dm text-md font-medium text-gray-600 "> Total organic calls </h5>
               <p class="font-dm text-2xl font-bold text-navy-700 dark:text-white "> {data1_dashboard_organic?.total_organic_calls_count} </p>              
             </div>
             <div class="flex items-center bg-lightPrimary dark:!bg-navy-700 h-20 w-20 justify-center rounded-full text-brand-500 dark:text-white ">
               <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-4xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                 <path fill="none" d="M0 0h24v24H0z"></path>
                 <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
               </svg>
             </div>
           </div>
         </div> */}

        <div>
          <div class="flex justify-between rounded-[20px] border border-gray-400 bg-white bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
            <div>
              <h5 class="text-md font-dm font-medium text-gray-900 ">
                {' '}
                Website Forms{' '}
              </h5>
              {/* <p class="font-dm text-2xl font-bold text-navy-700 dark:text-white mt-2"> {data1_dashboard_organic?.total_forms_count} 
              </p>               */}
              <p class="mt-2 font-dm text-2xl font-bold text-navy-700 dark:text-white">
                {' '}
                0
              </p>
            </div>
            <div class="flex h-20 w-20 items-center justify-center rounded-full bg-lightPrimary text-brand-500 dark:!bg-navy-700 dark:text-white ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="text-4xl"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
              </svg>
            </div>
          </div>
        </div>

        <div>
          <div class="flex justify-between rounded-[20px] border border-gray-400 bg-white bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
            <div>
              <h5 class="text-md font-dm font-medium text-gray-900 ">
                {' '}
                Chatbot{' '}
              </h5>
              {/* <p class="font-dm text-2xl font-bold text-navy-700 dark:text-white mt-2"> {data1_dashboard_organic?.total_chatboat_count}  </p>               */}
              <p class="mt-2 font-dm text-2xl font-bold text-navy-700 dark:text-white">
                {' '}
                0{' '}
              </p>
            </div>
            <div class="flex h-20 w-20 items-center justify-center rounded-full bg-lightPrimary text-brand-500 dark:!bg-navy-700 dark:text-white ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                class="text-4xl"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5 mt-10 flex px-5">
        <div
          class="flex hidden w-full items-center justify-center space-x-5"
          id="custom_search"
        >
          <form
            onSubmit={filter_data}
            enctype="multipart/form-data"
            class="flex w-full items-center space-x-3"
          >
            <span class="flex w-36">
              <strong class="mr-1.5">Filter By:</strong> Start{' '}
            </span>
            <input
              max={currentDate}
              type="date"
              name="start_date"
              id="start_date"
              required
              class="w-38 rounded-xl border border-solid border-gray-700 bg-white px-4 py-2 leading-tight text-gray-800 focus:border-blue-500 focus:bg-white focus:outline-none"
            />
            <span>To </span>
            <input
              max={currentDate}
              type="date"
              name="end_date"
              id="end_date"
              required
              class="w-38 rounded-xl border border-solid border-gray-700 bg-white px-4 py-2 leading-tight text-gray-800 focus:border-blue-500 focus:bg-white focus:outline-none"
            />

            <button
              type="submit"
              class="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              fdprocessedid="uuvz8k"
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
              </svg>
            </button>
          </form>
        </div>
        <div className="ml-auto w-full rounded-xl border !border-gray-400 px-3 text-sm font-medium text-gray-800 outline-none dark:!border-none dark:bg-navy-700 md:w-fit">
          <select
            onChange={() => get_filter()}
            name="search_by"
            id="search_by"
            className="h-[45px] w-full rounded-xl bg-white text-sm font-medium text-gray-800 outline-none dark:bg-navy-700 md:w-fit md:pr-8 xl:pr-20"
          >
            <option value="--" class="">
              Current Month
            </option>
            <option value="30 days" class="">
              Last 30 Days
            </option>
            <option value="60 days" class="">
              Last 60 days
            </option>
            <option value="90 days" class="">
              Last 90 Days
            </option>
            <option value="custom" class="">
              Custom
            </option>
          </select>
        </div>
        <div className="ml-5 hidden w-full rounded-xl border !border-gray-400 px-3 text-sm font-medium text-gray-800 outline-none dark:!border-none dark:bg-navy-700 md:w-fit">
          <select
            onChange={get_filter_type}
            name="search_by"
            id="search_by"
            defaultValue={'enquires'}
            className="h-[45px] w-full rounded-xl text-sm font-medium text-gray-800 outline-none dark:bg-navy-700 md:w-fit md:pr-8 xl:pr-20"
          >
            <option value="enquires" class="">
              Enquires
            </option>
            <option value="newlead" class="">
              New Lead
            </option>
            <option value="noconsultation" class="">
              No Consultation
            </option>
          </select>
        </div>
      </div>

      {/* Kanban UI start */}
      <div className="mt-5 w-full px-5">
        <div className="mt-20 flex flex-col overflow-hidden rounded-md xl:mt-3">
          <div className="max-w-full">
            <Scrollbars
              autoHide
              renderTrackHorizontal={kanbanRenderTrack}
              renderThumbHorizontal={kanbanRenderThumb}
              renderView={kanbanRenderView}
            >
              {/* <DndContext
      sensors={sensors}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    > */}

              <div className="m-auto flex gap-4">
                <div className="flex gap-4">
                  {/* Column one start */}

                  <div className="!z-5 react-kanban-column relative flex flex min-h-[800px] w-full max-w-[90vw] flex-col flex-col rounded-[20px]  rounded-md bg-white  bg-clip-border px-4 py-4 font-bold shadow-3xl shadow-shadow-100 dark:!bg-navy-800 dark:text-white dark:shadow-none">
                    {/* Title section start */}
                    <div
                      role="button"
                      tabindex="0"
                      aria-disabled="true"
                      aria-roledescription="sortable"
                      aria-describedby="DndDescribedBy-0"
                      class="mb-[20px] flex items-center justify-between px-2"
                    >
                      <div class="mb-3 flex h-10 flex-shrink-0 items-center px-0">
                        <span class="block text-xl text-navy-700">
                          Phone calls
                        </span>
                        <span class="ml-2 flex h-7 w-7 items-center justify-center rounded bg-lightPrimary bg-opacity-70 text-sm font-semibold text-brand-500">
                          {verified_booked_Calls?.length}
                        </span>
                      </div>
                      <button className="linear flex items-center justify-center rounded-md bg-lightPrimary px-3 py-1 text-lg text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                        </svg>
                      </button>
                    </div>

                    <div className="flex max-w-full flex-grow flex-col gap-2 overflow-y-auto p-2">
                      {verified_booked_Calls?.map((obj) => (
                        <div className="task flex w-[calc(100vw_-_40px)] max-w-full flex-col rounded-2xl border border-gray-400 bg-white p-[25px] shadow-3xl shadow-shadow-100 dark:!bg-navy-700 dark:shadow-none md:w-[470px]">
                          <div className="mb-[10px] flex items-center justify-between">
                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                              {snakeToCamel(obj.type)}
                            </p>
                            <div className="flex items-center">
                              <i class="fa-solid fa-calendar-days mr-2 text-[15px]"></i>{' '}
                              {obj.date}
                            </div>
                          </div>

                          <div className="justify-between1 relative mt-5 flex w-full items-start">
                            <div className="flex flex-wrap items-center gap-2 text-[14px] text-base font-normal text-gray-900 2xl:text-[15px]">
                              <div className="w-full">
                                <i className="fa-solid fa-user mr-2 text-[15px] text-navy-700"></i>{' '}
                                {obj.first_name}
                              </div>
                              <div className="w-full">
                                <i className="fa-solid fa-phone mr-2 text-[15px] text-navy-700"></i>
                                {obj.ph_number}
                              </div>
                              <div className="w-full">
                                <i className="fa-solid fa-envelope mr-2 text-[15px] text-navy-700"></i>
                                {obj.email}
                              </div>
                              <div className="flex flex-wrap items-center gap-3 text-[14px] 2xl:text-[15px]">
                                <div className="w-full">
                                  <i className="fa-solid fa-message mr-2 text-[15px] text-navy-700"></i>

                                  {obj.message != '' &&
                                  obj.message.length > 14 ? (
                                    <>
                                      <span>
                                        {obj.message.substring(0, 30)} ...
                                      </span>
                                      <a
                                        href="#!"
                                        title="View More"
                                        onClick={() =>
                                          loadremaining(obj.message)
                                        }
                                      >
                                        <i class="fa-solid fa-eye text-black-500"></i>
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      {obj.message != '' ||
                                      obj.message == ' ' ||
                                      obj.message == '-' ? (
                                        <>
                                          <span>---</span>
                                        </>
                                      ) : (
                                        <>
                                          <span>{obj.message} </span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            <select
                              onChange={(value) =>
                                change_status(obj.id, obj.tablename, value)
                              }
                              class="absolute right-0 mb-3 mr-2 flex items-center justify-center rounded-lg bg-gray-50 px-2 py-1.5 text-sm font-medium text-navy-700 hover:cursor-pointer "
                            >
                              <option value="">Status</option>
                              {/* <option value="booked">Consult Booked</option> */}
                              <option value="newlead">New Leads</option>
                              <option value="no_consultation">
                                No Consultation
                              </option>
                              <option value="existing_patient">
                                Existing Patient
                              </option>
                            </select>
                          </div>

                          <div className="mt-[20px] flex w-full items-center justify-between">
                            <div className="flex items-center">&nbsp;</div>
                            <div className="flex h-7 items-center justify-center rounded-[8px] bg-green-500 px-3 text-sm font-bold capitalize tracking-wide text-white">
                              {obj.status}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Cards rep section end */}
                  </div>
                  {/* Column one end */}

                  {/* Column two start */}

                  <div className="!z-5 react-kanban-column relative flex flex w-full max-w-[90vw] flex-col flex-col rounded-[20px]  rounded-md bg-white  bg-clip-border px-4 py-4 font-bold shadow-3xl shadow-shadow-100 dark:!bg-navy-800 dark:text-white dark:shadow-none">
                    {/* Title section start */}

                    <div
                      role="button"
                      tabindex="0"
                      aria-disabled="true"
                      aria-roledescription="sortable"
                      aria-describedby="DndDescribedBy-0"
                      class="mb-[20px] flex items-center justify-between px-2"
                    >
                      <div class="mb-3 flex h-10 flex-shrink-0 items-center px-0">
                        <span class="block text-xl text-navy-700">
                          {' '}
                          Website Forms
                        </span>
                        <span class="ml-2 flex h-7 w-7 items-center justify-center rounded bg-lightPrimary bg-opacity-70 text-sm font-semibold text-brand-500">
                          {verified_booked?.length}
                        </span>
                      </div>
                      <button className="linear flex items-center justify-center rounded-md bg-lightPrimary px-3 py-1 text-lg text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                        </svg>
                      </button>
                    </div>
                    {/* Title section end */}
                    {/* Cards rep section start */}

                    <div className="flex max-w-full flex-grow flex-col gap-2 overflow-y-auto p-2">
                      {verified_booked?.map((obj) => (
                        <div className="task flex w-[calc(100vw_-_40px)] max-w-full flex-col rounded-2xl border border-gray-400 bg-white p-[25px] shadow-3xl shadow-shadow-100 dark:!bg-navy-700 dark:shadow-none md:w-[470px]">
                          <div className="mb-[10px] flex items-center justify-between">
                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                              {snakeToCamel(obj.type)}
                            </p>
                            <div className="flex items-center">
                              <i class="fa-solid fa-calendar-days mr-2 text-[15px]"></i>{' '}
                              {obj.date}
                            </div>
                          </div>

                          <div className="justify-between1 relative mt-5 flex w-full items-start">
                            <div className="flex flex-wrap items-center gap-2 text-[14px] text-base font-normal text-gray-900 2xl:text-[15px]">
                              <div className="w-full">
                                <i className="fa-solid fa-user mr-2 text-[15px] text-navy-700"></i>{' '}
                                {obj.first_name}
                              </div>
                              <div className="w-full">
                                <i className="fa-solid fa-phone mr-2 text-[15px] text-navy-700"></i>
                                {obj.ph_number}
                              </div>
                              <div className="w-full">
                                <i className="fa-solid fa-envelope mr-2 text-[15px] text-navy-700"></i>
                                {obj.email}
                              </div>
                              <div className="flex flex-wrap items-center gap-3 text-[14px] 2xl:text-[15px]">
                                <div className="w-full">
                                  <i className="fa-solid fa-message mr-2 text-[15px] text-navy-700"></i>{' '}
                                  {obj.message != '' &&
                                  obj.message.length > 14 ? (
                                    <>
                                      <span>
                                        {obj.message.substring(0, 30)} ...
                                      </span>
                                      <a
                                        href="#!"
                                        title="View More"
                                        onClick={() =>
                                          loadremaining(obj.message)
                                        }
                                      >
                                        <i class="fa-solid fa-eye text-black-500"></i>
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      {obj.message != '' ||
                                      obj.message == ' ' ||
                                      obj.message == '-' ? (
                                        <>
                                          <span>---</span>
                                        </>
                                      ) : (
                                        <>
                                          <span>{obj.message} </span>
                                        </>
                                      )}
                                    </>
                                  )}{' '}
                                </div>
                              </div>
                            </div>
                            <select
                              onChange={(value) =>
                                change_status(obj.id, obj.tablename, value)
                              }
                              class="absolute  right-0 mb-3 mr-2 flex items-center justify-center rounded-lg bg-gray-50 px-2 py-1.5 text-sm font-medium text-navy-700 hover:cursor-pointer "
                            >
                              <option value=" ">Status</option>
                              {/* <option value="booked">Consult Booked</option> */}
                              <option value="newlead">New Leads</option>
                              <option value="no_consultation">
                                No Consultation
                              </option>
                              <option value="existing_patient">
                                Existing Patient
                              </option>
                            </select>
                          </div>

                          <div className="mt-[20px] flex w-full items-center justify-between">
                            <div className="flex items-center">&nbsp;</div>
                            <div className="flex h-7 items-center justify-center rounded-[8px] bg-green-500 px-3 text-sm font-bold capitalize tracking-wide text-white">
                              {obj.status}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Cards rep section end */}
                  </div>

                  {/* Column two end */}

                  {/* Column three start */}

                  <div className="!z-5 react-kanban-column relative flex flex w-full max-w-[90vw] flex-col flex-col rounded-[20px]  rounded-md bg-white  bg-clip-border px-4 py-4 font-bold shadow-3xl shadow-shadow-100 dark:!bg-navy-800 dark:text-white dark:shadow-none">
                    {/* Title section start */}
                    <div
                      role="button"
                      tabindex="0"
                      aria-disabled="true"
                      aria-roledescription="sortable"
                      aria-describedby="DndDescribedBy-0"
                      class="mb-[20px] flex items-center justify-between px-2"
                    >
                      <div class="mb-3 flex h-10 flex-shrink-0 items-center px-0">
                        <span class="block text-xl text-navy-700">Chatbot</span>
                        <span class="ml-2 flex h-7 w-7 items-center justify-center rounded bg-lightPrimary bg-opacity-70 text-sm font-semibold text-brand-500">
                          {verified_booked_Chatbots?.length}
                        </span>
                      </div>
                      <button className="linear flex items-center justify-center rounded-md bg-lightPrimary px-3 py-1 text-lg text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:!bg-navy-700 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                        </svg>
                      </button>
                    </div>
                    {/* Title section end */}
                    {/* Cards rep section start */}

                    <div className="flex max-w-full flex-grow flex-col gap-2 overflow-y-auto p-2">
                      {verified_booked_Chatbots?.map((obj) => (
                        <div className="task flex w-[calc(100vw_-_40px)] max-w-full flex-col rounded-2xl border border-gray-400 bg-white p-[25px] shadow-3xl shadow-shadow-100 dark:!bg-navy-700 dark:shadow-none md:w-[470px]">
                          <div className="mb-[10px] flex items-center justify-between">
                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                              {snakeToCamel(obj.type)}
                            </p>
                            <div className="flex items-center">
                              <i class="fa-solid fa-calendar-days mr-2 text-[15px]"></i>{' '}
                              {obj.date}
                            </div>
                          </div>

                          <div className="justify-between1 relative mt-5 flex w-full items-start">
                            <div className="flex flex-wrap items-center gap-2 text-[14px] text-base font-normal text-gray-900 2xl:text-[15px]">
                              <div className="w-full">
                                <i className="fa-solid fa-user mr-2 text-[15px] text-navy-700"></i>{' '}
                                {obj.first_name}
                              </div>
                              <div className="w-full">
                                <i className="fa-solid fa-phone mr-2 text-[15px] text-navy-700"></i>
                                {obj.ph_number}
                              </div>
                              <div className="w-full">
                                <i className="fa-solid fa-envelope mr-2 text-[15px] text-navy-700"></i>
                                {obj.email}
                              </div>
                              <div className="flex flex-wrap items-center gap-3 text-[14px] 2xl:text-[15px]">
                                <div className="w-full">
                                  <i className="fa-solid fa-message mr-2 text-[15px] text-navy-700"></i>{' '}
                                  {obj.message != '' &&
                                  obj.message.length > 14 ? (
                                    <>
                                      <span>
                                        {obj.message.substring(0, 30)} ...
                                      </span>
                                      <a
                                        href="#!"
                                        title="View More"
                                        onClick={() =>
                                          loadremaining(obj.message)
                                        }
                                      >
                                        <i class="fa-solid fa-eye text-black-500"></i>
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      {obj.message != '' ||
                                      obj.message == ' ' ||
                                      obj.message == '-' ? (
                                        <>
                                          <span>---</span>
                                        </>
                                      ) : (
                                        <>
                                          <span>{obj.message} </span>
                                        </>
                                      )}
                                    </>
                                  )}{' '}
                                </div>
                              </div>
                            </div>
                            <select
                              onChange={(value) =>
                                change_status(obj.id, obj.tablename, value)
                              }
                              class="absolute right-0 mb-3 mr-2 flex items-center justify-center rounded-lg bg-gray-50 px-2 py-1.5 text-sm font-medium text-navy-700 hover:cursor-pointer "
                            >
                              <option value="">Status</option>
                              {/* <option value="booked">Consult Booked</option> */}
                              <option value="newlead">New Leads</option>
                              <option value="no_consultation">
                                No Consultation
                              </option>
                              <option value="existing_patient">
                                Existing Patient
                              </option>
                            </select>
                          </div>

                          <div className="mt-[20px] flex w-full items-center justify-between">
                            <div className="flex items-center">&nbsp;</div>
                            <div className="1capitalize flex h-7 items-center justify-center rounded-[8px] bg-brand-500 px-3 text-sm font-bold capitalize tracking-wide text-white">
                              {snakeToCamel1(obj.status)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Cards rep section end */}
                  </div>

                  {/* Column three end */}
                </div>
              </div>

              {/* </DndContext> */}
            </Scrollbars>
          </div>
        </div>
      </div>
      {/* Kanban UI end */}

      <div className="mt-20 hidden h-full w-full gap-[20px]  rounded-[20px] sm:mt-10 xl:flex-row">
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3 3xl:grid-cols-4">
          <div>
            <MiniStatistics
              icon={<MdPhone className="text-4xl" />}
              title="Total organic calls"
              value={verified_organic_calls_count}
              bgColor={'bg-yellow-200'}
              growth={''}
              growthColor="text-red-500"
              cardBg="bg-yellow-50"
              titleColor="text-gray-600"
              valueColor="text-navy-700 dark:text-white"
              detailColor="text-gray-600"
              iconColor="text-yellow-600"
            />
          </div>
          <div>
            <MiniStatistics
              icon={<SiGoogleforms className="text-4xl" />}
              title="Total Forms"
              value={verified_forms_count}
              bgColor={'bg-indigo-200'}
              growth={''}
              growthColor="text-red-500"
              cardBg="bg-indigo-50"
              titleColor="text-gray-600"
              valueColor="text-navy-700 dark:text-white"
              detailColor="text-gray-600"
              iconColor="text-indigo-600"
            />
          </div>
          <div>
            <MiniStatistics
              icon={<MdChat className="text-4xl" />}
              title="Total Chatbots"
              value={verified_chatbot_count}
              bgColor={'bg-green-200'}
              growth={''}
              growthColor="text-red-500"
              cardBg="bg-green-50"
              titleColor="text-gray-600"
              valueColor="text-navy-700 dark:text-white"
              detailColor="text-gray-600"
              iconColor="text-green-600"
            />
          </div>
        </div>
        <div className="mb-5 mt-5 ">
          <div class={'h-full w-full px-9 py-3'}>
            <div class="min-w-0 flex-1"></div>
            <div class="flex">
              <div
                class="flex hidden w-full items-center justify-center space-x-5"
                id="custom_search"
              >
                <form
                  onSubmit={filter_data}
                  enctype="multipart/form-data"
                  class="flex w-full items-center space-x-3"
                >
                  <span class="flex w-36">
                    <strong class="mr-1.5">Filter By:</strong> Start{' '}
                  </span>
                  <input
                    max={currentDate}
                    type="date"
                    name="start_date"
                    id="start_date"
                    required
                    class="w-38 rounded-xl border border-solid border-gray-600 bg-white px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                  <span>To </span>
                  <input
                    max={currentDate}
                    type="date"
                    name="end_date"
                    id="end_date"
                    required
                    class="w-38 rounded-xl border border-solid border-gray-600 bg-white px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none"
                  />
                  {/* <input
                  type="submit"
                  value="Go"
                  class="bg-primarycolor-500 hover:bg-secondarycolor-500 text-md cursor-pointer rounded-full px-4 py-2 font-normal text-white shadow-xl"
                /> */}
                  <button
                    type="submit"
                    class="flex items-center justify-center rounded-full bg-brand-500 p-2 text-3xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    fdprocessedid="uuvz8k"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    </svg>
                  </button>
                </form>
              </div>
              <div className="ml-auto w-full rounded-xl border !border-gray-200 px-3 text-sm font-medium text-gray-600 outline-none dark:!border-none dark:bg-navy-700 md:w-fit">
                <select
                  onChange={() => get_filter()}
                  name="search_by"
                  id="search_by"
                  className="h-[45px] w-full rounded-xl text-sm font-medium text-gray-600 outline-none dark:bg-navy-700 md:w-fit md:pr-8 xl:pr-20"
                >
                  <option value="--" class="">
                    Current Month
                  </option>
                  <option value="30 days" class="">
                    Last 30 Days
                  </option>
                  <option value="60 days" class="">
                    Last 60 days
                  </option>
                  <option value="90 days" class="">
                    Last 90 Days
                  </option>
                  <option value="custom" class="">
                    Custom
                  </option>
                </select>
              </div>
              <div className="ml-20 w-full rounded-xl border !border-gray-200 px-3 text-sm font-medium text-gray-600 outline-none dark:!border-none dark:bg-navy-700 md:w-fit">
                <select
                  onChange={get_filter_type}
                  name="search_by"
                  id="search_by"
                  defaultValue={'enquires'}
                  className="h-[45px] w-full rounded-xl text-sm font-medium text-gray-600 outline-none dark:bg-navy-700 md:w-fit md:pr-8 xl:pr-20"
                >
                  <option value="enquires" class="">
                    Calls
                  </option>
                  <option value="newlead" class="">
                    Form
                  </option>
                  <option value="noconsultation" class="">
                    Chat Boat
                  </option>
                </select>
              </div>
            </div>
            {enquirydisplay ? (
              <div className="mt-5 grid h-full w-full grid-cols-1 gap-5">
                <>
                  <ColumnsTable
                    columnsData={columnsDataComplex}
                    tableData={verified_booked_Calls}
                    labelvalue={'Calls'}
                  />
                </>
              </div>
            ) : (
              ''
            )}

            {newleaddisplay ? (
              <div className="mt-5 grid h-full w-full grid-cols-1 gap-5">
                <>
                  <ColumnsTable
                    columnsData={columnsDataComplex}
                    tableData={verified_booked}
                    labelvalue={'Forms'}
                  />
                </>
              </div>
            ) : (
              ''
            )}

            {noconsultationdisplay ? (
              <div className="mt-5 grid h-full w-full grid-cols-1 gap-5">
                <ColumnsTable
                  columnsData={columnsDataComplex}
                  tableData={verified_booked_Chatbots}
                  labelvalue={'Chatbots'}
                />
              </div>
            ) : (
              ''
            )}

            {/* <div class="mb-10 grid w-full grid-cols-1 gap-6 px-10 lg:grid-cols-3 2xl:gap-10">
        <div class="flex w-full flex-shrink-0 flex-col">
          <div class="mb-3 flex h-10 flex-shrink-0 items-center px-0">
            <span class="block text-[18px] font-[600]">Calls</span>
            <span class="ml-2 flex h-5 w-5 items-center justify-center rounded bg-gray-500 bg-opacity-70 text-sm font-semibold text-white">
              {verified_organic_calls_count}
            </span>
            <button class="bg-transperent hover:text-black ml-auto flex h-6 w-6 items-center justify-center rounded text-[#b0b0ae] hover:bg-white">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
              </svg>
            </button>
          </div>

          <div class="overflow-auto1 flex flex-col gap-5">
            {data1_dashboard_organic && verified_booked.length && (
              <>
                {verified_booked?.map((row) => (
                  <>
                    {row?.type === 'phone_call' ? (
                      <>
                        {' '}
                        <div class="group relative flex flex-col items-start gap-4 rounded-[20px] border border-solid border-[#8d2995] bg-white  p-4 2xl:rounded-[30px] 2xl:p-6">
                          <div class="mt-0 flex w-full items-center justify-between">
                            <div class="flex items-center">
                              <div class="text-[16px] font-[600]">
                                Phone Call
                              </div>
                              <div class="ml-3 rounded-full bg-[#f5f5eb] p-2">
                                <i class="fa-solid fa-phone text-[20px]"></i>
                              </div>
                            </div>
                            <div class="flex items-center">
                              <div class="text-[16px] font-[600]">
                                <i class="fa-solid fa-calendar-days mr-2 text-[15px]"></i>{' '}
                                {row.date}
                              </div>
                            </div>
                          </div>
                          <div class="justify-between1 relative mt-5 flex w-full items-start">
                            <div class="flex flex-wrap items-center gap-3 text-[14px] 2xl:text-[15px]">
                              <div class="w-full">
                                <i class="fa-solid fa-user mr-2 text-[15px]"></i>{' '}
                                --
                              </div>
                              <div class="w-full">
                                <i class="fa-solid fa-envelope mr-2 text-[15px]"></i>{' '}
                                --
                              </div>
                              <div class="w-full">
                                <i class="fa-solid fa-phone mr-2 text-[15px]"></i>{' '}
                                {row.ph_number}
                              </div>
                              <div class="w-full">
                                <i class="fa-solid fa-message mr-2 text-[15px]"></i>{' '}
                                {row.reason}
                              </div>
                            </div>
                            <a
                              href="#!"
                              onClick={() => open_popover('outcome_v' + row.id)}
                              class="absolute right-0 rounded-[30px] bg-[#f3f3f3] px-3 py-1 text-[13px] font-medium leading-normal text-[#000] hover:bg-[#000] hover:text-white"
                            >
                              Status
                            </a>
                            <div
                              id="outcome_v{row.id}"
                              class="status_drop absolute right-0 top-8 hidden w-[10rem] rounded-[10px] border border-solid border-[#ddd] bg-white p-3 shadow-sm"
                            >
                              <div class="w-full gap-3">
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      change_status(
                                        row.id,
                                        row.tablename,
                                        'booked'
                                      )
                                    }
                                    class="text-black leading-normal"
                                  >
                                    Consult Booked
                                  </a>
                                </div>
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      change_status(
                                        row.id,
                                        row.tablename,
                                        'newlead'
                                      )
                                    }
                                    class="text-black leading-normal"
                                  >
                                    New Leads
                                  </a>
                                </div>
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      change_status(
                                        row.id,
                                        row.tablename,
                                        'no_consultation'
                                      )
                                    }
                                    class="text-black leading-normal"
                                  >
                                    No Consultation
                                  </a>
                                </div>
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      change_status(
                                        row.id,
                                        row.tablename,
                                        'existing_patient'
                                      )
                                    }
                                    class="text-black leading-normal"
                                  >
                                    Existing Patient{' '}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-2 flex w-full items-center justify-between">
                            <div class=""></div>
                            <div class="inline-flex rounded-[30px] bg-[#d5e3fa] px-3 py-1 text-[13px] font-medium leading-normal text-[#1c498e]">
                              {row.message != '' && row.message.length > 14 ? (
                                <>
                                  <span>
                                    {row.message.substring(0, 30)} ...
                                  </span>
                                  <a
                                    href="#!"
                                    title="View More"
                                    onClick={() => loadremaining(row.message)}
                                  >
                                    <i class="fa-solid fa-eye"></i>
                                  </a>
                                </>
                              ) : (
                                <>
                                  {row.message != '' ||
                                  row.message == ' ' ||
                                  row.message == '-' ? (
                                    <>
                                      <span>---</span>
                                    </>
                                  ) : (
                                    <>
                                      <span>{row.message} </span>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>

        <div class="flex w-full flex-shrink-0 flex-col">
          <div class="mb-3 flex h-10 flex-shrink-0 items-center px-0">
            <span class="block text-[18px] font-[600]">Form</span>
            <span class="ml-2 flex h-5 w-5 items-center justify-center rounded bg-gray-500 bg-opacity-70 text-sm font-semibold text-white">
              {verified_forms_count}
            </span>
            <button class="bg-transperent hover:text-black ml-auto flex h-6 w-6 items-center justify-center rounded text-[#b0b0ae] hover:bg-white">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
              </svg>
            </button>
          </div>

          <div class="overflow-auto1 flex flex-col gap-5">
            {data1_dashboard_organic && verified_booked.length && (
              <>
                {verified_booked?.map((row) => (
                  <>
                    {row.type === 'website_form' && (
                      <>
                        {' '}
                        <div class="group relative flex flex-col items-start gap-4 rounded-[20px] border border-solid border-[#ff601e] bg-white  p-4 2xl:rounded-[30px] 2xl:p-6">
                          <div class="mt-0 flex w-full items-center justify-between">
                            <div class="flex items-center">
                              <div class="text-[16px] font-[600]">
                                Website Form
                              </div>
                              <div class="ml-3 rounded-full bg-[#f5f5eb] p-2">
                                <i class="fa-solid fa-globe text-[20px]"></i>
                              </div>
                            </div>
                            <div class="flex items-center">
                              <div class="text-[16px] font-[600]">
                                <i class="fa-solid fa-calendar-days mr-2 text-[15px]"></i>{' '}
                                {row.date}
                              </div>
                            </div>
                          </div>
                          <div class="justify-between1 relative mt-5 flex w-full items-start">
                            <div class="flex flex-wrap items-center gap-3 text-[14px] 2xl:text-[15px]">
                              <div class="w-full">
                                <i class="fa-solid fa-user mr-2 text-[15px]"></i>{' '}
                                {row.first_name}
                              </div>
                              <div class="w-full">
                                <i class="fa-solid fa-phone mr-2 text-[15px]"></i>
                                {row.ph_number}
                              </div>
                              <div class="w-full">
                                <i class="fa-solid fa-envelope mr-2 text-[15px]"></i>
                                {row.email}
                              </div>
                              <div class="flex flex-wrap items-center gap-3 text-[14px] 2xl:text-[15px]">
                                <div class="w-full">
                                  <i class="fa-solid fa-message mr-2 text-[15px]"></i>

                                  {row.message != '' &&
                                  row.message.length > 14 ? (
                                    <>
                                      <span>
                                        {row.message.substring(0, 30)} ...
                                      </span>
                                      <a
                                        href="#!"
                                        title="View More"
                                        onClick={() =>
                                          loadremaining(row.message)
                                        }
                                      >
                                        <i class="fa-solid fa-eye"></i>
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      {row.message != '' ||
                                      row.message == ' ' ||
                                      row.message == '-' ? (
                                        <>
                                          <span>---</span>
                                        </>
                                      ) : (
                                        <>
                                          <span>{row.message} </span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <a
                              href="#!"
                              onClick={() => open_popover('outcome_v' + row.id)}
                              class="absolute right-0 rounded-[30px] bg-[#f3f3f3] px-3 py-1 text-[13px] font-medium leading-normal text-[#000] hover:bg-[#000] hover:text-white"
                            >
                              Status
                            </a>
                            <div
                              id="outcome_v{row.id}"
                              class="status_drop absolute right-0 top-8 hidden w-[10rem] rounded-[10px] border border-solid border-[#ddd] bg-white p-3 shadow-sm"
                            >
                              <div class="w-full gap-3">
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      change_status(
                                        row.id,
                                        row.tablename,
                                        'booked'
                                      )
                                    }
                                    class="text-black leading-normal"
                                  >
                                    Consult Booked
                                  </a>
                                </div>
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      change_status(
                                        row.id,
                                        row.tablename,
                                        'newlead'
                                      )
                                    }
                                    class="text-black leading-normal"
                                  >
                                    New Leads
                                  </a>
                                </div>
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      change_status(
                                        row.id,
                                        row.tablename,
                                        'no_consultation'
                                      )
                                    }
                                    class="text-black leading-normal"
                                  >
                                    No Consultation
                                  </a>
                                </div>
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      change_status(
                                        row.id,
                                        row.tablename,
                                        'existing_patient'
                                      )
                                    }
                                    class="text-black leading-normal"
                                  >
                                    Existing Patient{' '}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-2 flex w-full items-center justify-between">
                            <div class=""></div>
                            <div class="inline-flex rounded-[30px] bg-[#fff3e5] px-3 py-1 text-[13px] font-medium leading-normal text-[#ff540f]">
                              {row.message != '' && row.message.length > 14 ? (
                                <>
                                  <span>
                                    {row.message.substring(0, 30)} ...
                                  </span>
                                  <a
                                    href="#!"
                                    title="View More"
                                    onClick={() => loadremaining(row.message)}
                                  >
                                    <i class="fa-solid fa-eye"></i>
                                  </a>
                                </>
                              ) : (
                                <>
                                  {row.message != '' ||
                                  row.message == ' ' ||
                                  row.message == '-' ? (
                                    <>
                                      <span>---</span>
                                    </>
                                  ) : (
                                    <>
                                      <span>{row.message} </span>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
        <div class="flex w-full flex-shrink-0 flex-col">
          <div class="mb-3 flex h-10 flex-shrink-0 items-center px-0">
            <span class="block text-[18px] font-[600]">Chatboat</span>
            <span class="ml-2 flex h-5 w-5 items-center justify-center rounded bg-gray-500 bg-opacity-70 text-sm font-semibold text-white">
              { <>{verified_chatbot_count}</>}
            </span>
            <button class="bg-transperent hover:text-black ml-auto flex h-6 w-6 items-center justify-center rounded text-[#b0b0ae] hover:bg-white">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
              </svg>
            </button>
          </div>

          <div class="overflow-auto1 flex flex-col gap-5">
            {data1_dashboard_organic && verified_booked.length > 0 && (
              <>
                {verified_booked?.map((row) => (
                
                  <>
                    {console.log("row",row)}
                    {row.type == 'chat_boat' && (
                      <>
                        <div class="group relative flex flex-col items-start gap-4 rounded-[20px] border border-solid border-[#95b721] bg-white p-4 2xl:rounded-[30px] 2xl:p-6">
                          <div class="mt-0 flex w-full items-center justify-between">
                            <div class="flex items-center">
                              <div class="text-[16px] font-[600]">Chatboat</div>
                              <div class="ml-3 rounded-full bg-[#f5f5eb] p-2">
                                <i class="fa-solid fa-robot text-[20px]"></i>
                              </div>
                            </div>
                            <div class="flex items-center">
                              <div class="text-[16px] font-[600]">
                                <i class="fa-solid fa-calendar-days mr-2 text-[15px]"></i>{' '}
                                2024-02-14
                              </div>
                            </div>
                          </div>
                          <div class="justify-between1 relative mt-5 flex w-full items-start">
                            <div class="flex flex-wrap items-center gap-3 text-[14px] 2xl:text-[15px]">
                              <div class="w-full">
                                <i class="fa-solid fa-user mr-2 text-[15px]"></i>{' '}
                                Marie
                              </div>
                              <div class="w-full">
                                <i class="fa-solid fa-phone mr-2 text-[15px]"></i>{' '}
                                347-794-0747
                              </div>
                              <div class="w-full">
                                <i class="fa-solid fa-envelope mr-2 text-[15px]"></i>{' '}
                                Mariepaule8394@Gmail.Com
                              </div>
                              <div class="w-full">
                                <i class="fa-solid fa-message mr-2 text-[15px]"></i>{' '}
                                I Would Like To Have A Quote ...
                              </div>
                            </div>
                            <a
                              href="javascripct://"
                              onClick={() => open_popover('outcome_16')}
                              class="absolute right-0 rounded-[30px] bg-[#f3f3f3] px-3 py-1 text-[13px] font-medium leading-normal text-[#000] hover:bg-[#000] hover:text-white"
                            >
                              Status
                            </a>
                            <div
                              id="outcome_16"
                              class="status_drop absolute right-0 top-8 hidden w-[10rem] rounded-[10px] border border-solid border-[#ddd] bg-white p-3 shadow-sm"
                            >
                              <div class="w-full gap-3">
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    class="text-black leading-normal"
                                  >
                                    Calls
                                  </a>
                                </div>
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    class="text-black leading-normal"
                                  >
                                    Form
                                  </a>
                                </div>
                                <div class="mb-2">
                                  <a
                                    href="#!"
                                    class="text-black leading-normal"
                                  >
                                    Chatboat
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mt-2 flex w-full items-center justify-between">
                            <div class=""></div>
                            <div class="inline-flex rounded-[30px] bg-[#d3f26a] px-3 py-1 text-[13px] font-medium leading-normal text-[#398700]">
                              {row.message != '' && row.message.length > 14 ? (
                                <>
                                  <span>
                                    {row.message.substring(0, 30)} ...
                                  </span>
                                  <a
                                    href="#!"
                                    title="View More"
                                    onClick={() => loadremaining(row.message)}
                                  >
                                    <i class="fa-solid fa-eye"></i>
                                  </a>
                                </>
                              ) : (
                                <>
                                  {row.message != '' ||
                                  row.message == ' ' ||
                                  row.message == '-' ? (
                                    <>
                                      <span>---</span>
                                    </>
                                  ) : (
                                    <>
                                      <span>{row.message} </span>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </div> */}
          </div>
        </div>
      </div>
      <ModalComponent
        isOpen={isModalOpen}
        onClose={closeModal}
        leadsData={leadsData}
      />
    </div>
  );
};

export default VerifiedLeads;
