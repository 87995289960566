import React, { useEffect,useState } from 'react';
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Card from "components/card";
import axios from 'axios';
import google_logo from '../../../../../../assets/img/reviewus/google_logo.webp';
import facebook_logo from '../../../../../../assets/img/reviewus/facebook_logo.webp';
import others_logo from '../../../../../../assets/img/reviewus/others_logos.png';
import healthy_hearing from '../../../../../../assets/img/reviewus/healthy_hearing.webp';
import review_img from '../../../../../../assets/img/reviewus/reviewus_img.png';

const Modal = (props) => {
    let user = JSON.parse(localStorage.getItem('auth'));

    const [fname,setfname]=useState('');
    const [lname,setlname]=useState('');
    const [email,setemail]=useState('');
    const [pnumber,setpnumber]=useState('');
    const [message,setMessage]=useState('');
    const [googlereview,setgooglereview]=useState('');
    const [facebookreview,setfacebookreview]=useState('');
    const [othersreview,setothersreview]=useState('');
    const [googledisplay,setgoogledisplay]=useState(false);
    const [facebookdisplay,setfacebookdisplay]=useState(false);
    const [othersdisplay,setothersdisplay]=useState(false);
    const [adminemail,setadminemail]=useState(props?.decodedData?.email);
    const [username,setusername]=useState('')
  
    useEffect(()=>{
      const fetchData = async () => {
        try {
          // Perform your asynchronous task here
          const response = await fetch(`https://dcp-backend.onrender.com/wrapper/reviewURLfindone?username=${props?.decodedData?.email}`);
          const result = await response.json();
          console.log(result)
          
          setgooglereview(result?.googlereview);
          setfacebookreview(result?.facebookreview);
          setothersreview(result?.othersreview);
          setusername(result?.username)
          setgoogledisplay(result?.googlereview!==''?true:false)
          setfacebookdisplay(result?.facebookreview!==''?true:false)
          setothersdisplay(result?.othersreview!==''?true:false)
          // setData(result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }; 
  
      // Call the async function inside useEffect
      fetchData();
  
      // Since this is a cleanup function, you can return a function
      // to clean up any resources or subscriptions if necessary
      return () => {
        // Cleanup code here, if needed
      };
     
    },[])
  
    
  
     const handleChangeFname=(e)=>{
      setfname(e.target.value);
     }
     const handleChangeLname=(e)=>{
      setlname( e.target.value);
     }
     const handleChangeEmail=(e)=>{
      setemail(e.target.value);
     }  
     const handleChangePhone=(e)=>{
      setpnumber(e.target.value);
     }
     const handleChangeMessage=(e)=>{
      console.log(e.target.value)
      setMessage(e.target.value);
     }
     
  
  
   const handleFormClick=async()=>{
    try {
      const res = await axios.post(`https://dcp-backend.onrender.com/wrapper/reviewForm`, {
        fname,
        lname,
        pnumber,
        email,
        message,
        adminemail,
         username
      });
      props.setToggle1(false);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
   }
  


  return (
    <div className="!z-10 fixed inset-0 flex items-center justify-center overflow-y-auto">
      <div className="!z-10 fixed inset-0 bg-gray-800 bg-opacity-20 backdrop-blur-sm backdrop-filter"></div>


      <div className="!z-10 relative w-1/2 rounded bg-white p-8 shadow-md h-fit text-gray-800" >
        <button
          className="absolute right-2 top-2 text-gray-800 hover:text-gray-800"
          onClick={props.ratingChanged}
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>

        {/* 123 Stars Popup start */}
        {props.text == "form" ? (
         <div className="w-full">

                   <h2 className="mb-4 text-xl font-semibold">We strive for 100% customer satisfaction. If we fell short, please tell us more so we can address your concerns.</h2>
       
                    <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
                      <InputField
                        label="First Name"
                        placeholder="Vlad"
                        id="firstname"
                        value={fname}
                        onChange={handleChangeFname}
                        type="text"
                      />
                      <InputField
                        label="Last Name"
                        placeholder="Mihalache"
                        value={lname}
                        onChange={handleChangeLname}
                        id="lastname"
                        type="text"
                      />
                      <InputField
                        label="Email Address"
                        placeholder="@horizon.ui"
                        value={email}
                        onChange={handleChangeEmail}
                        id="email"
                        type="text"
                      />
                      <InputField
                        label="Phone"
                        placeholder="205 2545 4554"
                        value={pnumber}
                        onChange={handleChangePhone}
                        id="phone"
                        type="text"
                      />
       
                    </div>
       
                    <InputField
                      label="Message"
                      placeholder="Tell something about yourself in 150 characters!"
                      value={message}
                      onChange={handleChangeMessage}
                      id="message"
                      cols="30"
                 
                      rows="7"
                    />
           <div class="flex w-full justify-start mt-5">
                  <button onClick={handleFormClick} class="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">Send</button>
                </div>
                  </div>
          
        ) : (<div>
              <div className='flex flex-wrap h-full'>
                <div className='w-full lg:w-d2/3 p-0 1lg:pr-5 3xl:py-5 1mb-5 flex flex-col items-center justify-center'>
                    <h4 className="text-2xl font-bold text-navy-700 text-center mb-4">Please select the website to leave your review on</h4> 
                    <img src={review_img} className="w-full lg:w-auto h-auto lg:h-72 mx-auto" />
                </div>
                <div className='w-full lg:w-d1/3 bg-brand-500 p-5 3xl:p-8 rounded-xl'>
                    <div className='w-full text-center grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-3 gap-3 3xl:gap-5'>
                      {googledisplay?
                      <a href={googlereview!==''?googlereview:"#"} target='_blank' title='Google' className='bg-white border border-gray-400 rounded-lg shadow-xl hover:shadow-none p-2'>
                        <img src={google_logo} className="w-full h-auto mx-auto" />
                      </a>:""}
                      {facebookdisplay?
                      <a href={facebookreview!==''?facebookreview:"#"} target='_blank' title='Facebook' className='bg-white border border-gray-400 rounded-lg shadow-xl hover:shadow-none p-2'>
                        <img src={facebook_logo} className="w-full h-auto mx-auto" />
                      </a>:""}

                     {othersdisplay?
                      <a href={othersreview!==''?othersreview:"#"} target='_blank' title='Others' className='bg-white border border-gray-400 rounded-lg shadow-xl hover:shadow-none p-2'>
                        <img src={others_logo} className="w-full h-auto mx-auto" />
                      </a>:""}
                      {/* <a href='#' target='_blank' className='bg-white border border-gray-400 rounded-lg shadow-xl hover:shadow-none p-2'>
                        <img src={healthy_hearing} className="w-full h-auto mx-auto" />
                      </a> */}
                    </div>
                </div>
              </div> 

        </div>)}

     
      </div>

    </div>
  );
};

export default Modal;