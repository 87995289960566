import React, { useEffect, useState } from 'react';
import profile from "assets/img/crm/vbz.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";

const Banner = () => {
  let user = JSON.parse(localStorage.getItem('auth'));
  const[user_email,setuser_email]=useState(user?.email);
  const[username,setusername]=useState(user?.top_name);
  const[logo_path,setlogo_path]=useState(user?.logo_path);
  useEffect(()=>{
   console.log(user)
   setusername(user?.top_name)
    setuser_email(user?.email)
    setlogo_path(user?.logo_path)
    },[])
  return (
    <Card extra={"items-center pt-[16px] pb-10 px-[16px] bg-cover"}>
      {/* background and profile */}
      <div
        className="jsu relative mt-1 flex h-28 w-full justify-center rounded-[20px] bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-10 flex h-auto w-[190px]  items-center justify-center rounded-lg p-3 1rounded-full">
          <img className="h-full w-full bg-white " src={logo_path} alt="" />
        </div>
      </div>
      {/* name and position */}
      <div className="mt-14 flex flex-col items-center">
        <h4 className="mt-1 text-xl font-bold text-navy-700 dark:text-white">
          {username}
        </h4>
        <div className="mt-2 hidden 1flex items-center justify-center">
          <h6 className="mt-2 text-sm font-normal text-gray-600">
            Account type
          </h6>
          <select
            className="mt-2 px-2 text-sm font-bold text-navy-700 dark:!bg-navy-800 dark:text-white"
            name=""
            id=""
          >
            <option value="admin">Administrator</option>
            <option value="admin">Moderator</option>
          </select>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
