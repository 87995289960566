import React, { useEffect, useState } from 'react';
//import img1 from '../../../../assets/img/listings'
import Card from 'components/card';
import { IoMdInformationCircle } from "react-icons/io";
import Modal from './Modal';
import SearchTableUsers from './SearchTableUsersOverview';


const columnsDataUsersOverview=[
    {
      Header: 'Site/Directory',
      accessor: 'source',
    },
    {
      Header: 'Name',
      accessor: 'business-name',
    },
    {
      Header: 'Adress',
      accessor: 'address',
    },
    {
      Header: 'Postcode',
      accessor: 'postcode',
    },
    {
      Header: 'Phone',
      accessor: 'telephone',
    },
    {
      Header: 'Status',
      accessor: 'need_to_fix',
 }]

const BusinessListings = () => {
  const [data1, setData1] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage,setItemsPerPage] = useState(5);
  const [value,setValue]=useState('all');
  const [toggle,setToggle]=useState(false);
  const [toggle1,setToggle1]=useState(false);
  const [issues_data,setText]=useState([])
  const [status_val,setstatus_val]=useState(0);
  const [tableData, settableData] = useState([]);
  const [data,setData]=useState();
  const [items,setItems]=useState([]);
  let user = JSON.parse(localStorage.getItem('auth'));
 // const [row,setRow]=useState('')

  //let issues_data = [];
  function loadpopup(row){
    console.log(row)
      setText  (row);
      setToggle1(!toggle);  
  }
  function handleToggle(){
    setToggle1(!toggle1);
  }
  console.log(issues_data);
  function loadremaining() {
    setToggle(!toggle);
  }
  
  const handlePopup=()=>{
    setToggle(!toggle)
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  
  
    let currentItems = data1.topDirectories?.slice(indexOfFirstItem, indexOfLastItem);

  
  //const currentItems = data1.active_citations?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  async function getData(data1) {
    let report_id = user?.report_id; // Chess House Dental Practice
    const apiURL =
      'https://app.legaciestechno.com/brightlocal/Examples/CitationTracker/citation_tracker_all.php?report_id=' +
      report_id;

    // console.log(apiURL);
    const response = await fetch(apiURL);
    const res = await response.json();
    // console.log(res)

    setData1(res);
    settableData(res.topDirectories)
    console.log(res);
  }
  useEffect(() => {
    getData();
    
    //console.log(items)
    
  }, []);
  const Change=async(e)=>{
           setValue(e.target.value);



           let report_id = user?.report_id; // Chess House Dental Practice
           const apiURL =
             'https://app.legaciestechno.com/brightlocal/Examples/CitationTracker/citation_tracker_all.php?report_id=' +
             report_id;
                  
           // console.log(apiURL);
           const response = await fetch(apiURL);
           const res = await response.json();
          console.log(res.topDirectories,e.target.value)
         if(e.target.value=="issues"){
          setstatus_val(0)
          const directoriesNeedToFix = res.topDirectories.filter(directory => directory.need_to_fix.length > 0);
          settableData(directoriesNeedToFix);
        
         }  else if(e.target.value=="added"){
          setstatus_val(1);
          const filteredDirectories = res.topDirectories.filter(directory => (
            directory["business-name"] === 'n/a' &&
            directory.address =="" &&
            directory.postcode =='n/a' &&
            directory.telephone =='n/a'
        ));

        settableData(filteredDirectories)
        console.log(filteredDirectories)
         }
         else{
          setstatus_val(0)
          settableData(res.topDirectories)
         }



           // console.log(res)
           setData1(res);
            console.log(user)
  }


//  let range = dates_calculation(search);

  //const data = {};
  // data1['start_date'] = range.end_date;
  // data1['end_date'] = range.start_date;

  function dates_calculation(days) {
    if (days == '30 days') {
      let currentDate = new Date();
      let endDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
      let formattedStartDate = currentDate.toISOString().slice(0, 10);
      let formattedEndDate = endDate.toISOString().slice(0, 10);
      return {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
    }
    if (days == '60 days') {
      let currentDate = new Date();
      let endDate = new Date(currentDate.getTime() - 60 * 24 * 60 * 60 * 1000);
      let formattedStartDate = currentDate.toISOString().slice(0, 10);
      let formattedEndDate = endDate.toISOString().slice(0, 10);
      return {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
    }
    if (days == '90 days') {
      let currentDate = new Date();
      let endDate = new Date(currentDate.getTime() - 60 * 24 * 60 * 60 * 1000);
      let formattedStartDate = currentDate.toISOString().slice(0, 10);
      let formattedEndDate = endDate.toISOString().slice(0, 10);
      return {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
    }
    if (days == '--') {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      let formattedMonth = month < 10 ? '0' + month : month;
      let formattedStartDate = year + '-' + formattedMonth + '-01';
      let formattedEndDate = currentDate.toISOString().slice(0, 10);

      return {
        start_date: formattedEndDate,
        end_date: formattedStartDate,
      };
    }
  }
 
  return (
    
    <div class="w-full px-5">

          {/* Local Business Listings start */}
          <div className="w-full mb-5">
            <h4 className="flex items-center text-lg font-bold text-navy-700 dark:text-white">
              <div className="flex items-center bg-lightPrimary dark:!bg-navy-700 h-10 w-10 mr-2 justify-center rounded-full text-brand-500 dark:text-white ">
                <i class="fa-solid fa-location-dot"></i>
              </div> Local Business Listings
            </h4>
            <p className="mt-1 text-base text-gray-800 lg:ml-12"> Business Listings Tracker looks for your business information on the most important business listing sites. Inaccuracies and incomplete business information are also highlighted.</p>
          </div>
          {/* Local Business Listings end */}

        {/* Business Listings Score start */}
        <div className='w-full mb-5'>
            <div class="w-full relative bg-gray-50 p-5 rounded-lg mb-5">
                <div class="grid grid-cols-1 lg:grid-cols-5 gap-5">
                  <div class="w-full">
                    <div class="grid grid-cols-1 gap-2">
                      <div class="w-full bg-[#992699] border border-gray-500 p-2 rounded-[10px]">
                        <div class="flex items-center justify-center space-x-2 mb-1">
                          <div class="text-white text-opacity-80 text-sm font-medium ">Business Listings Score</div>
                        </div>
                        <div class="flex items-center justify-center ">
                          <div class="text-2xl font-bold text-white ">
                              {data1 ? (
                              <div class="">
                                {data1.keyCitationScore ? data1.keyCitationScore : '0'}
                                /100
                              </div>
                            ) : (
                              ''
                            )}                        
                          </div>
                        </div>
                      </div>
                      <div class="w-full bg-white border border-gray-500 p-2 rounded-[10px]">
                        <div class="flex items-center justify-center space-x-2 mb-1">
                          <div class="text-black text-opacity-80 text-sm font-medium ">Percentage Found</div>
                        </div>
                        <div class="flex items-center justify-center ">
                          <div class="text-2xl font-bold text-gray-900 ">
                                {data1 ? (
                                <div class="">
                                  {data1.percentage_found ? data1.percentage_found : '0'}%
                                </div>
                              ) : (
                                ''
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full bg-white border border-gray-500 p-3 rounded-[10px]">
                    <div class="flex items-center justify-center space-x-2 mb-2">
                      <div class="text-sm font-medium text-[#363987] flex">
                        {/* <span class="text-black font-semibold mr-2">Summary:</span>  */}
                      <span class="text-black font-semibold bg-[#363987] text-white px-2 py-.5 rounded">Found</span>
                      </div>
                    </div>
                    <div class="flex items-center justify-center mt-10">
                      <div class="text-5xl font-bold text-[#363987]">
                        {data1 ? (
                                  <div class="">
                                    {data1.citations_summery_found
                                      ? data1.citations_summery_found
                                      : '0'}
                                  </div>
                                ) : (
                                  ''
                                )}
                      </div>
                    </div>
                  </div>
                  <div class="w-full bg-white border border-gray-500 p-3 rounded-[10px]">
                    <div class="flex items-center justify-center space-x-2 mb-2">
                      <div class="text-sm font-medium text-[#838383] flex">
                        {/* <span class="text-black font-semibold mr-2">Summary:</span>  */}
                        <span class="text-black font-semibold bg-[#838383] text-white px-2 py-.5 rounded">Not Found</span>
                      </div>
                    </div>
                    <div class="flex items-center justify-center mt-10">
                      <div class="text-5xl font-bold text-[#838383]">                    
                        {data1 ? (
                                  <div class="">
                                    {data1.citations_summery_not_found
                                      ? data1.citations_summery_not_found
                                      : '0'}
                                  </div>
                                ) : (
                                  ''
                                )}
                      </div>
                    </div>
                  </div>
                  <div class="w-full bg-white border border-gray-500 p-3 rounded-[10px]">
                    <div class="flex items-center justify-center space-x-2 mb-2">
                      <div class="text-sm font-medium text-[#0b9229] flex">
                        {/* <span class="text-black font-semibold mr-2">Summary:</span>  */}
                        <span class="text-black font-semibold bg-[#0b9229] text-white px-2 py-.5 rounded">Correct</span>
                      </div>
                    </div>
                    <div class="flex items-center justify-center mt-10">
                      <div class="text-5xl font-bold text-[#0b9229] ">
                        {data1 ? (
                                  <div class="">
                                    {data1.citations_summery_correct
                                      ? data1.citations_summery_correct
                                      : '0'}
                                  </div>
                                ) : (
                                  ''
                                )}                    
                      </div>
                    </div>
                  </div>
                  <div class="w-full bg-white border border-gray-500 p-3 rounded-[10px]">
                    <div class="flex items-center justify-center space-x-2 mb-2">
                      <div class="text-sm font-medium text-[#c70509]  flex">
                        {/* <span class="text-black font-semibold mr-2"></span>  */}
                        <span class="text-black font-semibold  bg-[#c70509] text-white px-2 py-.5 rounded">NAP Errors</span>
                        </div>
                    </div>
                    <div class="flex items-center justify-center mt-10">
                      <div class="text-5xl font-bold text-[#c70509]">
                        {data1 ? (
                                  <div class="">
                                    {data1.citations_summery_with_nap_errors
                                      ? data1.citations_summery_with_nap_errors
                                      : '0'}
                                  </div>
                                ) : (
                                  ''
                                )}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        {/* Business Listings Score end */}

        {/* Live Business Listings start */}
        <div className='w-full mb-5'>

          <h4 className="flex items-center text-lg font-bold text-navy-700 dark:text-white mb-5">
                <div className="flex items-center bg-lightPrimary dark:!bg-navy-700 h-10 w-10 mr-2 justify-center rounded-full text-brand-500 dark:text-white ">
                  <i class="fa-solid fa-location-dot"></i>
                </div> Live Business Listings
          </h4>
          
          <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5'>

          {data1 &&
            data1.active_citations?.map((row) => (
              
            <div class="relative !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !p-5 h-full">
                  
                  <div class="w-full flex items-center justify-between mt-0">
                    <div class="w-full flex items-center justify-start text-left text-black font-semibold text-left mb-3">
                      {/* <img src="/img/listings/192.png " alt="" class="w-[30px] h-[30px] mr-2 capitalize" />  */}
                      {row['source']}
                    </div>
                  </div>
                  <div class="flex flex-wrap items-center gap-3.5 text-[14px] 2xl:text-[15px] text-gray-800">
                    <div class="w-full flex"><i class="fa-solid fa-calendar-days text-[15px] mr-2 text-navy-700"></i> {row['date-identified']}</div>
                    <div class="w-full flex"><i class="fa-regular fa-window-restore text-[15px] mr-2 text-navy-700"></i> {row['domain-authority']}/100</div>
                    <div class="w-full flex"><i class="fa-solid fa-star text-[15px] mr-2 text-navy-700"></i> {row['citation-value']}</div>
                    <div class="w-full flex"><i class="fa-solid fa-globe text-[15px] mr-2 text-navy-700"></i> {row['site-type']}</div>
                  </div>
                  <div class="w-full flex items-center justify-between mt-4">
                    
                    <a href={row['url']} target="_blank" 
                    class="flex items-center justify-center rounded-full bg-brand-500 px-5 py-2 text-sm font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-100 hover:text-brand-500 active:bg-gray-200 ">
                      View Link <i class="fa-solid fa-arrow-right text-[15px] ml-2"></i></a>
                  </div>
                  

                  <div class="absolute right-5 top-5">
                        {row['need_to_fix'].length > 0 ? (
                        <a
                          href="#!"
                           onClick={()=>loadpopup(row)}
                        >
                          <i class="fa-solid fa-triangle-exclamation text-[24px] text-red-500"></i>
                        </a>
                      ) : (
                        <i class="fa-solid fa-circle-check text-[24px] text-green-500" ></i>
                        // onClick={()=>loadpopup(row)}
                      )}
                        </div>



             </div>
            ))}


          </div>           

        </div>                                  
        {/* Live Business Listings end */}

        {/* Business Listings start */}
        <div className='w-full mb-5'>

          <div class="flex items-center justify-between mb-5">
            <div class="flex items-center">
              <div class="text-lg font-bold text-navy-700">
                Business Listings</div> 
                {status_val===0?(
                <a onClick={handlePopup} href="#!" class="ml-3 inline-flex items-center px-5 py-2 text-[13px] font-medium leading-normal rounded-[30px] hover:bg-red-800 hover:text-white bg-red-500 text-white">
                  Action  {toggle ?   <Modal toggle={toggle} setToggle={setToggle}  loadremaining={loadremaining} />: ""}
                      <IoMdInformationCircle className="ml-1" onClick={loadremaining}/>
                </a>):( <a class="ml-3 inline-flex items-center px-5 py-2 text-[13px] font-medium leading-normal rounded-[30px] hover:bg-red-800 hover:text-white bg-red-500 text-white">
                  To be Added  
                </a>)}
              </div>
              <div class="flex ml-auto">
                <div className="w-full rounded-xl border border-gray-400 p-3 text-sm font-normal text-gray-800 outline-none dark:!border-white/10 sm:w-full md:w-fit">
                  <select className="text-lightFourth w-full rounded-xl pr-4 text-sm outline-none dark:text-white lg:w-36" value={value} onChange={Change}>
                    <option value='all'>All Listings</option>
                    <option value='issues'>With Nap Issues</option>
                    <option value='added'>To be added</option>
                  </select>   
                </div>
              </div>
          </div>

          {toggle1 ? <Modal2 toggle1={toggle1} setToggle1={setToggle1} issues_data={issues_data} setText={setText} handleToggle={handleToggle}  loadpopup={loadpopup}/>:""}
          <div className='w-full !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !p-5 h-full'>
          <SearchTableUsers
            tableData={tableData}
            columnsData={columnsDataUsersOverview}
            status_val={status_val}
          />
          {/* <table class="w-full">
                <thead className='bg-gray-50'>
                  <tr>
                  <th class="px-3 py-2.5 text-left text-sm font-bold uppercase1 tracking-wide text-gray-800">
                      Site/Directory
                    </th>
                    <th class="px-3 py-2.5 text-left text-sm font-bold uppercase1 tracking-wide text-gray-800">
                      Name
                    </th>
                    <th class="px-3 py-2.5 text-left text-sm font-bold uppercase1 tracking-wide text-gray-800">
                      Adress
                    </th>
                    <th class="px-3 py-2.5 text-left text-sm font-bold uppercase1 tracking-wide text-gray-800">
                      Postcode
                    </th>
                    <th class="px-3 py-2.5 text-left text-sm font-bold uppercase1 tracking-wide text-gray-800">
                      Phone
                    </th>
                     
                    <th class="px-3 py-2.5 text-left text-sm font-bold uppercase1 tracking-wide text-gray-800">
                      Satus
                    </th>
                     
                  </tr>
                </thead>
                {toggle1 ? <Modal toggle1={toggle1} setToggle1={setToggle1} issues_data={issues_data} setText={setText} handleToggle={handleToggle}  loadpopup={loadpopup}/>:""}
                <tbody class="w-full bg-[white]">
                   
                  {data1 &&
                    currentItems?.map((row) => (
                      <tr className='w-full border-b border-solid border-gray-100'>
                        <td class="px-3 py-2.5 text-sm">
                         
                        {row['source']}  </td>
                        <td class="px-3 py-2.5 text-sm">
                       
                          {row['business-name']}
                        </td>
                        <td class="px-3 py-2.5 text-sm">
                        
                          {row['address']}
                        </td>
                        <td class="px-3 py-2.5 text-sm">
                          
                          {row['postcode']}
                        </td>
                        <td class="px-3 py-2.5 text-sm">
                          
                          {row['telephone']}
                        </td>
                        <td className='px-3 py-2.5 text-sm'>
                        {row['need_to_fix'].length > 0 ? (
                        <a
                          href="#!"
                           onClick={()=>loadpopup(row)}
                        >
                          <i class="fa-solid fa-triangle-exclamation text-[24px] text-red-500"></i>
                        </a>
                      ) : (
                        <i class="fa-solid fa-circle-check text-[24px] text-green-500"  onClick={()=>loadpopup(row)}></i>
                      )}
                        </td>
                  
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination
        itemsPerPage={itemsPerPage} currentPage={currentPage} setItemsPerPage={setItemsPerPage}
        totalItems={data1.active_citations?.length}
        paginate={paginate}
      /> */}

          </div>
           

        </div>
        {/* Business Listings end */}


      
         

        

        
  </div>
    
  );
};
const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate,setItemsPerPage }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const hasNextPage = currentPage < totalPages;
  const hasPrevPage = currentPage > 1;

  const handleClickNext = () => {
    if (hasNextPage) {
      paginate(currentPage + 1);
    }
  };

  const handleClickPrev = () => {
    if (hasPrevPage) {
      paginate(currentPage - 1);
    }
  };
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
  };

  return (
    <nav className="flex justify-between my-4">
    <div className='flex flex-row'>
      <button
        onClick={handleClickPrev}
        disabled={!hasPrevPage}
        className={` flex mr-2 px-3 py-1 border rounded-md ${!hasPrevPage ? "text-gray-400 cursor-not-allowed" : ""}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
        prev
      </button>
      <span className='p-2 text-sm text-gray'>{currentPage} of {totalPages}</span>
      <button
        onClick={handleClickNext}
        disabled={!hasNextPage}
        className={` flex pagination-btn px-3 py-1 border rounded-md ${!hasNextPage ? "text-gray-400 cursor-not-allowed" : ""}`}
      >next
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
      </div>
      <div className="ml-4">
        <select onChange={handleItemsPerPageChange} value={itemsPerPage} className="border rounded-md px-2 py-1">
          <option value="5">5 per page</option>
          <option value="10">10 per page</option>
          <option value="20">20 per page</option>
          {/* Add more options as needed */}
        </select>
      </div>
    </nav>
  );
};
const Modal2 = (props) => {
  console.log(props+"sm");
  return (
    <div className="!z-10 fixed inset-0 flex items-center justify-center overflow-y-auto">
      <div className="!z-10 fixed inset-0 bg-gray-800 bg-opacity-20 backdrop-blur-sm backdrop-filter"></div> 

               
 
   
    <div class="!z-10 relative w-full lg:w-1/2 rounded bg-white p-8 shadow-md h-fit">
    <h2 class="text-black text-base font-[700] mb-2">Business Details </h2>  
    <button
            className="absolute right-2 top-2 text-gray-600 hover:text-gray-800"
            onClick={props.handleToggle}
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        <table class="w-full text-left rtl:text-right">
            <thead class="font-semibold uppercase1 bg-gray-50 ">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Address
                    </th>
                    <th scope="col" class="px-6 py-3">
                        
                        Postcode
                    </th>                        
                    <th scope="col" class="px-6 py-3">
                        Phone
                    </th>                         
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b border-solid border-[#e5e5e1]">
                    <th scope="row" class="px-6 py-4 text-gray-900">
                        { props.issues_data['need_to_fix'] && props.issues_data['need_to_fix'].includes("name") ?(
                            <a href="{props.issues_data['url']}" target="_blank" class="text-red-500 break-all">{props.issues_data['business-name'] }</a>)
                        :(
                            <a href="{props.issues_data['url']}" target="_blank" class="text-black-500 break-all">{props.issues_data['business-name'] }</a>
                        )}
                    </th>
                    <td class="px-6 py-4">
                        { props.issues_data['need_to_fix'] && props.issues_data['need_to_fix'].includes("address") ?(
                            <span  class="text-red-500 break-all">{props.issues_data['address'] }</span>)
                        
                        :(    <span  class="text-black-500 break-all">{props.issues_data['address'] }</span>
                        )}
                    </td>
                    <td class="px-6 py-4">
                        {['need_to_fix'] && props.issues_data['need_to_fix'].includes("postcode") ?(
                            <span  class="text-red-500 break-all">{props.issues_data['postcode'] }</span>)
                        :(
                            <span  class="text-black-500 break-all">{props.issues_data['postcode'] }</span>
                        )}
                    </td>
                    <td class="px-6 py-4">
                        {props.issues_data['need_to_fix'] && props.issues_data['need_to_fix'].includes("telephone")?(
                        <span  class="text-red-500 break-all">{props.issues_data['telephone'] }</span>)
                    
                    : (   <span  class="text-black-500 break-all">{props.issues_data['telephone'] }</span>)
                    }
                    </td> 
                </tr> 
                 
                 



            </tbody>
        </table>
    </div>
        

        
     
    </div>
  );
};
export default BusinessListings;
