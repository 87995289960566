import Details from "./components/Details";
import OrderStatus from "./components/OrderStatus";
import Receipt from "./components/Receipt";

const Settings = () => {
  return (
    <div className="mt-3 grid h-full w-full grid-cols-7 gap-[20px] rounded-[20px]">
      <div className="z-0 col-span-7 lg:col-span-3">
        <Receipt />
      </div>

      <div className="z-0 col-span-7 lg:col-span-4">
        <OrderStatus />
      </div>
      {/* <div className="z-0 col-span-7 lg:col-span-7">
        <Details />
      </div> */}
    </div>
  );
};

export default Settings;
