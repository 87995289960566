import { HiX } from 'react-icons/hi';
import Links from './components/Links';
import image2 from '../../assets/img/users/logo.png';
import SidebarCard from 'components/sidebar/components/SidebarCard';
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import avatar4 from 'assets/img/avatars/avatar4.png';
import routes from 'routes';
import Card from 'components/card';
import { useNavigate } from 'react-router-dom';
function SidebarHorizon(props) {
  const navigate = useNavigate();
  const { open, onClose, variant, mini, hovered, setHovered } = props;
  let user = JSON.parse(localStorage.getItem('auth'));
  // const handleLogout = () => {
  //   localStorage.removeItem('auth');
  //   navigate('/login');
  // };
  return (
    <div
      className={`sm:none ${
        mini === false
          ? 'w-[230px]'
          : mini === true && hovered === true
          ? 'w-[230px]'
          : 'w-[230px] xl:!w-[120px]'
      } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        variant === 'auth' ? 'xl:hidden' : 'xl:block'
      } ${open ? '' : '-translate-x-[105%]'}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card
        extra={`ml-3 w-full h-[96.5vh] sm:mr-4 sm:my-4 m-7 !rounded-[20px]`}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
              ? renderView
              : renderViewMini
          }
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <span
                className="absolute right-4 top-4 block cursor-pointer xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>
              <div className={`mt-[0px] flex items-center justify-center px-5`}>
                <img src={user?.logo_path} className="rounded-[6px] w-full" />
              </div>
              <div className="mb-7 mt-5 h-px bg-gray-200 dark:bg-white/10" />
              {/* Nav item */}
              <ul>
                <Links mini={mini} hovered={hovered} routes={routes} />
              </ul>
            </div>
            {/* Free Horizon Card    */}
            <div className="mb-[30px] mt-[28px]">
              {/* <div className="flex justify-center">
                <SidebarCard mini={mini} hovered={hovered} />
              </div> */}
              {/* Sidebar profile info */}
              <div className="mt-5 flex items-center justify-center gap-3">
                {/* <div className="h-12 w-12 rounded-full bg-blue-200">
                  <img src={avatar4} className="rounded-full" alt="avatar" />
                </div> */}
                <div
                  className={`ml-1 ${
                    mini === false
                      ? 'block'
                      : mini === true && hovered === true
                      ? 'block'
                      : 'block xl:hidden'
                  }`}
                >
                  <div className="w-full px-5 mb-2">
                    <img src="https://www.qualiconvert.com/images/logo_dark.png" class="rounded-[6px] w-full" />
                  </div>
                  <div className="w-full">
                    <div className="privacy_links flex items-center justify-center px-2 text-[13px] gap-1">
                      <a href="/admin/privacypolicy" className='border-r border-solid border-gray-200 pr-1 hover:underline text-gray-900'>Privacy Policy</a>
                      <a href="/admin/terms_conditions" className='hover:underline text-gray-900'>Terms &amp; Conditions</a>
                      {/* <a href="/cookie_policy">Cookie Policy</a><a href="/disclaimer">Disclaimer</a>*/}
                    </div>
                  </div>
                  <div className="w-full text-center px-3 mt-1">
                    <div className='text-[14px] text-gray-900'>Copyright © 2024 QualiConvert. All rights reserved.</div>
                  </div>
                  {/* <button
                    onClick={handleLogout}
                    class="linear cursor-pointer rounded-xl bg-brand-900 px-16 py-2 font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                  >
                    Logout
                  </button> */}
                  {/* <h4 className="text-base font-bold text-navy-700 dark:text-white">
                    Adela Parkson
                  </h4>
                  <p className="text-sm font-medium text-gray-600">
                    Product Designer
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </Card>
    </div>
  );
}
export default SidebarHorizon;