import React, { useEffect, useState } from 'react';
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Card from "components/card";
import axios from 'axios';

const Modal = (props) => {
  //state
  let user = JSON.parse(localStorage.getItem('auth'));
  const [fname,setfname]=useState('');
  const [lname,setlname]=useState('');
  const [email,setemail]=useState('');
  const [pnumber,setpnumber]=useState('');
  const [message,setMessage]=useState('');
  const [googlereview,setgooglereview]=useState('');
  const [facebookreview,setfacebookreview]=useState('');
  const [othersreview,setothersreview]=useState('');
  const [adminemail,setadminemail]=useState(user?.email);
  const [googledisplay,setgoogledisplay]=useState(false);
    const [facebookdisplay,setfacebookdisplay]=useState(false);
    const [othersdisplay,setothersdisplay]=useState(false);
    const [username,setusername]=useState(user?.username)

  useEffect(()=>{
    const fetchData = async () => {
      try {
        // Perform your asynchronous task here
        const response = await fetch(`https://dcp-backend.onrender.com/wrapper/reviewURLfindone?username=${user?.email}`);
        const result = await response.json();
        console.log(user)
        
        setgooglereview(result?.googlereview);
        setfacebookreview(result?.facebookreview);
        setothersreview(result?.othersreview);

        // setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }; 

    // Call the async function inside useEffect
    fetchData();

    // Since this is a cleanup function, you can return a function
    // to clean up any resources or subscriptions if necessary
    return () => {
      // Cleanup code here, if needed
    };
   
  },[])

   
   const handleChangegoogle=(e)=>{
    console.log(e.target.value)
    setgooglereview(e.target.value);
    setgoogledisplay(true);
   }

   const handleChangefacebook=(e)=>{
    console.log(e.target.value)
    setfacebookreview(e.target.value);
    setfacebookdisplay(true);
   }

   const handleChangeothers=(e)=>{
    console.log(e.target.value)
    setothersreview(e.target.value);
    setothersdisplay(true);
   }

 const handleUrlClick=async()=>{
  try {
    console.log(adminemail)
    const res = await axios.post(`https://dcp-backend.onrender.com/wrapper/reviewURL`, {
      googlereview,
      facebookreview,
      othersreview,
      googledisplay,
      facebookdisplay,
      othersdisplay,
      username,
      adminemail
    });
    console.log(res);
    props.setToggle1(false);
    // if (res && res.status === 200) {
    //   toast.success("login successful");
    // } else {
    //   toast.error("login failed");
    // }
  } catch (error) {
    console.log(error);
    // toast.error("Something went wrong");
  }
 }

  return (
    <div className="!z-10 fixed inset-0 flex items-center justify-center overflow-y-auto">
      <div className="!z-10 fixed inset-0 bg-gray-800 bg-opacity-20 backdrop-blur-sm backdrop-filter"></div>


      <div className="!z-10 relative w-1/2 rounded bg-white p-8 shadow-md h-fit text-gray-800" >
        <button
          className="absolute right-2 top-2 text-gray-800 hover:text-gray-800"
          onClick={props.loadremaining}
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>

        {/* 123 Stars Popup start */}
        {props.text == "form" ? (
  //         <div className="w-full">

  //           <h2 className="mb-4 text-xl font-semibold">We strive for 100% customer satisfaction. If we fell short, please tell us more so we can address your concerns.</h2>

  //           <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
  //             <InputField
  //               label="First Name"
  //               placeholder="Vlad"
  //               id="firstname"
  //               value={fname}
  //               onChange={handleChangeFname}
  //               type="text"
  //             />
  //             <InputField
  //               label="Last Name"
  //               placeholder="Mihalache"
  //               value={lname}
  //               onChange={handleChangeLname}
  //               id="lastname"
  //               type="text"
  //             />
  //             <InputField
  //               label="Email Address"
  //               placeholder="@horizon.ui"
  //               value={email}
  //               onChange={handleChangeEmail}
  //               id="email"
  //               type="text"
  //             />
  //             <InputField
  //               label="Phone"
  //               placeholder="205 2545 4554"
  //               value={pnumber}
  //               onChange={handleChangePhone}
  //               id="phone"
  //               type="text"
  //             />

  //           </div>

  //           <InputField
  //             label="Message"
  //             placeholder="Tell something about yourself in 150 characters!"
  //             value={message}
  //             onChange={handleChangeMessage}
  //             id="message"
  //             cols="30"
          
  //             rows="7"
  //           />
  //  <div class="flex w-full justify-start mt-5">
  //         <button onClick={handleFormClick} class="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">Save Changes</button>
  //       </div>
  //         </div>
  <div class="w-full lg:-mx-4 lg:flex">
  <div class="w-full lg:px-4">
    <div class="relative">
      <label
        for="name"
        class="mb-1 ml-1 block text-[16px] font-medium text-gray-800"
      >
        Mail Content
      </label>
      {/* <div class="w-full border bg-gray-100 p-5 outline-none"></div> */}
      <div
        class="editable w-full border  p-5 outline-none"
        contenteditable="false"
      >
        <div
          class="pt-10 w-full lg:w-[600px] rounded-lg border border-gray-100 bg-[white] p-10 shadow-xl shadow-gray-300  "
          style={{ 
            margin: '0px auto',
            padding: '0px',
            border: '1px solid #ccc',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              padding: '15px 10px',
              display: 'block',
              background: '#E5ECF6',
            }}
          >
            <img
              style={{
                width: '150px',
                height: 'auto',
                margin: '0 auto',
                background: '#ffffff',
                padding: '5px',
                borderRadius: '4px',
              }}
              src={user?.logo_path}
              alt="logo.png"
            />
          </div>
          <div
            style={{
              display: 'block',
              clear: 'both',
              textAlign: 'center',
              padding: '20px',
            }}
          >
            <p
              style={{
                // texAtlign: 'center',
                // margin: '16px 0px 32px 0px',
                // fontSize: '16px',
                // color: '#25282b',
                textAlign: 'center',
                margin: '16px 0px 32px 0px',
                fontSize: '18px',
                color: '#25282b',
                fontFamily: 'nunito',
              }}
            >
              Thank you for visiting our office, we truly hope
              you enjoyed your visit.
            </p>
            <p
              style={{
                textAlign: 'center',
                fontSize: '20px',
                color: '#25282b',
                fontFamily: 'nunito',
              }}
            >
              Our goal is to provide the best service
              possible. In order to do that, we are asking
              that you help us improve our services by
              providing your feedback. Your input helps build
              our relationship with each other as well as
              improve the experience of future patients at our
              practice.
            </p>
            <h2
              style={{
                textAlign: 'center',
                fontWeight: ' 600',
                fontSize: '16px',
                margin: '32px 64px',
                color: '#25282b',
              }}
            >
              Please leave us a review by clicking the stars
              below.
            </h2>
              
            <div className="w-full flex items-center justify-center gap-0">
            {/* <Rating value="78" stars="5" mb="mb-1" /> */}
           
                <a href='' ><img src="https://app.legaciestechno.com/dcp/img/stars_img123.webp" alt="" className='h-[130px] 1hover:opacity-70' /></a>
                <a href='https://www.google.com/search?q=chess+dental+practice+google+reviews&rlz=1C1RXQR_enIN1074IN1074&oq=chess+dental+practice+google+reviews&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGEAyCggCEAAYgAQYogQyCggDEAAYgAQYogQyCggEEAAYgAQYogTSAQg4NTUyajFqN6gCALACAA&sourceid=chrome&ie=UTF-8' target='_blank'><img src="https://app.legaciestechno.com/dcp/img/stars_img45.webp" alt="" className='h-[130px] 1hover:opacity-70' /></a>
            </div>

            {/* <div
              style={{
                textAlign: 'center',
                padding: '0px 40px',
              }}
            >
              <a href="#" target="_blank">
                <img
                  src="https://app.legaciestechno.com/dcp/img/stars_img.webp"
                  alt=""
                  style={{ width: '100%' }}
                />
              </a>
            </div> */}
          </div>
          <div
            style={{
              color: 'black',
              lineHeight: '18px',
              textAlign: 'center',
              padding: '15px 10px',
              display: 'block',
              clear: 'both',
              fontSize: '14px',
              background: '#E5ECF6',
            }}
          >
            Copyright © 2024 {user?.top_name}.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        ) : (<div>
              <Card extra={"w-full px-6 py-6"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Social Profile
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can set user social profiles
        </p>
      </div>
      {/* inputs */}
      <div className="mt-11 grid grid-cols-1 gap-3">
        <InputField
          label="Google url"
          placeholder="Google url"
          id="username"
          value={googlereview}
          onChange={handleChangegoogle}
          type="text"
        />
        <InputField
          label="Facebook page url"
          placeholder="Facebook page url"
          id="username"
          value={facebookreview}
          onChange={handleChangefacebook}
          type="text"
        />
        <>{console.log(user)}</>
        {user?.email!=="info@chesshousedental.com"?     <InputField
          label="others url"
          placeholder="others url"
          id="username"
          value={othersreview}
          onChange={handleChangeothers}
          type="text"
        />:""}
   

        {/* button */}
        <div className="mt-3 flex w-full justify-end">
          <button onClick={handleUrlClick} className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Save changes
          </button>
        </div>
      </div>
      {/* full width inputs */}
    </Card>
        </div>)}

     
      </div>

    </div>
  );
};

export default Modal;