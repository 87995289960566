import React, { useEffect, useState } from 'react';
// import DonutChart from 'components/charts/DonutChart';
import Conversion from './components/Conversion';
import axios from 'axios';
import LineChart from 'components/charts/LineChart';
import {
  lineChartDataMiniArea1,
  lineChartOptionsMiniArea1,
  lineChartDataMiniArea2,
  lineChartOptionsMiniArea2,
} from 'variables/charts';
import Stock from 'views/admin/main/account/billing/components/Stock';
import Card from './components/card';
import YourTransfers from './components/YourTransfers';
import Chart from 'react-apexcharts';
import DonutChart3 from './components/card/DonutChart3';

const Dashboard = () => {
  let user = JSON.parse(localStorage.getItem('auth'));
  console.log(user);

  const [donutdata, setDonutData] = useState([]);
  const [campaign_id, setCampaign_id] = useState();
  const [campaigndata, setCampaignData] = useState();
  const [user_email, setuser_email] = useState();
  const [user_id, setuser_id] = useState(user?.uid);
  useEffect(() => {
    if (user?.display_email === 'demo@chesshousedental.com') {
      setuser_email('info@chesshousedental.com');
    }
  }, []);
  //donut chart data
  //organic leads
  const [organic_phone_per, setorganic_phone_per] = useState();
  const [organic_form_per, setorganic_form_per] = useState();
  const [organic_chatboat, setorganic_chatboat] = useState();
  const [totalorganicleadcount, settotalorganicleadcount] = useState(0);

  //Paid leads
  const [paid_phone_per, setpaid_phone_per] = useState();
  const [paid_form_per, setpaid_form_per] = useState();
  const [paid_chatboat, setpaid_chatboat] = useState(0);
  const [totalpaidleadcount, settotalpaidleadcount] = useState(0);

  //barchart calculations

  const [currentcalls, setcurretcalls] = useState();
  const [currentforms, setcurrentforms] = useState();
  const [currentchatboats, setcurrentchatboats] = useState();
  const [previousforms, setpreviousforms] = useState();
  const [previouscalls, setpreviouscalls] = useState();
  const [previouschatboats, setpreviouschatboats] = useState();

  const [channeldata, setChanneldata] = useState([]);
  const [displaydata, setdisplaydata] = useState(false);
  const [OrganicData, setOrganicData] = useState({
    series: [
      {
        name: 'Current Month',
        data: [0, 0, 0], // Placeholder data for phone, website, chatbot
      },
      {
        name: 'Past Month',
        data: [0, 0, 0], // Placeholder data for phone, website, chatbot
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        style: {
          fontSize: '8px',
        },
        toolbar: {
          show: false, // Hide the toolbar icons
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top', // Display data labels at the top
          },
        },
      },
      title: {
        text: 'Month Wise Performance',
        align: 'center',
        margin: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#1b2559',
        },
      },
      xaxis: {
        categories: ['Phone Calls', 'Website Forms', 'Chatbot'],
        labels: {
          show: true,
          style: {
            colors: '#1b2559',
            fontSize: '11px',
            fontWeight: '600',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        color: 'black',
        labels: {
          show: true,
          style: {
            colors: '#CBD5E0',
            fontSize: '14px',
          },
        },
      },
      grid: {
        show: false,
        strokeDashArray: 5,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '40px',
        },
      },
      // fill: {
      //   type: "gradient",
      //   gradient: {
      //     type: "vertical",
      //     shadeIntensity: 1,
      //     opacityFrom: 0.7,
      //     opacityTo: 0.9,
      //     colorStops: [
      //       [
      //         {
      //           offset: 0,
      //           color: "var(--color-500)",
      //           opacity: 1,
      //         },
      //         {
      //           offset: 100,
      //           color: "rgba(255, 255, 255, 1)",
      //           opacity: 0.0,
      //         },
      //       ],
      //     ],
      //   },
      // },
      menu: {
        show: false, // Hide the menu icon
      },

      legend: {
        show: true,
        position: 'bottom',
      },
    },
  });

  const calculateCurrentPhoneCalls = (channelData) => {
    const lastMonth =
      channelData.channel_months[channelData.channel_months.length - 1];
    const lastMonthData = channelData.channel_data.find(
      (data) => data['Organic Forms'].month === lastMonth
    );
    const organicCallsCount = lastMonthData['Organic Calls'].count;
    const paidCallsCount = lastMonthData['Paid Calls'].count;
    const currentPhoneCalls = organicCallsCount + paidCallsCount;
    return currentPhoneCalls;
  };
  const calculateCurrentForms = (channelData) => {
    const lastMonth =
      channelData.channel_months[channelData.channel_months.length - 1];
    const lastMonthData = channelData.channel_data.find(
      (data) => data['Organic Forms'].month === lastMonth
    );
    const organicFormsCount = lastMonthData['Organic Forms'].count;
    const paidFormsCount = lastMonthData['Paid Forms'].count;
    const currentForms = organicFormsCount + paidFormsCount;
    return currentForms;
  };
  const calculateCurrentChatboats = (channelData) => {
    const lastMonth =
      channelData.channel_months[channelData.channel_months.length - 1];
    const lastMonthData = channelData.channel_data.find(
      (data) => data['Chatbot'].month === lastMonth
    );
    const organicChatbotCount = Number(lastMonthData['Chatbot'].count);
    console.log(typeof lastMonthData['Chatbot'].count);
    const currentChatbot = organicChatbotCount;
    return currentChatbot;
  };

  // const calculatePreviousPhoneCalls = (channelData) => {
  //   const channelDataArray = channelData.channel_data;

  //   const lastMonth =
  //   channelData.channel_months[channelData.channel_months.length - 1];
  //   const totalCurrentCalls = channelDataArray.reduce((sum, monthData) => {
  //     const organicCallsCount = monthData['Organic Calls'].count;

  //     const paidCallsCount = monthData['Paid Calls'].count;

  //     return sum + organicCallsCount + paidCallsCount;
  //   }, 0);

  //   return totalCurrentCalls;
  // };

  const calculatePreviousPhoneCalls = (channelData) => {
    const channelDataArray = channelData.channel_data;

    const lastMonthIndex = channelData.channel_months.length - 1;
    const totalCurrentCalls = channelDataArray.reduce(
      (sum, monthData, index) => {
        if (index !== lastMonthIndex) {
          const organicCallsCount = monthData['Organic Calls'].count;
          const paidCallsCount = monthData['Paid Calls'].count;
          return sum + organicCallsCount + paidCallsCount;
        } else {
          return sum;
        }
      },
      0
    );

    return totalCurrentCalls;
  };

  const calculatePreviousForms = (channelData) => {
    const channelDataArray = channelData.channel_data;
    const lastMonthIndex = channelData.channel_months.length - 1;
    const totalCurrentForms = channelDataArray.reduce(
      (sum, monthData, index) => {
        if (index !== lastMonthIndex) {
          const organicFormsCount = monthData['Organic Forms'].count;
          const paidFormsCount = monthData['Paid Forms'].count;

          return sum + organicFormsCount + paidFormsCount;
        } else {
          return sum;
        }
      },
      0
    );

    return totalCurrentForms;
  };
  const calculatePreviousChatbot = (channelData) => {
    const channelDataArray = channelData.channel_data;
    const lastMonthIndex = channelData.channel_months.length - 1;
    const totalCurrentForms = channelDataArray.reduce(
      (sum, monthData, index) => {
        if (index !== lastMonthIndex) {
          const organicFormsCount = Number(monthData['Chatbot'].count);

          return sum + organicFormsCount;
        } else {
          return sum;
        }
      },
      0
    );

    return totalCurrentForms;
  };

  const [PaidData, setPaidData] = useState({
    series: [
      {
        name: 'Current Month',
        data: [123, 89, 55], // Placeholder data for phone, website, chatbot
      },
      {
        name: 'Past Month',
        data: [45, 55, 20], // Placeholder data for phone, website, chatbot
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false, // Hide the toolbar icons
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      title: {
        text: 'Month Wise Performance',
        align: 'center',
        margin: 10,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#1b2559',
        },
      },
      xaxis: {
        categories: ['Phone calls', 'Website Forms', 'Chatbot'],

        labels: {
          show: true,
          style: {
            colors: '#1b2559',
            fontSize: '11px',
            fontWeight: '600',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        color: 'black',
        labels: {
          show: true,
          style: {
            colors: '#CBD5E0',
            fontSize: '14px',
          },
        },
      },
      grid: {
        show: false,
        strokeDashArray: 5,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '40px',
        },
      },
      fill: {
        // type: "gradient",
        // gradient: {
        //   type: "vertical",
        //   shadeIntensity: 1,
        //   opacityFrom: 0.7,
        //   opacityTo: 0.9,
        //   colorStops: [
        //     [
        //       {
        //         offset: 0,
        //         color: "var(--color-500)",
        //         opacity: 1,
        //       },
        //       {
        //         offset: 100,
        //         color: "rgba(154, 255, 255, 1)",
        //         opacity: 0.0,
        //       },
        //     ],
        //   ],
        // },
      },
      legend: {
        show: true,
        position: 'bottom',
      },
      menu: {
        show: false, // Hide the menu icon
      },
    },
  });
  //end

  let data1_dashboard_channels = [];

  let isChecked = false;
  async function handleCheckboxClick() {
    isChecked = !isChecked;
  }
  console.log(isChecked);

  let isChecked_keywords = false;
  async function handleCheckboxClickKeywords() {
    isChecked_keywords = !isChecked_keywords;
  }
  let isCheckedKeywords = false;
  async function handleCheckboxClickKeywords() {
    isCheckedKeywords = !isCheckedKeywords;
  }

  async function change_channel_period() {
    let data1_dashboard_channels_filter = [];
    let channel_filter = document.getElementById('home_channel').value;

    //nodejs
    const apiURL_dashboard_channel = `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_dashboard_channel&user_email=${user_email}&user_id=${usr_id}&channel_filter=${channel_filter}`;

    //php
    let usr_id = 225;
    // const apiURL_dashboard_channel =    "https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_dashboard_channel&user_email="+user_email+"&user_id="+usr_id+'&channel_filter='+channel_filter;
    console.log(apiURL_dashboard_channel);
    const response_dashboard_channel = await fetch(apiURL_dashboard_channel);
    data1_dashboard_channels_filter = await response_dashboard_channel.json();
    data1_dashboard_channels = data1_dashboard_channels_filter;
    console.log(data1_dashboard_channels);
  }

  function calculateTotalLeads(data, leadType) {
    const leadsArray = data[`${leadType.toLowerCase()}_leads`] || [];

    const totalLeads = leadsArray.reduce(
      (total, entry) => total + parseInt(entry.count, 10),
      0
    );

    return totalLeads;
  }

  const getdata = async () => {
    let filter_count = 1;
    const res = await axios.get(
      // `http://192.168.1.11:8003:8003/wrapper/organicForm?user_email=${user_email}&user_id=${user_id}&filter_count=${filter_count}`
      `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_dashboard&user_email=${user_email}&user_id=${user_id}&filter_count=${filter_count}`
    );
    console.log(res);
    setDonutData(res.data);
    const totalOrganicFormLeads = calculateTotalLeads(
      res.data,
      'organic_forms'
    );
    console.log(totalOrganicFormLeads);
    const totalOrganicCalls = calculateTotalLeads(res.data, 'organic_calls');
    const totalChatboats = calculateTotalLeads(res.data, 'organic_chat');
    const totalPaidForms = calculateTotalLeads(res.data, 'paid_form');
    const totalPaidCalls = calculateTotalLeads(res.data, 'paid_calls');
    const totalPaidchatboats = calculateTotalLeads(res.data, 'paid_chatboats');
    setorganic_phone_per(totalOrganicCalls);
    setorganic_form_per(totalOrganicFormLeads);
    setorganic_chatboat(totalChatboats);
    setpaid_form_per(totalPaidForms);
    setpaid_phone_per(totalPaidCalls);
    setpaid_chatboat(totalPaidchatboats);
  };

  useEffect(() => {
    // setCampaign_id()
    getdata();
    // getchanneldata();
    getcampaigndata(user?.campaign_id);
    setuser_id(user?.uid);
    setuser_email(user?.email);
  }, []);

  const change_graph_period = async (e) => {
    try {
      const res = await axios.get(
        // `http://192.168.1.11:8003:8003/wrapper/channelBottom?user_email=${user_email}&user_id=${user_id}&channel_filter=${e.target.value}`
        `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_dashboard_channel&user_email=${user_email}&user_id=${user_id}&channel_filter=${e.target.value}`
      );

      const currentcalls1 = await calculateCurrentPhoneCalls(res.data);
      const currentforms1 = await calculateCurrentForms(res.data);
      const prevcalls1 = await calculatePreviousPhoneCalls(res.data);
      const prevforms1 = await calculatePreviousForms(res.data);
      const currentchatboats1 = calculateCurrentChatboats(res.data);
      const previouschatboats1 = calculatePreviousChatbot(res.data);
      setcurretcalls((prevCalls) => currentcalls1);
      setcurrentforms((prevForms) => currentforms1);
      setpreviouscalls((prevPrevCalls) => prevcalls1);
      setpreviousforms((prevPrevForms) => prevforms1);
      // setpreviouschatboats(previouschatboats1)
      // setcurrentchatboats(currentchatboats1)

      // await setOrganicDataState();

      setOrganicData((prevData) => ({
        ...prevData,
        series: [
          {
            name: 'Current Month',
            data: [currentcalls1, currentforms1, currentchatboats1],
          },
          {
            name: 'Past Month',
            data: [prevcalls1, prevforms1, previouschatboats1], // Placeholder data for phone, website, chatbot
          },
        ],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            style: {
              fontSize: '8px',
            },
            toolbar: {
              show: false, // Hide the toolbar icons
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top', // Display data labels at the top
              },
            },
          },
          title: {
            text: 'Month Wise Performance',
            align: 'center',
            margin: 0,
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#1b2559',
            },
          },
          xaxis: {
            categories: ['Phone Calls', 'Website Forms', 'Chatbot'],
            labels: {
              show: true,
              style: {
                colors: '#1b2559',
                fontSize: '11px',
                fontWeight: '600',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
            color: 'black',
            labels: {
              show: true,
              style: {
                colors: '#CBD5E0',
                fontSize: '14px',
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + ' calls';
              },
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              const label = w.config.xaxis.categories[dataPointIndex];

              const percentage =
                (
                  (w.config.series[seriesIndex].data[dataPointIndex] /
                    w.globals.seriesTotals[seriesIndex]) *
                  100
                ).toFixed(2) + '%';

              return `<div class="bg-[#ffffff] flex flex-col gap-1">
                <div class="bg-[lightgrey] p-2">
               ${label}
                </div>
              <div class="p-2">${w.config.series[seriesIndex].name}: <span class="font-semibold">${w.config.series[seriesIndex].data[dataPointIndex]} (${percentage})</span></div>
              </div>`;
            },
          },
          grid: {
            show: false,
            strokeDashArray: 5,
            yaxis: {
              lines: {
                show: true,
              },
            },
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: '40px',
            },
          },
          // fill: {
          //   type: "gradient",
          //   gradient: {
          //     type: "vertical",
          //     shadeIntensity: 1,
          //     opacityFrom: 0.7,
          //     opacityTo: 0.9,
          //     colorStops: [
          //       [
          //         {
          //           offset: 0,
          //           color: "var(--color-500)",
          //           opacity: 1,
          //         },
          //         {
          //           offset: 100,
          //           color: "rgba(255, 255, 255, 1)",
          //           opacity: 0.0,
          //         },
          //       ],
          //     ],
          //   },
          // },
          menu: {
            show: false, // Hide the menu icon
          },

          legend: {
            show: true,
            position: 'bottom',
          },
        },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          // `http://192.168.1.11:8003:8003/wrapper/channelBottom?user_email=${user_email}&user_id=${user_id}&channel_filter=3`
          `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_dashboard_channel&user_email=${user_email}&user_id=${user_id}&channel_filter=3`
        );
        setChanneldata(res.data);
        console.log(res.data);
        const currentcalls1 = calculateCurrentPhoneCalls(res.data);
        const currentforms1 = calculateCurrentForms(res.data);
        // const currentchatboats1=calculateCurrentChatboats(res.data)
        const prevcalls1 = calculatePreviousPhoneCalls(res.data);
        const prevforms1 = calculatePreviousForms(res.data);
        const currentchatboats1 = calculateCurrentChatboats(res.data);
        const previouschatboats1 = calculatePreviousChatbot(res.data);

        setcurretcalls(currentcalls1);
        setcurrentforms(currentforms1);
        setpreviouscalls(prevcalls1);
        setpreviousforms(prevforms1);
        // setpreviouschatboats(previouschatboats1)
        // setcurrentchatboats(currentchatboats1)
        if (user?.display_email === 'demo@harrowwealddental.com') {
          setOrganicData({
            series: [
              {
                name: 'Current Month',
                // data: [currentcalls1, currentforms1, currentchatboats1],
                data: [0, 0, 0],
              },
              {
                name: 'Past Month',
                // data: [prevcalls1, prevforms1, previouschatboats1], // Placeholder data for phone, website, chatbot
                data: [0, 0, 0],
              },
            ],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                style: {
                  fontSize: '8px',
                },
                toolbar: {
                  show: false, // Hide the toolbar icons
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    position: 'top', // Display data labels at the top
                  },
                },
              },
              title: {
                text: 'Month Wise Performance',
                align: 'center',
                margin: 0,
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#1b2559',
                },
              },
              xaxis: {
                categories: ['Phone Calls', 'Website Forms', 'Chatbot'],
                labels: {
                  show: true,
                  style: {
                    colors: '#1b2559',
                    fontSize: '11px',
                    fontWeight: '600',
                  },
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
              },
              yaxis: {
                show: false,
                color: 'black',
                labels: {
                  show: true,
                  style: {
                    colors: '#CBD5E0',
                    fontSize: '14px',
                  },
                },
              },
              grid: {
                show: false,
                strokeDashArray: 5,
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '40px',
                },
              },

              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + ' calls';
                  },
                },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  const label = w.config.xaxis.categories[dataPointIndex];

                  const percentage =
                    (
                      (w.config.series[seriesIndex].data[dataPointIndex] /
                        w.globals.seriesTotals[seriesIndex]) *
                      100
                    ).toFixed(2) + '%';

                  return `<div class="bg-[#ffffff] flex flex-col gap-1">
                  <div class="bg-[lightgrey] p-2">
                 ${label}
                  </div>
                <div class="p-2">${w.config.series[seriesIndex].name}: <span class="font-semibold">${w.config.series[seriesIndex].data[dataPointIndex]} (${percentage})</span></div>
                </div>`;
                },
              },

              menu: {
                show: false, // Hide the menu icon
              },

              legend: {
                show: true,
                position: 'bottom',
              },
            },
          });
        } else {
          setOrganicData({
            series: [
              {
                name: 'Current Month',
                data: [currentcalls1, currentforms1, currentchatboats1],
                // data: [0, 0, 0],
              },
              {
                name: 'Past Month',
                data: [prevcalls1, prevforms1, previouschatboats1], // Placeholder data for phone, website, chatbot
                // data: [0, 0, 0],
              },
            ],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                style: {
                  fontSize: '8px',
                },
                toolbar: {
                  show: false, // Hide the toolbar icons
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    position: 'top', // Display data labels at the top
                  },
                },
              },
              title: {
                text: 'Month Wise Performance',
                align: 'center',
                margin: 0,
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#1b2559',
                },
              },
              xaxis: {
                categories: ['Phone Calls', 'Website Forms', 'Chatbot'],
                labels: {
                  show: true,
                  style: {
                    colors: '#1b2559',
                    fontSize: '11px',
                    fontWeight: '600',
                  },
                },
                axisBorder: {
                  show: false,
                },
                axisTicks: {
                  show: false,
                },
              },
              yaxis: {
                show: false,
                color: 'black',
                labels: {
                  show: true,
                  style: {
                    colors: '#CBD5E0',
                    fontSize: '14px',
                  },
                },
              },
              grid: {
                show: false,
                strokeDashArray: 5,
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '40px',
                },
              },

              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + ' calls';
                  },
                },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  const label = w.config.xaxis.categories[dataPointIndex];

                  const percentage =
                    (
                      (w.config.series[seriesIndex].data[dataPointIndex] /
                        w.globals.seriesTotals[seriesIndex]) *
                      100
                    ).toFixed(2) + '%';

                  return `<div class="bg-[#ffffff] flex flex-col gap-1">
                  <div class="bg-[lightgrey] p-2">
                 ${label}
                  </div>
                <div class="p-2">${w.config.series[seriesIndex].name}: <span class="font-semibold">${w.config.series[seriesIndex].data[dataPointIndex]} (${percentage})</span></div>
                </div>`;
                },
              },

              menu: {
                show: false, // Hide the menu icon
              },

              legend: {
                show: true,
                position: 'bottom',
              },
            },
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const filterchanneldata = async (e) => {
    const res = await axios.get(
      // `http://192.168.1.11:8003:8003/wrapper/channelBottom?user_email=${user_email}&user_id=${user_id}&channel_filter=${e.target.value}`
      `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_dashboard_channel&user_email=${user_email}&user_id=${user_id}&channel_filter=${e.target.value}`
    );
    setChanneldata(res.data);
  };

  const getcampaigndata = async (campaignid) => {
    try {
      // console.log(campaign_id);
      const res = await axios.get(
        // `http://192.168.1.11:8003:8003/wrapper/chessHouse?campaign_id=${campaign_id}`
        `https://app.legaciestechno.com/brightlocal/Examples/RankChecker/rankchecker_all.php?campaign_id=${campaignid}`
      );

      setCampaignData(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mt-5 h-full w-full gap-[20px] rounded-[20px] pb-10 sm:mt-5 xl:flex-row">
      <div className="mb-5 mt-5 flex items-center justify-between px-5">
        <h4 class="ml-px text-lg font-bold text-navy-700 dark:text-white">
          Month To Date Performance
        </h4>
        <div className="w-full rounded-xl border border-gray-400 p-3 text-sm font-normal text-gray-800 outline-none dark:!border-white/10 sm:w-full md:w-fit">
          <select
            className="text-lightFourth w-full rounded-xl pr-4 text-sm outline-none dark:text-white lg:w-36"
            onChange={change_graph_period}
            id="orgnaic_chart_id"
            name="orgnaic_chart_id"
          >
            <option value="1">Last Month</option>
            <option value="3">Last 3 Months</option>
            <option value="6">Last 6 Months</option>
            <option value="9">Last 9 Months</option>
          </select>
        </div>
      </div>

      <div className="grid w-full grid-cols-1 gap-5 px-5 lg:grid-cols-2">
        <div className="!z-5 relative flex flex-1 flex-col rounded-[20px] bg-[#E3F5FF] bg-white bg-clip-border p-5 pb-7 shadow-3xl  shadow-shadow-100 dark:!bg-navy-800  dark:text-white  dark:shadow-none">
          <div className="relative grid w-full grid-cols-1 gap-2 lg:grid-cols-2">
            <div className="col-span-2">
              {' '}
              {user?.display_email === 'demo@harrowwealddental.com'&&<div class="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white/80">
                <div class="  w-full rounded-xl bg-[#d3f26a] p-5 text-center text-lg font-bold">
                  No Data Found.
                  <br />
                </div>
              </div>}
              <h2 class="text-left text-lg font-bold text-navy-700">
                Organic Leads
              </h2>
            </div>
            <div className="rounded-lg border border-gray-300 p-2">
              {/* if (user?.display_email === 'demo@harrowwealddental.com'){

            } */}
              {user?.display_email === 'demo@harrowwealddental.com' ? (
                <>
                  <DonutChart3
                    title="Month to Date"
                    totalorganicleadcount={0}
                    usersPercentage={0}
                    viewsPercentage={0}
                    sessionsPercentage={0}
                  />
                </>
              ) : (
                <>
                  <DonutChart3
                    title="Month to Date"
                    totalorganicleadcount={totalorganicleadcount}
                    usersPercentage={organic_chatboat}
                    viewsPercentage={organic_phone_per}
                    sessionsPercentage={organic_form_per}
                  />
                </>
              )}

             
              {/* <DonutChart3
                title="Month to Date"
                totalorganicleadcount={totalorganicleadcount}
                usersPercentage={organic_chatboat}
                viewsPercentage={organic_phone_per}
                sessionsPercentage={organic_form_per}

                // totalorganicleadcount={0}
                // usersPercentage={0}
                // viewsPercentage={0}
                // sessionsPercentage={0}
              /> */}
            </div>
            <div className="rounded-lg border border-gray-300 p-2 pt-4">
              {OrganicData.options && OrganicData.series && (
                <Chart
                  options={OrganicData.options}
                  series={OrganicData.series}
                  type="bar"
                  height={350}
                  // options={OrganicData.options}
                  // series={OrganicData.series}
                  // type="bar"
                  // height={350}
                />
              )}
            </div>
          </div>
        </div>
        <div className="!z-5 relative flex flex-1 flex-col rounded-[20px] bg-[#E3F5FF] bg-white bg-clip-border p-5 pb-7 shadow-3xl  shadow-shadow-100 dark:!bg-navy-800  dark:text-white  dark:shadow-none">
          <div className="relative grid w-full grid-cols-1 gap-2 lg:grid-cols-2">
            <div class="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white/80">
              <div class="  rounded-xl bg-[#d3f26a] p-5 text-center text-lg font-bold">
                There are no live paid campaigns.
                <br />
                Please contact us at <span>support@qualiconvert.com</span> to
                set up a new campaign.
              </div>
            </div>

            <div className="rounded-lg border border-gray-300 p-2">
              <DonutChart3
                title="Month to Date"
                totalpaidleadcount={totalpaidleadcount}
                usersPercentage={paid_chatboat}
                viewsPercentage={paid_phone_per}
                sessionsPercentage={paid_form_per}
              />
            </div>
            <div className="rounded-lg border border-gray-300 p-2">
              <Chart
                options={PaidData.options}
                series={PaidData.series}
                type="bar"
                height={355}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="1flex hidden flex-wrap gap-4 px-5">
        <Card extra={'flex-1 '} backgroundcolor={'bg-[#E3F5FF]'}>
          <DonutChart3
            title="Organic Leads - Month to Date"
            usersPercentage={20}
            viewsPercentage={40}
            sessionsPercentage={60}
          />

          {/* <Conversion  title="Organic Leads"
            currentPercentage={donutdata?.organic_forms_percentage}
            pastPercentage={100 - donutdata?.organic_forms_percentage} /> */}
        </Card>
        <Card extra={'flex-1 '} backgroundcolor={'bg-[#E5ECF6]'}>
          {/* <Conversion   title="Organic Calls"
            currentPercentage={donutdata?.organic_calls_percentage}
            pastPercentage={100 - donutdata?.organic_calls_percentage} /> */}
          <div className="horizontal-bar-chart ">
            <Chart
              options={OrganicData.options}
              series={OrganicData.series}
              type="bar"
              height={250}
            />
            {/* <div className="mt-2 flex h-fit w-full items-center justify-center rounded-[20px] px-4 py-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <p className="ml-1 text-xs font-normal text-brand-500">Current</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            50
          </p>
        </div>
        <div className="mx-8 h-11 w-px bg-gray-400 dark:!bg-white/20" />
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <p className="ml-1 text-xs font-normal text-gray-600">
              Past
            </p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            50
          </p>
        </div>
      </div> */}
          </div>
        </Card>
        <Card extra={'flex-1'} backgroundcolor={'bg-[#E3F5FF]'}>
          <DonutChart3
            title="Paid Leads"
            usersPercentage={20}
            viewsPercentage={40}
            sessionsPercentage={60}
          />
        </Card>
        <Card extra={'flex-1'} backgroundcolor={'bg-[#E5ECF6]'}>
          <div className="horizontal-bar-chart ">
            <Chart
              options={PaidData.options}
              series={PaidData.series}
              type="bar"
              height={250}
            />
          </div>
        </Card>
      </div>
      <div className="mt-5 grid w-full grid-cols-3 gap-5 px-5">
        <div className="w-full">
          <YourTransfers campaigndata={campaigndata} type={'dashboard'} />
        </div>
        <div className="col-span-2 w-full">
          <Card
            extra={
              'w-full !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  h-full w-full pt-3 pb-10 px-8'
            }
            backgroundcolor={'bg-[#ffffff]'}
          >
            <div class="mb-4 flex items-center justify-between">
              <p class="text-lg font-bold text-navy-700 dark:text-white">
                Last Three Months Performance
              </p>
              <div className="w-full rounded-xl border !border-gray-400 px-3 text-sm font-medium text-gray-800 outline-none dark:!border-none dark:bg-navy-700 lg:w-36">
                <select
                  className="h-[45px] w-full rounded-xl text-sm font-medium text-gray-800 outline-none dark:bg-navy-700 "
                  onChange={filterchanneldata}
                  id="orgnaic_chart_id"
                  name="orgnaic_chart_id"
                >
                  <option value="3">Last 3 Months</option>
                  <option value="6">Last 6 Months</option>
                </select>
              </div>
            </div>

            <div className="overflow-x-auto ">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-3 py-2.5">
                      <div className="uppercase1 text-left text-sm font-bold tracking-wide text-gray-800">
                        Channel
                      </div>
                    </th>
                    {channeldata?.channel_months?.map((month, index) => (
                      <>
                        <th className="">
                          {index == channeldata?.channel_months.length - 1 ? (
                            <div className="uppercase1 text-left text-sm font-bold tracking-wide text-gray-800">
                              MTD
                            </div>
                          ) : (
                            <div className="uppercase1 text-left text-sm font-bold tracking-wide text-gray-800">
                              {month}
                            </div>
                          )}
                        </th>
                      </>
                    ))}
                  </tr>
                </thead>

                <tbody className="w-full">
                  <tr className="w-full border-b border-solid border-gray-100">
                    <th scope="row" className="font-xs px-3 py-2.5">
                      <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                        Website Forms {'(Organic)'}
                      </div>
                    </th>
                    {channeldata?.channel_data?.map((month, index) => (
                      <td class="px-3 py-2.5 text-sm">
                        {/* {month['Organic Forms']?.count} */}
                        {displaydata ? '0' : '0'}
                      </td>
                    ))}
                  </tr>

                  <tr className="w-full border-b border-solid border-gray-100">
                    <th scope="row" className="font-xs px-3 py-2.5">
                      <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                        Phone Calls {'(Organic)'}
                      </div>
                    </th>
                    {channeldata?.channel_data?.map((month, index) => (
                      <td class="px-3 py-2.5 text-sm ">
                        {/* {month['Organic Calls']?.count} */}
                        {displaydata ? '0' : '0'}
                      </td>
                    ))}
                  </tr>

                  <tr className="w-full border-b border-solid border-gray-100">
                    <th scope="row" className="font-xs px-3 py-2.5">
                      <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                        {' '}
                        Website Forms {'(Paid)'}
                      </div>
                    </th>
                    {channeldata?.channel_data?.map((month, index) => (
                      <td class="px-3 py-2.5 text-sm ">
                        {/* {month['Paid Forms']?.count} */}
                        {displaydata ? '0' : '0'}
                      </td>
                    ))}
                  </tr>

                  <tr className="w-full border-b border-solid border-gray-100">
                    <th scope="row" className="font-xs px-3 py-2.5">
                      <div className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                        Phone Calls {'(Paid)'}
                      </div>
                    </th>
                    {channeldata?.channel_data?.map((month, index) => (
                      <td class="px-3 py-2.5 text-sm ">
                        {/* {month['Paid Calls']?.count} */}
                        {displaydata ? '0' : '0'}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
