import React, { useState, useEffect } from 'react';
import Nft1 from 'assets/img/nfts/Nft1.png';
import { FaGoogle } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';
import { GrFormView } from 'react-icons/gr';
import { FaMessage } from 'react-icons/fa6';
import Modal from './Modal';
import { FaCalendarAlt } from 'react-icons/fa';
import Card from 'components/card';
import Rating from './Rating';

const Review = () => {
  let user = JSON.parse(localStorage.getItem('auth'));
  const [user_email, setuser_email] = useState(user?.email);
  const [user_id, setuser_id] = useState(user?.uid);

  useEffect(() => {
    setuser_id(user?.uid);
    setuser_email(user?.email);
  }, []);

  let [toggle, setToggle] = useState(false);

  // let total_rating_yelp = 0;
  let total_reviews_facebook = 0;
  //let total_reviews_google;

  let total_rating = 4.5;
  let total_reviews = 58;
  let total_reviews_yelp = 0;
  // let filter_data = [];
  //let main_columnheader=10;

  //  let data1_all=[];
  let [data1, setData] = useState([]);
  let [text, setText] = useState('');
  let [data1_all, setDataAll] = useState([]);
  // const apiURL =  "https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=review_data&email="+user_email;
  // const response = await fetch(apiURL);
  // data1 = await response.json();
  // total_reviews_google=data1.total_reviews_google;
  // total_reviews_facebook=data1.total_reviews_facebook;
  // total_reviews_yelp=data1.total_reviews_yelp;
  // total_reviews=data1.total_reviews;
  // total_rating=data1.total_rating;
  // total_rating_google=data1.total_rating_google;
  // total_rating_facebook=data1.total_rating_facebook;
  // total_rating_yelp=data1.total_rating_yelp;
  // console.log(apiURL);
  // const apiURL_all =  "https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_review_insights&user_email="+user_email;
  // console.log(apiURL_all);
  // const response_all = await fetch(apiURL_all);
  // data1_all = await response_all.json();
  // console.log(data1_all);
  // main_columnheader= data1_all.all_data_google;
  // reviews_count= data1_all.facebook_count;
  // console.log(reviews_count);
  // let currentDate = new Date().toJSON().slice(0, 10);
  async function getData() {
    try {
      const apiURL =
        'https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=review_data&email=' +
        user_email;
      const response = await fetch(apiURL);
      setData(await response.json());
      total_reviews_google = data1.total_reviews_google;
      total_reviews_facebook = data1.total_reviews_facebook;

      total_reviews = data1.total_reviews;
      total_rating = data1.total_rating;

      // total_rating_facebook = data1.total_rating_facebook;
      // total_rating_yelp = data1.total_rating_yelp;
      // console.log(apiURL);
      // console.log(total_reviews_google)
    } catch (error) {
      console.log(error);
    }
  }
  async function getAll() {
    try {
      const apiURL_all =
        'https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_review_insights&user_email=' +
        user_email;
      /// console.log(apiURL_all);
      const response_all = await fetch(apiURL_all);
      setDataAll(await response_all.json());
      // console.log(data1_all);
      main_columnheader = data1_all.all_data_google;
      reviews_count = data1_all.facebook_count;
      //console.log(reviews_count);
      let currentDate = new Date().toJSON().slice(0, 10);
    } catch (error) {
      console.log(error);
    }
  }
  function loadremaining(text) {
    // document.getElementById('leads_data').innerHTML = rev_data;
    setText(text);
    setToggle(!toggle);
  }
  useEffect(() => {
    getData();
    getAll();
  }, []);
  let total_rating_google = 0;
  // console.log(data1.total_rating_facebook)
  let total_reviews_google = data1.total_reviews_google;
  let main_columnheader = data1_all.all_data_google;
  // total_rating_facebook=;
  let reviews_count = data1_all.facebook_count;
  console.log(reviews_count);

  // Example usage:
  total_reviews_yelp = data1.total_reviews_yelp;
  total_rating_google = data1.total_rating_google;

  let total_rating_yelp = data1.total_rating_yelp;
  let total_rating_facebook = data1.total_rating_facebook;

  async function getreviewcount() {
    try {
      const data = {};

      data['source'] = document.getElementById('source').value;
      data['rate_by'] = document.getElementById('rate_by').value;
      data['start_date'] = document.getElementById('start_date').value;
      data['end_date'] = document.getElementById('end_date').value;

      console.log(data);
      //nodejs
      const res = await fetch(
        `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_review_insights&user_email=${user_email}`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data,
          }),
        }
      );

      //php
      //    const res = await fetch('https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_review_insights&user_email='+user_email, {
      //     method: 'POST',
      //     body: JSON.stringify({
      //         data
      //     })
      // })

      data1 = await res.json();
      setDataAll(data1);
      console.log(data1);
      main_columnheader = data1.all_data_google;
      reviews_count = data1.facebook_count;
    } catch (error) {
      console.log(error);
    }
  }

  const filter_data = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = {};
      for (let field of formData) {
        const [key, value] = field;
        data[key] = value;
      }
      // console.log(data);
      const res = await fetch(
        'https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_review_insights&user_email=' +
          user_email,
        {
          method: 'POST',
          body: JSON.stringify({
            data,
          }),
        }
      );
      data1 = await res.json();
      setDataAll(data1);
      main_columnheader = data1.all_data_google;
      reviews_count = data1.facebook_count;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div class="w-full px-5">
      <div class="w-full">
        <h4 className="mb-5 flex items-center text-lg font-bold text-navy-700 dark:text-white">
          <div className="mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-lightPrimary text-brand-500 dark:!bg-navy-700 dark:text-white ">
            <i class="fa-solid fa-star"></i>
          </div>{' '}
          Review
        </h4>
      </div>

      <div className="w-full">
        <div class="grid grid-cols-1 gap-5 lg:grid-cols-5">
          <div class=" w-full rounded-[10px] border border-gray-300 bg-indigo-50 p-3 text-center">
            <div class="flex1 mb-2 items-center justify-start space-x-2 rounded-md bg-white py-3">
              <i class="fa-brands fa-google text-[20px]"></i>{' '}
              <span class="pb-2 text-[26px] font-bold">
                ({total_reviews_google})
              </span>
              <div class="text-black mt-2 text-sm font-medium ">
                Google Reviews
              </div>
            </div>

            <div class="mt-3 flex items-center justify-center">
              <div class="text-black text-3xl font-bold">
                {total_rating_google?.toFixed(1)}
              </div>
            </div>
            <div class="flex items-center justify-center space-x-1 text-[16px] xl:text-[15px] 2xl:text-[15px]">
              <Rating value="100" stars={total_rating_google} mb="mb-1" />
              {/* <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i> */}
            </div>
            <div class="text-black mt-3 pb-2 ">Average Star Rating</div>
          </div>

          <div class="bg-black w-full rounded-[10px] border border-gray-500 bg-[#ffffff] bg-teal-50 p-3 text-center">
            <div class="flex1 mb-2 items-center justify-start space-x-2 rounded-md bg-white py-3">
              <i class="fa-brands fa-facebook-f text-[20px]"></i>{' '}
              <span class="pb-2 text-[26px] font-bold">
                ({total_reviews_facebook})
              </span>
              <div class="text-black mt-2 text-sm font-medium  ">
                Facebook Reviews
              </div>
            </div>

            <div class="mt-3 flex items-center justify-center">
              <div class="text-black text-3xl font-bold">
                {total_rating_facebook}
              </div>
            </div>
            <div class="flex items-center justify-center space-x-1 text-[16px] xl:text-[15px] 2xl:text-[15px]">
              <Rating value="100" stars={total_rating_facebook} mb="mb-1" />
              {/* <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i> */}
            </div>
            <div class="text-black mt-3 pb-2">Average Star Rating</div>
          </div>

          <div class="bg-black w-full rounded-[10px] border border-gray-500 bg-lime-50 p-3  text-center">
            <div class="flex1 mb-2 items-center justify-start space-x-2 rounded-md bg-[white] py-3">
              <i class="fa-brands fa-yelp text-[20px]"></i>{' '}
              <span class="pb-2 text-[26px] font-bold">
                {total_reviews_yelp}
              </span>
              <div class="text-black mt-2 text-sm font-medium  ">
                Yelp Reviews
              </div>
            </div>

            <div class="mt-3 flex items-center justify-center">
              <div class="text-black text-3xl font-bold">
                {total_rating_yelp}
              </div>
            </div>
            <div class="flex items-center justify-center space-x-1 text-[16px] xl:text-[15px] 2xl:text-[15px]">
              <Rating value="100" stars={total_rating_yelp} mb="mb-1" />
              {/* <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i>
                      <i class="fa-solid fa-star text-yellow-400"></i> */}
            </div>
            <div class="text-black mt-3 pb-2">Average Star Rating</div>
          </div>

          <div class="w-full rounded-[10px] border-4 border-gray-400 bg-white p-3 text-center">
            <div class="flex1 bg-gary-200 mb-2 items-center justify-start space-x-2 rounded-md py-3">
              <div class="text-black mt-2 text-sm font-medium  ">
                Total <br />
                Reviews
              </div>
            </div>

            <div class="mt-3 flex items-center justify-center">
              <div class="text-black text-3xl font-bold">{total_reviews}</div>
            </div>
            <div class="flex items-center justify-center space-x-1 text-[16px] xl:text-[15px] 2xl:text-[15px]">
              {/* <Rating value="100" stars={total_reviews} mb="mb-1" /> */}
              <i class="fa-solid fa-star text-yellow-400"></i>
              <i class="fa-solid fa-star text-yellow-400"></i>
              <i class="fa-solid fa-star text-yellow-400"></i>
              <i class="fa-solid fa-star text-yellow-400"></i>
              <i class="fa-solid fa-star text-yellow-400"></i>
            </div>

            <div class="text-black mt-3 pb-2">
              <i class="fa-brands fa-google mr-2 text-[18x]"></i>
              <i class="fa-brands fa-facebook-f mr-2 text-[18px]"></i>
              <i class="fa-brands fa-yelp text-[18x]"></i>
            </div>
          </div>

          <div class="w-full rounded-[10px] border-4 border-gray-400 bg-indigo-50 p-3  text-center">
            <div class="flex1 mb-2 items-center justify-start space-x-2 rounded-md bg-white py-3">
              <div class="text-black mt-2 text-sm font-medium  ">
                Average <br /> Star Rating
              </div>
            </div>

            <div class="mt-3 flex items-center justify-center">
              <div class="text-black text-3xl font-bold">
                {total_rating ? <span>{total_rating.toFixed(1)}</span> : ''}
              </div>
            </div>
            <div class="flex items-center justify-center space-x-1 text-[16px] xl:text-[15px] 2xl:text-[15px]">
              <i class="fa-solid fa-star text-yellow-400"></i>
              <i class="fa-solid fa-star text-yellow-400"></i>
              <i class="fa-solid fa-star text-yellow-400"></i>
              <i class="fa-solid fa-star text-yellow-400"></i>
              <i class="fa-solid fa-star text-yellow-400"></i>
            </div>

            <div class="text-black mt-3 pb-2">
              <i class="fa-brands fa-google mr-2 text-[18x]"></i>
              <i class="fa-brands fa-facebook-f mr-2 text-[18px]"></i>
              <i class="fa-brands fa-yelp text-[18x]"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="main_tabs w-full">
        <div class="mb-10 mt-5 w-full items-center border-b border-t border-solid border-[#e5e5e1] py-4">
          <div class="flex  ">
            <form
              onSubmit={filter_data}
              enctype="multipart/form-data"
              // class="flex w-[80%] gap-4 "
              className="w-full"
            >
              <div className="w-full items-center justify-between lg:flex">
                <div className="w-full items-center gap-5 lg:flex">
                  <div class="flex items-center gap-1">
                    <span class="mr-1 font-bold text-gray-900">Source by </span>
                    <select
                      onChange={() => getreviewcount()}
                      name="source"
                      id="source"
                      class="w-[8rem] rounded-xl border border-gray-600 bg-white px-2.5 py-1.5 leading-tight text-gray-700 "
                    >
                      <option value="All" class="">
                        All
                      </option>
                      <option value="Google" class="">
                        Google
                      </option>
                      <option value="Facebook" class="">
                        Facebook
                      </option>
                      <option value="Yelp" class="">
                        Yelp
                      </option>
                    </select>
                  </div>

                  <div class="flex items-center gap-1">
                    <span class="mr-1 font-bold text-gray-900">Rate by </span>
                    <select
                      name="rate_by"
                      id="rate_by"
                      onChange={() => getreviewcount()}
                      class="w-[8rem] rounded-xl border border-gray-600 bg-white px-2.5 py-1.5 leading-tight text-gray-700 "
                    >
                      <option value="All" class="">
                        All
                      </option>
                      <option value="5" class="">
                        5
                      </option>
                      <option value="4" class="">
                        4
                      </option>
                      <option value="3" class="">
                        3
                      </option>
                      <option value="2" class="">
                        2
                      </option>
                      <option value="1" class="">
                        1
                      </option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center lg:justify-end">
                  <div class="flex items-center gap-4">
                    <span class="flex items-center text-gray-900">
                      <strong>Filter By:</strong> Start{' '}
                    </span>
                    <input
                      max="{currentDate}"
                      type="date"
                      name="start_date"
                      id="start_date"
                      required
                      class=" rounded-xl border border-solid border-gray-600 bg-white px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none"
                    />
                    <span>To </span>
                    <input
                      max="{currentDate}"
                      type="date"
                      name="end_date"
                      id="end_date"
                      required
                      class="w-38 rounded-xl border border-solid border-gray-600 bg-white px-4 py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none"
                    />
                    <input
                      type="submit"
                      value="Go"
                      class="text-md cursor-pointer rounded-full bg-brand-500 px-4 py-2 font-normal text-white shadow-xl hover:bg-brand-700"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="w-full">
          <div class="grid grid-cols-1 grid-cols-1 gap-6 py-0 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4">
            {reviews_count
              ? reviews_count.map((item) => (
                  <div>
                    <div class="flex justify-between rounded-[20px] border border-gray-400 bg-white bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                      <div>
                        <h5 class="mb-0 text-[18px] font-[600] text-navy-800">
                          {' '}
                          {item.key}{' '}
                        </h5>
                        <p class="mt-2 text-[32px] font-[700] text-brand-500">
                          {item.value}{' '}
                        </p>
                      </div>
                      <div class="flex h-20 w-20 items-center justify-center rounded-full bg-lightPrimary text-brand-500 dark:!bg-navy-700 dark:text-white ">
                        {item.key == 'Google' ? (
                          <div class="flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-r from-brand-400 to-brand-600 text-2xl text-brand-500 text-white dark:text-white ">
                            <FaGoogle />
                          </div>
                        ) : item.key == 'Facebook' ? (
                          <div class="flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-r from-brand-400 to-brand-600 text-2xl text-brand-500 text-white dark:text-white ">
                            <FaFacebook />
                          </div>
                        ) : (
                          <div class="flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-r from-brand-400 to-brand-600 text-2xl text-brand-500 text-white dark:text-white ">
                            <i class="fa-brands fa-yelp"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : ''}
          </div>

          {/*          <div class="grid grid-cols-1 gap-6 py-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4">
            {reviews_count
              ? reviews_count.map((item) => (
                  <div class="!z-5 relative flex items-center justify-center rounded-[20px] bg-white border border-gray-400 bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !p-5 h-full">
                    <div class="mx-auto">
                      {item.key == 'Google' ? (
                        <div class="flex items-center bg-lightPrimary dark:!bg-navy-700 h-20 w-20 justify-center rounded-full text-2xl text-brand-500 dark:text-white ">
                          <FaGoogle />
                        </div>
                      ) : item.key == 'Facebook' ? (
                        <div class="flex items-center bg-lightPrimary dark:!bg-navy-700 h-20 w-20 justify-center rounded-full text-2xl text-brand-500 dark:text-white ">
                        <FaFacebook />
                        </div>
                      ) : (
                        <div class="flex items-center bg-lightPrimary dark:!bg-navy-700 h-20 w-20 justify-center rounded-full text-2xl text-brand-500 dark:text-white ">
                          <i class="fa-brands fa-yelp"></i>
                        </div>                        
                      )}
                    </div>
                    <div className='text-center mt-2'>
                      <p class="mb-0 text-[18px] font-[600] text-navy-800">
                        {item.key}
                      </p>
                      <p class="text-brand-500 text-[32px] font-[700]">
                        {item.value}
                      </p>
                    </div>
                  </div>

                        

                ))
              : ''}
          </div> */}

          <div class="mb-5 mt-10 grid w-full grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
            {toggle ? (
              <Modal
                toggle={toggle}
                setToggle={setToggle}
                loadremaining={loadremaining}
                setText={setText}
                text={text}
              />
            ) : (
              ''
            )}

            {data1_all?.all_data_google
              ? data1_all?.all_data_google.map((item) => (
                  <Card
                    extra={
                      'task flex w-[470px] max-w-full flex-col rounded-2xl border-gray-200 bg-white p-[25px] shadow-3xl shadow-shadow-100 dark:!bg-navy-700 dark:!shadow-none'
                    }
                  >
                    <div class="mt-0 flex w-full items-center justify-between ">
                      <div class="lg:cols-3 md:cols-6 sm:cols-12 flex items-center">
                        <div class="">
                          <div class="inline-flex  justify-between rounded-[5px] bg-green-500 pl-2 pr-2 font-medium capitalize leading-normal text-white">
                            {item.source}
                          </div>
                        </div>
                        <div class="ml-3 hidden rounded-full bg-[#F5F5EB] p-2">
                          <i class="fa-solid fa-globe text-[20px]"></i>
                        </div>
                      </div>
                      <div class=" items-center  ">
                        <div class="flex flex-row text-lg font-[600] text-gray-900">
                          {item.date}
                        </div>
                      </div>
                    </div>
                    <div class="justify-between1 relative mt-5 flex w-full items-start">
                      <div class="flex flex-row flex-wrap items-center gap-5 text-[14px] 2xl:text-[15px]">
                        <div class="flex flex-wrap items-center gap-3 text-[12px] 2xl:text-[15px]">
                          <div class="flex w-full gap-3">
                            {item.review != '' && item.review.length > 14 ? (
                              <div className="flex flex-row gap-0 text-gray-900">
                                {' '}
                                {/* <span className="mt-1.5"> */}
                                {item.review.substring(0, 120)}...
                                <span>
                                  {' '}
                                  <GrFormView
                                    onClick={() =>
                                      loadremaining(
                                        item.review.substring(0, 200)
                                      )
                                    }
                                    className="text-4xl text-blue-500"
                                  />
                                </span>
                                {/* </span> */}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        <div class=" flex w-full flex-row gap-2 text-yellow-500">
                          {Array.from({ length: Number(item.rating) }).map(
                            (_, index) => (
                              <div key={index}>
                                <FaStar />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                ))
              : ' '}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
