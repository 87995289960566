import React, { useState } from 'react';
import Modal from './Modal';
// import ReviewUs from './ReviewStatus/Review_us'
import image1 from '../../../../../assets/img/users/logo_chesshousedental.jpg';
import Nft1 from 'assets/img/nfts/Nft1.png';
import Rating from "./Rating";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ReviewMe = () => {
  let user = JSON.parse(localStorage.getItem('auth'));

 const [To,setTo]=useState('');
 const [Subject,setSubject]=useState('');
 const [toggle,setToggle]=useState(false);
 let [toggle1,setToggle1]=useState(false);
 const [text,setText]=useState();
 const navigate=useNavigate()

 const handleToChange=(e)=>{
  setTo(e.target.value)
 }

 const handleSubjectChange=(e)=>{
  setSubject(e.target.value)
 }

 //let issues_data = [];
//  function loadremaining(){
 
//   window.open("/reviewus", "_blank");
 
// }
function loadremaining() {
  let username =user?.username;
  // navigate(`/reviewus/${user?.username}`, { state: { user: user } });
  const params = encodeURIComponent(JSON.stringify(user));
  window.open(`/reviewus/${username}?data=${params}`, "_blank");

}

  const handleSend=async(e)=>{
    e.preventDefault();
   let data={};
   data.user_email=user?.email;
   data.location=user?.top_name;
   data.mail_subject=Subject;
   data.to_email=To;


   const res = await axios.post(`https://dcp-backend.onrender.com/wrapper/sendmailreview`, {
   data
  });
   console.log(res)
    // const res_filter = await fetch(
    //   `http://localhost:8003/wrapper/sendmailreview`,
    //   {
    //     method: 'post',
     
    //     body: JSON.stringify({
    //       data,
    //     }),
    //   }
    // );

      // let result=await res_filter.json();
      if(res.data.message=="success"){
        
        alert("Send Mail Successfully")
      }
      else{
        alert("Error")
      }
   console.log(data,user)
  }

  return (
    <div class="w-full px-5">

        <h4 class="flex items-center text-lg font-bold text-navy-700 dark:text-white mb-5">
          <div class="flex items-center bg-lightPrimary dark:!bg-navy-700 h-10 w-10 mr-2 justify-center rounded-full text-brand-500 dark:text-white ">
            <i class="fa-solid fa-star"></i>
            </div> Review Me
        </h4>

        <div className="w-full">

            

            <form class="mb-0 mt-6 space-y-4 rounded-lg border border-gray-400 p-8 shadow-2xl">

                  <h2 class="flex items-center text-[20px] font-[600]">
                    <i class="fa-solid fa-envelope bg-blue-500 mr-3 rounded-full px-3 py-2 text-xl text-white"></i>
                    Request Reviews from Individual Customers
                  </h2>

                  <div class="w-full lg:-mx-4 lg:flex">
                    <div class="w-full lg:w-1/3 lg:px-4">
                      <input
                        type="hidden"
                        name="user_email"
                       
                        value="monica@gmail.com"
                      />
                      <div class="relative">
                        <label
                          for="name"
                          class="mb-1 ml-1 block text-[16px] font-medium text-gray-800"
                        >
                          To <span className='text-[12px]'>Please provide your addresses separated by commas</span>
                        </label>
                        <input
                          type="text"
                          name="to_email"
                          id="to_email"
                          value={To}
                          onChange={handleToChange}
                          placeholder="Please Enter email address"
                          class="w-full rounded-[10px] border border-gray-300 bg-gray-100 bg-opacity-100 px-5 py-2.5 text-base text-gray-700 outline-none"
                          required=""
                        />
                      </div>
                    </div>
                    <div class="w-full lg:w-1/3 lg:px-4">
                      <div class="relative">
                        <label
                          for="name"
                          class="mb-1 ml-1 block text-[16px] font-medium text-gray-800"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          name="mail_subject"
                          id="mail_subject"
                          value={Subject}
                          onChange={handleSubjectChange}
                          placeholder="We value your feedback"
                          class="w-full rounded-[10px] border border-gray-300 bg-gray-100 bg-opacity-100 px-5 py-2.5 text-base text-gray-700 outline-none"
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 w-full lg:-mx-4 lg:flex">
                    <div class="w-full lg:px-4">
                      <div class="relative">
                        <label
                          for="name"
                          class="mb-1 ml-1 block text-[16px] font-medium text-gray-800"
                        >
                          Mail Content
                        </label>
                        <div class="w-full border bg-gray-100 p-5 outline-none"></div>
                        <div
                          class="editable w-full border  p-5 outline-none"
                          contenteditable="false"
                        >
                          <div
                            class="pt-15 w-full lg:w-[600px] rounded-lg border border-gray-100 bg-[white] p-10 shadow-xl shadow-gray-300  "
                            style={{ 
                              margin: '0px auto',
                              padding: '0px',
                              border: '1px solid #ccc',
                            }}
                          >
                            <div
                              style={{
                                textAlign: 'center',
                                padding: '15px 10px',
                                display: 'block',
                                background: '#E5ECF6',
                              }}
                            >
                              <img
                                style={{
                                  width: '150px',
                                  height: 'auto',
                                  margin: '0 auto',
                                  background: '#ffffff',
                                  padding: '5px',
                                  borderRadius: '4px',
                                }}
                                src={user?.logo_path}
                                alt="logo.png"
                              />
                            </div>
                            <div
                              style={{
                                display: 'block',
                                clear: 'both',
                                textAlign: 'center',
                                padding: '20px',
                              }}
                            >
                              <p
                                style={{
                                  // texAtlign: 'center',
                                  // margin: '16px 0px 32px 0px',
                                  // fontSize: '16px',
                                  // color: '#25282b',
                                  textAlign: 'center',
                                  margin: '16px 0px 32px 0px',
                                  fontSize: '18px',
                                  color: '#25282b',
                                  fontFamily: 'nunito',
                                }}
                              >
                                Thank you for visiting our office, we truly hope
                                you enjoyed your visit.
                              </p>
                              <p
                                style={{
                                  textAlign: 'center',
                                  fontSize: '20px',
                                  color: '#25282b',
                                  fontFamily: 'nunito',
                                }}
                              >
                                Our goal is to provide the best service
                                possible. In order to do that, we are asking
                                that you help us improve our services by
                                providing your feedback. Your input helps build
                                our relationship with each other as well as
                                improve the experience of future patients at our
                                practice.
                              </p>
                              <h2
                                style={{
                                  textAlign: 'center',
                                  fontWeight: ' 600',
                                  fontSize: '16px',
                                  margin: '32px 64px',
                                  color: '#25282b',
                                }}
                              >
                                Please leave us a review by clicking the stars
                                below.
                              </h2>
                                
                              <div className="w-full flex items-center justify-center gap-0">
                              {/* <Rating value="78" stars="5" mb="mb-1" /> */}
                             
                                  <a href='' onClick={()=>loadremaining()} ><img src="https://app.legaciestechno.com/dcp/img/stars_img123.webp" alt="" className='h-[130px] 1hover:opacity-70' /></a>
                                  <a href='https://www.google.com/search?q=chess+dental+practice+google+reviews&rlz=1C1RXQR_enIN1074IN1074&oq=chess+dental+practice+google+reviews&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGEAyCggCEAAYgAQYogQyCggDEAAYgAQYogQyCggEEAAYgAQYogTSAQg4NTUyajFqN6gCALACAA&sourceid=chrome&ie=UTF-8' target='_blank' onClick={()=>loadremaining()}><img src="https://app.legaciestechno.com/dcp/img/stars_img45.webp" alt="" className='h-[130px] 1hover:opacity-70' /></a>
                              </div>

                              {/* <div
                                style={{
                                  textAlign: 'center',
                                  padding: '0px 40px',
                                }}
                              >
                                <a href="#" target="_blank">
                                  <img
                                    src="https://app.legaciestechno.com/dcp/img/stars_img.webp"
                                    alt=""
                                    style={{ width: '100%' }}
                                  />
                                </a>
                              </div> */}
                            </div>
                            <div
                              style={{
                                color: 'black',
                                lineHeight: '18px',
                                textAlign: 'center',
                                padding: '15px 10px',
                                display: 'block',
                                clear: 'both',
                                fontSize: '14px',
                                background: '#E5ECF6',
                              }}
                            >
                              Copyright © 2024 {user?.top_name}.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="right-0">
                    <i
                      class="fa fa-refresh"
                      aria-hidden="true"
                      style={{ float: 'right' }}
                    ></i>
                  </div>

                  <div class="flex mt-5 space-x-2 lg:space-x-3">
                    <button type="submit" onClick={handleSend} class="rounded-full bg-brand-500 px-6 py-3 text-sm font-medium text-white">Send</button>
                  </div>

                  {/* <div class="mt-5 flex 1hidden space-x-2 lg:space-x-3 ">
                    <a
                      href="#!"
                      class="text-md hidden rounded-full bg-gray-700 px-6 py-2 font-normal text-white shadow-xl hover:bg-gray-500"
                    >
                      Download History
                    </a>
                    <a
                      href="#!"
                      class="bg-primarycolor-500 text-md hover:bg-secondarycolor-500 hidden rounded-full px-6 py-2 font-normal text-white shadow-xl"
                    >
                      Clear History
                    </a>
                  </div> */}



                </form>

          
        </div>
        {/* {toggle1 ? (
              <ReviewUs
              toggle1={toggle1}
              setToggle1={setToggle1}
              loadremaining={loadremaining}
              setText={setText}
              text={text}
              />
            ) : (
              ''
            )} */}

       
    </div>
  );
};

export default ReviewMe;

