// import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Chart = ({ data }) => {
  let averagedData;
  let formatXAxis;

  if (Array.isArray(data) && data.length > 0) {
    const monthlyData = data.reduce((acc, entry) => {
      const month = entry.date.substring(0, 7); // Extract YYYY-MM
      if (!acc[month]) {
        acc[month] = { date: month, views: 0, sessions: 0, users: 0, count: 0 };
      }
      acc[month].views += entry.views;
      acc[month].sessions += entry.sessions;
      acc[month].users += entry.users;
      acc[month].count += 1;
      return acc;
    }, {});

    // Sort the months chronologically
    const sortedMonths = Object.keys(monthlyData).sort();

    averagedData = sortedMonths.map(month => {
      const avgMonthData = monthlyData[month];
      return {
        date: avgMonthData.date,
        views: Math.round (avgMonthData.views / avgMonthData.count),
        sessions:  Math.round(avgMonthData.sessions / avgMonthData.count),
        users:  Math.round(avgMonthData.users / avgMonthData.count),
      };
    });

    const monthNames = new Intl.DateTimeFormat('en-US', { month: 'long' });
    formatXAxis = (tickItem) => monthNames.format(new Date(tickItem));
  } else {
    // Handle the case when data is undefined or not an array
    averagedData = [];
    formatXAxis = () => ''; // You can adjust this based on your needs
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={averagedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={formatXAxis} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="views" stroke="#8884d8" name=" Views" />
        <Line type="monotone" dataKey="sessions" stroke="#82ca9d" name=" Sessions" />
        <Line type="monotone" dataKey="users" stroke="#ffc658" name=" New Users" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
