import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend ,Label} from 'recharts';

const DonutChart = ({ title,viewsPercentage, sessionsPercentage, usersPercentage }) => {
  
  const data = [
    { name: 'Phone', value: viewsPercentage , color: '#4EACF4' },
    { name: 'Website', value:sessionsPercentage , color: '#6F21D1' },
    { name: 'Chatbot', value:usersPercentage , color: '#EB55A5' },
  ];
  
  const total = data.reduce((acc, entry) => acc + entry.value, 0);
  
  const renderTooltipContent = ({ payload }) => {
    if (payload && payload.length) {
      const { value, name } = payload[0].payload;
      const percentage = ((value / total) * 100).toFixed(2);
      return (
        <div style={{ backgroundColor: '#fff', padding: '5px' }}>
          <p>{name}: {value} ({percentage}%)</p>
        </div>
      );
    }
    return null;
  };

  

  return (
    <div className="flex flex-col items-center mb-8"> {/* Adjust the margin bottom as needed */}
    <div className="w-full h-[21rem] relative"> {/* Adjust the height of the chart area */}
      <h2 className="1absolute left-14 top-2 text-[14px] font-bold text-[#1b2559] text-center pt-2 z-10">{title}</h2>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius="50%"
            outerRadius="70%"
            fill="#8884d8"
            label={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
            <Label width={20} className='text-[#1b2559] text-[13px] font-[600]' position="center">
              { `Total Leads:${total}` }
                </Label>
          </Pie>
          <Tooltip content={renderTooltipContent} />
          
        </PieChart>
      </ResponsiveContainer>
    </div>
    <div className="flex gap-2">
      {data.map((entry, index) => (
        <div key={index} className="flex items-center">
          <div className="w-3 h-3 rounded-full mr-1" style={{ backgroundColor: entry.color }}></div>
          <span className='text-[#1b2559] text-[13px] font-[600]'>{entry.name}</span>
        </div>
      ))}
    </div>
  </div>
);
};


export default DonutChart;

