import { IoMdStar, IoMdStarOutline } from "react-icons/io";

const Rating = (props) => {
  const { stars, value, mb } = props;
  return (
 
    
      <div className="flex items-center text-2xl  text-yellow-500">
        <p> {stars >= 1 ? <IoMdStar /> : <IoMdStarOutline />} </p>
        <p> {stars >= 2 ? <IoMdStar /> : <IoMdStarOutline />} </p>
        <p> {stars >= 3 ? <IoMdStar /> : <IoMdStarOutline />} </p>
        <p> {stars >= 4 ? <IoMdStar /> : <IoMdStarOutline />} </p>
        <p> {stars >= 5 ? <IoMdStar /> : <IoMdStarOutline />} </p>
      </div>

    
  );
};

export default Rating;
