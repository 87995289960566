import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import React, { useEffect, useMemo, useState } from "react";
import ModalComponent from "./Modal";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";

const ColumnsTable = (props) => {
    const { columnsData, tableData, labelvalue } = props;

    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);

    let user = JSON.parse(localStorage.getItem('auth'));
    const[user_email,setuser_email]=useState(user?.email);
    const [user_id,setuser_id]=useState(user?.uid)
   
    useEffect(()=>{
    setuser_id(user?.uid)
    setuser_email(user?.email)
    },[])

    const tableInstance = useTable(
        {
            columns,
            data,
        },

        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex, pageSize },
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageCount,
        // initialState,
    } = tableInstance;

    const [isModalOpen, setModalOpen] = useState(false);
    const [leadsData, setLeadsData] = useState('');
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    async function change_status(rid, tname, status_type) {
        
        let response_data_d_change =[];
     
        console.log(rid, tname.target.value, status_type)
        const apiURL_change = `https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=dashboard_change_status_data?rid=${rid.original.id}&status_type=${tname.target.value}&tname=${rid.original.tablename}&user_email=${user_email}`;
            //  console.log(apiURL_change)
        // const apiURL_change = "https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=dashboard_change_status_data&id="+rid+"&tname="+tname+'&status='+status_type;
        // console.log(apiURL_change);
        const response_change = await fetch(apiURL_change);
        response_data_d_change = await response_change.json();
        console.log(response_data_d_change);
        // if (response_data_d_change.status) {
        //   alert('Status Changed Successfully', 'info');
        //   // window.location.href="/";
    
        //   //nodejs
        //   const apiURL_dashboard_organic = `http://192.168.1.11:8003:8003/wrapper/organic?user_email=${user_email}&user_id=${user_id}`;
    
        //   //php
        //   // auto load code start
        //   //    let usr_id = 225;
        //   //    const apiURL_dashboard_organic =    "https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=viewall_leads_new&user_email="+user_email+"&user_id="+usr_id
    
        //   console.log(apiURL_dashboard_organic);
        //   const response_dashboard_organic = await fetch(apiURL_dashboard_organic);
        //   let data1_dashboard_organic_autoload =
        //     await response_dashboard_organic.json();
        //   let data1_dashboard_organic1 = data1_dashboard_organic_autoload;
        // //   setdata1_dashboard_organic(data1_dashboard_organic1);
        //   console.log(data1_dashboard_organic);
    
        //   let organic_booked_autoload = [];
        //   let organic_newlead_autoload = [];
        //   let verified_booked_autoload = [];
        //   let verified_forms_count_autoload = 0;
        //   let organic_no_consultation_autoload = [];
        //   let verified_organic_calls_count_autoload = 0;
        //   let verified_chatbot_count_autoload = 0;
        //   for (let item of data1_dashboard_organic1.total_forms) {
        //     // console.log(item.status);
        //     item.type = 'website_form';
        //     if (item.status == 'booked' || item.status == 'enquiry') {
        //       organic_booked_autoload.push(item);
        //     }
        //     if (item.status == 'newlead') {
        //       organic_newlead_autoload.push(item);
        //       verified_booked_autoload.push(item);
        //       verified_forms_count_autoload = verified_forms_count_autoload + 1;
        //     }
        //     if (
        //       item.status == 'no_consultation' ||
        //       item.status == 'existing_patient'
        //     ) {
        //       organic_no_consultation_autoload.push(item);
        //       verified_booked_autoload.push(item);
        //       verified_forms_count_autoload = verified_forms_count_autoload + 1;
        //     }
        //   }
        //   for (let item of data1_dashboard_organic1.total_organic_calls) {
        //     // console.log(item.status);
        //     item.type = 'phone_call';
        //     if (item.status == 'booked' || item.status == 'enquiry') {
        //       organic_booked_autoload.push(item);
        //     }
        //     if (item.status == 'newlead') {
        //       organic_newlead_autoload.push(item);
        //       verified_booked_autoload.push(item);
        //       verified_organic_calls_count_autoload =
        //         verified_organic_calls_count_autoload + 1;
        //     }
        //     if (
        //       item.status == 'no_consultation' ||
        //       item.status == 'existing_patient'
        //     ) {
        //       organic_no_consultation_autoload.push(item);
        //       verified_booked_autoload.push(item);
        //       verified_organic_calls_count_autoload =
        //         verified_organic_calls_count_autoload + 1;
        //     }
        //   }
        //   for (let item of data1_dashboard_organic1.total_chatboat) {
        //     // console.log(item.status);
        //     item.type = 'chat_boat';
        //     if (item.status == 'booked' || item.status == 'enquiry') {
        //       organic_booked_autoload.push(item);
        //     }
        //     if (item.status == 'newlead') {
        //       organic_newlead_autoload.push(item);
        //       verified_booked_autoload.push(item);
        //       verified_chatbot_count_autoload = verified_chatbot_count_autoload + 1;
        //     }
        //     if (
        //       item.status == 'no_consultation' ||
        //       item.status == 'existing_patient'
        //     ) {
        //       organic_no_consultation_autoload.push(item);
        //       verified_booked_autoload.push(item);
        //       verified_chatbot_count_autoload = verified_chatbot_count_autoload + 1;
        //     }
        //   }
    
        // //   setorganic_booked(organic_booked_autoload);
        // //   setorganic_newlead(organic_newlead_autoload);
    
        // //   setverified_booked(verified_booked_autoload);
        // //   verified_forms_count = verified_forms_count_autoload;
        // //   setorganic_no_consultation(organic_no_consultation_autoload);
        //   verified_organic_calls_count = verified_organic_calls_count_autoload;
        //   verified_chatbot_count = verified_chatbot_count_autoload;
    
        // //   console.log(organic_booked);
        // //   console.log(organic_newlead);
        // //   console.log(organic_no_consultation);
    
        // //   if (document.getElementById('outcome_' + rid)) {
        // //     let div_block = document.getElementById('outcome_' + rid);
        // //     div_block.style.display = 'none';
        // //   }
        // //   if (document.getElementById('outcome_v' + rid)) {
        // //     let div_block = document.getElementById('outcome_v' + rid);
        // //     div_block.style.display = 'none';
        // //   }
    
        //   // end
        // }
      }

    function loadremaining(rev_data) {
        setLeadsData(rev_data)
        openModal();

    }

    return (
        <Card extra={"w-full pb-10 p-4 h-full"}>
               {page.length === 0 ? (
                    <p className="text-center text-gray-500 dark:text-white">No record found</p>
                ) : (
                    <>
            <header className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    <span class="block text-[18px] font-[600]">{labelvalue}
                        <span class="ml-1  h-5 w-5 items-center justify-center rounded bg-gray-500 bg-opacity-70 text-sm font-semibold text-white">
                            <>{tableData?.length}</>
                        </span></span>

                </div>

                {/* <CardMenu /> */}
            </header>

            <div className="mt-8 overflow-auto ">
             
                    <table {...getTableProps()} className="w-full">
                        <thead>
                            {headerGroups.map((headerGroup, index) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            key={index}
                                            className="border-b border-gray-200 pr-14 pb-[10px] dark:!border-navy-700"
                                        >
                                            <div className="flex w-full font-bold justify-between pr-10 text-md tracking-wide text-gray-800">
                                                {column.render("Header")}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} key={index}>
                                        {row.cells.map((cell, index) => {
                                            let data;
                                            if (cell.column.Header === "Type") {
                                                data = (
                                                    <p className="text-sm text-gray-800 font-medium dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            } else if (cell.column.Header === "Name") {
                                                data = (
                                                    <p className="mr-[10px]  text-gray-800 font-medium  dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            } else if (cell.column.Header === "ContactNumber") {
                                                data = (
                                                    <p className="mr-[10px] text-gray-800 font-medium  dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            } else if (cell.column.Header === "Email_Id") {
                                                data = (
                                                    <p className="mr-[10px] text-gray-800 font-medium dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            } else if (cell.column.Header === "Date") {
                                                data = (
                                                    <p className=" mr-[10px]  text-gray-800 font-medium dark:text-white">
                                                        {cell.value}
                                                    </p>
                                                );
                                            } else if (cell.column.Header === "Update") {
                                                data = (
                                                    <div className="w-25 mr-[10px]  rounded-xl border !border-gray-200 px-3 text-sm text-gray-600  outline-none dark:!border-none dark:bg-navy-700 ">
                                                        <select
                                                            onChange={(value)=>change_status(row,value)}
                                                            name="search_by"
                                                            id="search_by"

                                                            className="h-[45px] w-full rounded-xl text-gray-800 font-medium outline-none dark:bg-navy-700 "
                                                        >
                                                            <option value="" >
                                                                Status
                                                            </option>
                                                            <option value="booked" class="">
                                                                Consult Booked
                                                            </option>
                                                            <option value="newlead" class="">
                                                                New Lead
                                                            </option>
                                                            <option value="existing_patient" class="">
                                                                Existing Patient
                                                            </option>


                                                        </select>
                                                    </div>
                                                );
                                            } else if (cell.column.Header === "Status") {
                                                data = (

                                                    <p className={`text-sm  ${cell.value == "booked" ? "text-green-600" : "text-indigo-600"} items-center justify-center  dark:text-white`}>
                                                        {cell.value}
                                                    </p>
                                                );
                                            } else if (cell.column.Header === "Review") {
                                                data = (
                                                    <div class="flex mr-[10px] mr-[10px]  flex-wrap items-center gap-3 text-[14px] 2xl:text-[15px]">
                                                        <div class="w-full">

                                                            {cell.value != '' &&
                                                                cell.value.length > 14 ? (
                                                                <>
                                                                    {/* <span>
                                            {cell.value.substring(0, 30)} ...
                                          </span> */}
                                                                    <a
                                                                        href="#!"
                                                                        title="View More"
                                                                        onClick={() =>
                                                                            loadremaining(cell.value)
                                                                        }
                                                                    >
                                                                        <i class="fa-solid fa-eye text-black-500"></i>


                                                                    </a>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {cell.value != '' ||
                                                                        cell.value == ' ' ||
                                                                        cell.value == '-' ? (
                                                                        <>
                                                                            <span>---</span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span>{cell.value} </span>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>

                                                );
                                            }
                                            return (
                                                <td
                                                    className="pt-[14px] pb-[20px] sm:text-[14px]"
                                                    {...cell.getCellProps()}
                                                    key={index}
                                                >
                                                    {data}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                
                    <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-3">
          <p className="> Show rows per page text-sm text-gray-700">
            Show rows per page{" "}
          </p>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-[32px] w-[75px] rounded-[20px] border border-gray-200 px-2 text-gray-700 dark:!border-white/10 dark:bg-navy-800"
            name=""
            id=""
          >
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
          >
            <MdChevronLeft />
          </button>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
              
                <ModalComponent isOpen={isModalOpen} onClose={closeModal} leadsData={leadsData} />
            </div>
            </>
                  )}
        </Card>

    );
};

export default ColumnsTable;
