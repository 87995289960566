import React, { useEffect } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import avatar1 from 'assets/img/avatars/avatar1.png';
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar3 from 'assets/img/avatars/avatar3.png';
import avatar4 from 'assets/img/avatars/avatar4.png';
import Card from 'components/card';
import Transfer from './Tables/Transfer';
import LineChart from "components/charts/LineChart";
import Stock from "./Stock";
import {
  lineChartDataMiniArea1,
  lineChartOptionsMiniArea1,
  lineChartDataMiniArea2,
  lineChartOptionsMiniArea2,
} from "variables/charts";

function YourTransfers(props) {
  const { campaigndata } = props;
  const { type } = props;
  useEffect(() => {
    console.log(campaigndata?.organic_desktop, 'campp');
  }, []);
  return (
    <Card
      extra={'!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !p-5 h-full'}
    >
      {/* <div class="flex w-full items-center justify-between pb-0">
        <span class=" font-poppins text-[16px] font-[600] text-[#031390]">
          {type === 'seo' ? <>Keywords</> : <>Volume</>}
        </span>
        <span class="font-poppins text-[16px] font-[600] text-[#031390]">
          {type === 'seo' ? <>598</> : <> {campaigndata?.volume}</>}
        </span>
      </div> */}

      <div class="flex items-center justify-between mb-4">
        <p class="text-lg font-bold text-navy-700 dark:text-white">
          {type === 'seo' ? <>Keywords</> : <>Search Volume Trends</>}
        </p>
        <span class="mt-0 font-bold flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-800 transition duration-200 hover:cursor-pointer hover:bg-gray-100 ">
          {type === 'seo' ? <>0</> : <> {campaigndata?.volume}</>}
        </span>
      </div>


      <div className="w-full">  

        {/* left side */}
        <div className="flex items-center gap-0 bg-gray-50 px-3 py-2.5 mb-4">
          <div className="">
            <p className="text-sm font-bold text-gray-800 mr-3"> Rank </p>
          </div>
          <div>
            <p className="text-sm font-bold text-gray-800 "> Name </p>
          </div>
        </div>
        {/* right side */}
      </div>

      {/* <Stock
          name="XPEV"
          sum="350.4"
          growth="+2.45"
          chart={
            <LineChart
              chartData={lineChartDataMiniArea1}
              chartOptions={lineChartOptionsMiniArea1}
            />
          }
        /> */}

      <Transfer
        name="Organic Desktop"
        date={
          campaigndata &&
          campaigndata?.organic_desktop && (
            <>{campaigndata?.organic_desktop.rank}</>
          )
        }
        sum={
          campaigndata &&
          campaigndata?.organic_desktop && (
            <>
              {campaigndata?.organic_desktop > 0 ? (
                <>{campaigndata?.organic_desktop.toString()}</>
              ) : (
                <>{campaigndata?.organic_desktop.change.toString()}</>
              )}
            </>
          )
        }
        avatar={''}
      />
      <Transfer
        name=" Organic Mobile"
        date={
          campaigndata &&
          campaigndata?.organic_mobile && (
            <>{campaigndata?.organic_mobile.rank}</>
          )
        }
        sum={
          campaigndata &&
          campaigndata?.organic_mobile && (
            <>
              {campaigndata?.organic_mobile.change > 0 ? (
                <>{campaigndata?.organic_mobile.change.toString()}</>
              ) : (
                <>{campaigndata?.organic_mobile.change.toString()}</>
              )}
            </>
          )
        }
        avatar={''}
      />
      <Transfer
        name="Local Pack"
        date={
          campaigndata && campaigndata?.local_pack ? (
            campaigndata?.local_pack.rank
          ) : (
            <>-</>
          )
        }
        sum={
          campaigndata &&
          campaigndata?.local_pack && (
            <>
              {campaigndata?.local_pack.change > 0 ? (
                <>{campaigndata?.local_pack.change.toString()}</>
              ) : campaigndata?.local_pack.change < 0 ? (
                <>{campaigndata?.local_pack.change.toString()}</>
              ) : (
                <>-</>
              )}
            </>
          )
        }
        avatar={avatar3}
      />
      <Transfer
        name="Local Finder"
        date={
          campaigndata &&
          campaigndata?.local_finder && (
            <>
              {campaigndata?.local_finder?.map((item) => (
                <>{item.rank}</>
              ))}
            </>
          )
        }
        sum={
          campaigndata &&
          campaigndata?.local_finder && (
            <>
              {campaigndata?.local_finder?.map((item) => (
                <>
                  {item?.change > 0 ? (
                    <>{item?.change.toString()}</>
                  ) : (
                    <>{item?.change.toString()}</>
                  )}
                </>
              ))}
            </>
          )
        }
        avatar={avatar4}
      />
      <div className="mb-auto" />
      {/* <div className="flex w-full items-center justify-end gap-1 hover:cursor-pointer">
        <div className="text-sm font-bold text-brand-500 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
          View all
        </div>
        <div className="text-xl font-bold text-brand-500 transition-all hover:translate-x-1 hover:cursor-pointer dark:text-white">
          <BsArrowRight />
        </div>
      </div> */}
    </Card>
  );
}

export default YourTransfers;
