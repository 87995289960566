import React, { useState } from 'react';
import Modal from "./Modal"
import Review_us from "./Review_us"
const OrderStep = (props) => {
  const { icon, icon2, title, subtitle, bg, border, variant,indexvalue } = props;
  let [toggle1,setToggle1]=useState(false);
  const [text,setText]=useState();
  let [toggle2,setToggle2]=useState(false);
  const [text1,setText1]=useState();
  function loadremaining(row){
    console.log(row)
    setText(row);
    setToggle1(!toggle1);
  }
  function loadremaining1(val){
    console.log(val)
    setText1('send');
    setToggle2(!toggle2);
  }
  return (
    <div className="flex w-full items-center justify-between pt-10">
      <div className="flex items-center gap-3">
        <div
          className={`flex ${
            variant === "last"
              ? " "
              : "from-[#ffffff0f] to-white/0 shadow-xl shadow-gray-200 dark:bg-gradient-to-b dark:shadow-darkinset dark:shadow-white/20"
          } h-16 w-16 items-center justify-center rounded-full text-3xl text-brand-500 dark:text-white ${bg}`}
        >
          {icon}
        </div>
        {indexvalue!=="send"?
        <div>
          <h4 className="text-base font-medium leading-6 text-navy-700 dark:text-white">
            {" "}
            {title}{" "}
          </h4>
          <p className="text-base text-gray-600"> {subtitle} </p>
          </div>
          :  <div>
          <h4 className="text-base font-medium leading-6 text-navy-700 dark:text-white">
            {" "}
            {title}{" "}
          </h4>
          <p className="text-base text-gray-600 mb-2"> {subtitle} </p>
          <button type='button'  onClick={()=>loadremaining1("value")} className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Send Notifications
          </button>
          </div>}
      </div>
      {indexvalue!=="send"?
      <div onClick={()=>loadremaining(indexvalue)}
      //  h-16 w-16 items-center justify-center rounded-full text-3xl text-brand-500 dark:text-white ${bg}
        className={`h-16 w-16 items-center justify-center rounded-full text-3xl text-brand-500 dark:text-white ${bg} cursor-pointer `}
      >
        {icon2}
      </div>:""}
      {toggle2 ? (
              <Review_us
              toggle2={toggle2}
              setToggle2={setToggle2}
              loadremaining1={loadremaining1}
              text1={text1}
              />
            ) : (
              ''
            )}
      {toggle1 ? (
              <Modal
              toggle1={toggle1}
              setToggle1={setToggle1}
              loadremaining={loadremaining}
              text={text}
              />
            ) : (
              ''
            )}
    </div>
  );
};
export default OrderStep;