import React, { useState, useEffect } from 'react';

import nft1 from '../../../assets/img/nfts/Nft1.png';
import nft2 from '../../../assets/img/nfts/Nft3.png';
import { FaArrowUp } from 'react-icons/fa';
import { FaArrowDown } from 'react-icons/fa';
import { GrFormView } from 'react-icons/gr';
import { SiSessionize } from 'react-icons/si';
import { TbUserScan } from 'react-icons/tb';
import { PiUsersFour } from 'react-icons/pi';
import Card from './components/card';

import Stock from '../main/account/billing/components/Stock';
import {
  lineChartDataMiniArea1,
  lineChartOptionsMiniArea1,
  lineChartDataMiniArea2,
  lineChartOptionsMiniArea2,
} from 'variables/charts';
import DonutChart2 from './components/DonutChart2';
import YourTransfers from '../dashboards/default/components/YourTransfers';
import OverallRevenue from "./components/OverallRevenue";
import axios from 'axios';
import Chart from './components/lineCharts';
import { lineChartDataOverallRevenue } from "variables/charts";
import { lineChartOptionsOverallRevenue } from "variables/charts";
import LineChart from './components/card/LineChart';


const SeoInsights = () => {
  let user = JSON.parse(localStorage.getItem('auth'));
  let user_2 = JSON.parse(localStorage.getItem('auth2'));
  const [productid,setproductid]=useState(user_2?.seo_insights)

  const [data1, setData] = useState([]);
  const[seodata,setseodata]=useState([]);
  const [viewsPercentage,setviewspercentage]=useState();
  const [sessionsPercentage,setsessionsPercentage]=useState();
  const [usersPercentage,setusersPercentage]=useState();
  // const viewsPercentage = 33.3;
  // const sessionsPercentage =33.3;
  // const usersPercentage = 33.3;
  const [campaigndata, setCampaignData] = useState();
const [linechartdata,setlinechartdata]=useState();

  const getlinechartdata=async()=>{
    const res=await axios.get(`https://app.legaciestechno.com/postgress/api.php?property_id=${user_2?.seo_insights}`)
 console.log(res)
  setlinechartdata(res?.data?.all_data)
  }

console.log(linechartdata)


 const getseodata=async()=>{
  const res=await axios.get(`https://app.legaciestechno.com/postgress/api.php?property_id=${user_2?.seo_insights}`)
  setseodata(res.data)
  setviewspercentage(res?.data?.total_views)
  setsessionsPercentage(res?.data?.total_sessions)
  setusersPercentage(res?.data?.total_users)
 }

  async function getData() {
    try {
          let campaign_id = 1555419; //Chess House Dental Practice
    const apiURL =
    `https://app.legaciestechno.com/brightlocal/Examples/RankChecker/rankchecker_all.php?campaign_id=${campaign_id}`


    //  console.log(apiURL);
    const response = await fetch(apiURL);
    const res = await response.json();
    //console.log(res);
    setData(res);
    setCampaignData(res)
    positional_movement = data1.positional_movement;
    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    getData();
    getseodata();
    getlinechartdata()
  }, []);
  useEffect(()=>{
   getData()
   getlinechartdata();
  },[user_2?.seo_insights])

  let positional_movement = '';

console.log(campaigndata)
  return (
    <div className="w-full px-5">
      <div className=" w-full">

        <div className="w-full">

        <div class="flex items-center justify-between mb-4">
            <p class="text-lg font-bold text-navy-700 dark:text-white">
            Overall  Performance 
            </p>
            
          </div>

        <div className="grid grid-cols-1 xl:grid-cols-4 gap-5 mb-5">

                  <div class="flex bg-gradient-to-r from-brand-400 to-brand-600 justify-between rounded-[20px] bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500  dark:!bg-navy-800 dark:shadow-none pb-6">
                    <div class="flex flex-col gap-3">
                      <h5 class="font-dm text-lg font-medium text-white ">Views</h5>
                      <p class="font-dm text-2xl font-bold text-white ">{seodata?.total_views}</p>
                    </div>
                    <div class="flex h-20 w-20 items-center justify-center text-white rounded-full  bg-gradient-to-r from-brand-600 to-brand-400 ">
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-4xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path>
                      </svg>
                    </div>
                  </div>

                  <div>
                    <div class="flex bg-white border border-gray-400 justify-between rounded-[20px] bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                      <div>
                        <h5 class="font-dm text-md font-medium text-gray-900 "> Sessions</h5>
                        <p class="font-dm text-2xl font-bold text-navy-700 dark:text-white mt-2">{seodata?.total_sessions}</p>              
                      </div>
                      <div class="flex items-center bg-lightPrimary dark:!bg-navy-700 h-20 w-20 justify-center rounded-full text-brand-500 dark:text-white ">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-4xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path></svg>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex bg-white border border-gray-400 justify-between rounded-[20px] bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                      <div>
                        <h5 class="font-dm text-md font-medium text-gray-900 "> New Users</h5>
                        <p class="font-dm text-2xl font-bold text-navy-700 dark:text-white mt-2">{seodata?.total_newusers}</p>              
                      </div>
                      <div class="flex items-center bg-lightPrimary dark:!bg-navy-700 h-20 w-20 justify-center rounded-full text-brand-500 dark:text-white ">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-4xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path></svg>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex bg-white border border-gray-400 justify-between rounded-[20px] bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                      <div>
                        <h5 class="font-dm text-md font-medium text-gray-900 ">Total Users</h5>
                        <p class="font-dm text-2xl font-bold text-navy-700 dark:text-white mt-2">{seodata?.total_users}</p>              
                      </div>
                      <div class="flex items-center bg-lightPrimary dark:!bg-navy-700 h-20 w-20 justify-center rounded-full text-brand-500 dark:text-white ">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-4xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z"></path></svg>
                      </div>
                    </div>
                  </div> 

            </div>     

        </div>

        <div className="w-full grid grid-cols-1 xl:grid-cols-6 gap-5">           
          
          {/* <div className="flex flex-1 flex-col gap-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <Card
                extra={
                  'p-5 flex flex-col items-center justify-center gap-2'
                }
                backgroundcolor={'bg-[#fff]'}
              >
                <div className="flex h-[56px] w-14 items-center justify-center rounded-full text-[33px] text-brand-500 dark:!bg-navy-700 dark:text-white bg-lightPrimary ">
                  <img src='/img/eye-open.png'/>                  
                </div>
                <div className="w-full text-center">                  
                  <div className="w-full text-[28px] font-[800] font-poppins text-gray-900">58</div>
                  <div className="w-full text-sm font-medium text-gray-700">Views</div>
                </div>
              </Card>
              <Card
                extra={
                  'p-5 flex flex-col items-center justify-center gap-2'
                }
                backgroundcolor={'bg-[#fff]'}
              >
                <div className="flex h-[56px] w-14 items-center justify-center rounded-full text-[33px] text-brand-500 dark:!bg-navy-700 dark:text-white bg-lightPrimary ">
                  <img src='/img/user-1.png'/>              
                </div>
                <div className="w-full text-center">                  
                  <div className="w-full text-[28px] font-[800] font-poppins text-gray-900">285</div>
                  <div className="w-full text-sm font-medium text-gray-700">Sessions</div>
                </div>
              </Card>
              <Card
                extra={
                  'p-5 flex flex-col items-center justify-center gap-2'
                }
                backgroundcolor={'bg-[#fff]'}
              >
                <div className="flex h-[56px] w-14 items-center justify-center rounded-full text-[33px] text-brand-500 dark:!bg-navy-700 dark:text-white bg-lightPrimary ">
                  <img src='/img/user-plus.png'/>       
                </div>
                <div className="w-full text-center">                  
                  <div className="w-full text-[28px] font-[800] font-poppins text-gray-900">29</div>
                  <div className="w-full text-sm font-medium text-gray-700">New Users</div>
                </div>
              </Card>
              <Card
                extra={
                  'p-5 flex flex-col items-center justify-center gap-2'
                }
                backgroundcolor={'bg-[#fff]'}
              >
                <div className="flex h-[56px] w-14 items-center justify-center rounded-full text-[33px] text-brand-500 dark:!bg-navy-700 dark:text-white bg-lightPrimary ">
                  <img src='/img/users-more.png'/>
                </div>
                <div className="w-full text-center">                  
                  <div className="w-full text-[28px] font-[800] font-poppins text-gray-900">115</div>
                  <div className="w-full text-sm font-medium text-gray-700">Active Users</div>
                </div>
              </Card>
               
            </div>
             
          </div> */}

          {/* <div className="">
            <OverallRevenue />            
          </div> */}
          
          <Card extra={'col-span-4 !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !p-5 h-full'} backgroundcolor={'bg-[#fffff]'}>

          <div class="flex items-center justify-between mb-4">
            <p class="text-lg font-bold text-navy-700 dark:text-white">
            Website Performance (last 90days)
            </p>
            {/* <span class="mt-0 flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 text-gray-800 transition duration-200 hover:cursor-pointer hover:bg-gray-100 "></span> */}
          </div>
          <div className="w-full">
            {/* <DonutChart2
                usersPercentage={usersPercentage}
                viewsPercentage={viewsPercentage}
                sessionsPercentage={sessionsPercentage}
              /> */}
              <Chart data={linechartdata}/>
              {/* <LineChart all_data={linechartdata} chartOptions={lineChartOptionsOverallRevenue} /> */}
          </div>

          </Card>
 
          <Card
            extra={'col-span-2 flex-1 p-5 flex flex-col gap-2 '}
            backgroundcolor={'bg-[#fff]'}
          >
            <div className="text-lg font-bold text-navy-700 dark:text-white">
              Domain Authority
            </div>
            <div class="w-full grid grid-cols-1 lg:grid-cols-1 gap-2 mt-4">
                 
                <div class="w-full text-gray-800 text-sm font-normal leading-[13.41px] border border-solid border-gray-300 p-3 rounded">
                  <div class="w-full flex items-center justify-between mb-3">
                    <span class="flex items-center text-black text-sm font-semibold leading-[13.41px]">Google Page Index Count</span> 
                    <span class="font-bold"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" preserveAspectRatio="xMidYMid meet" width="16" height="16" aria-hidden="true" name=""><path d="M17 22L17 14 13 14 13 16 15 16 15 22 12 22 12 24 20 24 20 22 17 22zM16 8a1.5 1.5 0 101.5 1.5A1.5 1.5 0 0016 8z"></path><path d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z"></path></svg></span>
                  </div>
                  <div class="w-full flex items-center text-navy-500 font-bold text-2xl">
                    <div class="w-1/2">10</div> 
                  </div>
                  
                </div>

                <div class="w-full text-gray-800 text-sm font-normal leading-[13.41px] border border-solid border-gray-300 p-3 rounded">
                  <div class="w-full flex items-center justify-between mb-3">
                    <span class="flex items-center text-black text-sm font-semibold leading-[13.41px]">Age</span> 
                    <span class="font-bold"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" preserveAspectRatio="xMidYMid meet" width="16" height="16" aria-hidden="true" name=""><path d="M17 22L17 14 13 14 13 16 15 16 15 22 12 22 12 24 20 24 20 22 17 22zM16 8a1.5 1.5 0 101.5 1.5A1.5 1.5 0 0016 8z"></path><path d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z"></path></svg></span>
                  </div>
                  <div class="w-full flex items-center border-b border-solid border-gray-300 pb-2 mb-1.5">
                    <div class="w-1/2">Age</div>
                    <div class="w-1/2">Reg Year</div>
                  </div>
                  <div class="w-full flex items-center text-navy-500 font-bold">
                    <div class="w-1/2">5</div>
                    <div class="w-1/2 flex items-center ">2018</div>
                  </div>
                </div>

                <div class="w-full text-gray-800 text-sm font-normal leading-[13.41px] border border-solid border-gray-300 p-3 rounded">
                  <div class="w-full flex items-center justify-between mb-3">
                    <span class="flex items-center text-black text-sm font-semibold leading-[13.41px]">Link Count</span> 
                    <span class="font-bold"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" preserveAspectRatio="xMidYMid meet" width="16" height="16" aria-hidden="true" name=""><path d="M17 22L17 14 13 14 13 16 15 16 15 22 12 22 12 24 20 24 20 22 17 22zM16 8a1.5 1.5 0 101.5 1.5A1.5 1.5 0 0016 8z"></path><path d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z"></path></svg></span>
                  </div>
                  <div class="w-full flex items-center border-b border-solid border-gray-300 pb-2 mb-1.5">
                    <div class="w-1/2">External Links</div>
                    <div class="w-1/2">Domain. Linking</div>
                  </div>
                  <div class="w-full flex items-center text-navy-500 font-bold">
                    <div class="w-1/2">185</div>
                    <div class="w-1/2 flex items-center ">53</div>
                  </div>
                </div>

                <div class="w-full text-gray-800 text-sm font-normal leading-[13.41px] border border-solid border-gray-300 p-3 rounded">
                  <div class="w-full flex items-center justify-between mb-3">
                    <span class="flex items-center text-black text-sm font-semibold leading-[13.41px]">Linking Domains Authority</span> 
                    <span class="font-bold"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" preserveAspectRatio="xMidYMid meet" width="16" height="16" aria-hidden="true" name=""><path d="M17 22L17 14 13 14 13 16 15 16 15 22 12 22 12 24 20 24 20 22 17 22zM16 8a1.5 1.5 0 101.5 1.5A1.5 1.5 0 0016 8z"></path><path d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z"></path></svg></span>
                  </div>
                  <div class="w-full flex items-center border-b border-solid border-gray-300 pb-2 mb-1.5">
                    <div class="w-1/2">Domain </div>
                    <div class="w-1/2">URL </div>
                  </div>
                  <div class="w-full flex items-center text-navy-500 font-bold">
                    <div class="w-1/2">12</div>
                    <div class="w-1/2 flex items-center ">27</div>
                  </div>
                </div>

                

                 
              </div>

            {/* <div class="qflex gap-3 hidden ">
              <div class="flex flex-1 flex-col items-center justify-center gap-2 rounded-md bg-gray-50 p-2">
                <div class="rounded-lg p-2 bg-[#ccc] text-[12px] font-[500] font-poppins">
                  Google page index count
                </div>
                <div class="text-[24px] font-[600] text-navy-700">10</div>
              </div>
              <div class="flex flex-1 flex-col items-center justify-center rounded-md  bg-gray-50 p-2">
                <div class=" bg-[#E3F5FF]  rounded-[12px] px-1 py-2  text-[12px] font-[500] font-poppins">
                  Link Count
                </div>
                <p class="text-[10px] text-[#818181]">External links</p>
                <div class="text-[16px] font-[600] text-[#363987]">90</div>
                <p class="text-[10px] text-[#818181]">Domain Links</p>
                <div class="text-[16px] font-[600] text-[#363987]">53</div>
              </div>
              <div class="flex flex-1 flex-col items-center justify-center rounded-md  bg-gray-50 p-2">
                <div class=" bg-[#E8EBFF] font-[500] rounded-[12px] p-1 text-[12px] font-[500] font-poppins">Age</div>
                <p class="text-[10px] text-[#818181]"> Age</p>
                <div class="text-[16px] font-[600] text-[#363987]">5</div>
                <p class="text-[10px] text-[#818181]">Reg Year</p>
                <div class="text-[16px] font-[600] text-[#363987]">2018</div>
              </div>
            </div> */}
            {/* <div class="flex w-full  flex-1 items-center justify-between  gap-2 rounded-md  bg-gray-50 p-2">
              <div class="rounded-[12px] px-1 py-2 bg-[#E3F5FF] text-[12px] font-[500] font-poppins">
                Linking Domains
              </div>
              <div class="flex flex-col items-center gap-2">
                <p class="text-[10px] text-[#818181]"> Domain Authority</p>
                <div class="text-[16px] font-[600] text-[#363987]">12</div>
              </div>
              <div class="flex flex-col items-center gap-2">
                <p class="text-[10px] text-[#818181]">Url Authority</p>
                <div class="text-[16px] font-[600] text-[#363987]">27</div>
              </div>
            </div> */}
          </Card>
        </div>



        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
          <div className="">

            <YourTransfers campaigndata={campaigndata} type={"seo"}/>            

          </div>
          <div className="col-span-2">

          <Card extra={'!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !p-5 h-full'} backgroundcolor={"bg-#FFFFFF"}>
            

          <div class="w-full grid grid-cols-4 gap-4">
                <div class="w-full grid grid-cols-1 gap-3">
                  <div class="w-full bg-white border border-solid border-[#0b9229] rounded-[10px]">
                    <div class="w-full flex items-center justify-center text-2xl text-[#0b9229] font-bold text-center py-2">
                      <span class="">0</span> <i class="fa-solid fa-up-long text-sm ml-2"></i>
                      </div>
                    <div class="w-full flex items-center justify-center bg-[#0b9229] rounded-b-[10px] text-white text-lg font-bold text-center py-2">
                      <span class="">Gained</span>
                      </div>
                  </div>
                  <div class="w-full bg-white border border-solid border-[#c70509] rounded-[10px]">
                    <div class="w-full flex items-center justify-center text-2xl text-[#c70509] font-bold text-center py-2">
                      <span class="">0</span> <i class="fa-solid fa-down-long text-lg ml-2"></i>
                      </div>
                    <div class="w-full flex items-center justify-center bg-[#c70509] rounded-b-[10px] text-white text-lg font-bold text-center py-2">
                      <span class="">Lost</span>
                      </div>
                  </div>
                  <div class="w-full bg-white border border-solid border-[#020435] rounded-[10px]">
                    <div class="w-full flex items-center justify-center text-2xl text-[#020435] font-bold text-center py-2">
                      <span class="">0</span> <i class="fa-solid fa-down-long text-lg ml-2"></i>
                      </div>
                    <div class="w-full flex items-center justify-center bg-[#020435] rounded-b-[10px] text-white text-lg font-bold text-center py-2">
                      <span class="">Change</span>
                    </div>
                  </div>
                </div>
                <div class="w-full col-span-3">
                  <div class="w-full p-3 bg-white rounded-[10px] ">
                    <div class="relative overflow-x-auto">
                      <table class="w-full text-left rtl:text-right">
                        <thead class="font-semibold uppercase1 bg-gray-50 ">
                          <tr>
                            <th scope="col" class="px-2 py-3">Source</th>
                            <th scope="col" class="px-2 py-3">New</th>
                            <th scope="col" class="px-2 py-3">Gained</th>
                            <th scope="col" class="px-2 py-3">Lost</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="bg-white border-b border-solid border-[#e5e5e1]">
                            <th scope="row" class="px-6 py-4 text-gray-900 whitespace-nowrap">
                              <span class="flex items-center text-black text-sm font-semibold leading-[13.41px]">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="w-4 mr-1" viewBox="0 0 48 48">
                                  <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path></svg>
                                                          Organic Desktop</span></th>
                            <td class="px-6 py-4">-</td>
                            <td class="px-6 py-4">-</td>
                            <td class="px-6 py-4">-</td>
                          </tr>
                          <tr class="bg-white border-b border-solid border-[#e5e5e1]">
                            <th scope="row" class="px-6 py-4 text-gray-900 whitespace-nowrap"><span class="flex items-center text-black text-sm font-semibold leading-[13.41px]">
                              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="w-4 mr-1" viewBox="0 0 48 48">
                                <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path></svg>
                                                          Organic Mobile</span></th>
                            <td class="px-6 py-4">-</td>
                            <td class="px-6 py-4">-</td>
                            <td class="px-6 py-4">-</td>
                          </tr>
                          <tr class="bg-white border-b border-solid border-[#e5e5e1]">
                            <th scope="row" class="px-6 py-4 text-gray-900 whitespace-nowrap">
                              <span class="flex items-center text-black text-sm font-semibold leading-[13.41px]">
                              <img src='https://tools.brightlocal.com/assets/core/images/search-engines/google-places.svg' className='w-3 mr-1' />  Local Finder
                              </span>
                            </th>
                            <td class="px-6 py-4">-</td>
                            <td class="px-6 py-4">-</td>
                            <td class="px-6 py-4">-</td>
                          </tr>
                          <tr class="bg-white border-b border-solid border-[#e5e5e1]">
                            <th scope="row" class="px-6 py-4 text-gray-900 whitespace-nowrap">
                              <span class="flex items-center text-black text-sm font-semibold leading-[13.41px]">
                                <img src='https://tools.brightlocal.com/assets/core/images/search-engines/bing.svg' className='w-5 mr-1' />  Bing
                              </span>
                            </th>
                            <td class="px-6 py-4">-</td>
                            <td class="px-6 py-4">-</td>
                            <td class="px-6 py-4">-</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>



            <div class="hidden dflex flex-row gap-5">
              <div class="w-full flex lg:w-[20%] flex-col gap-2">
                <div class="flex flex-col items-center justify-between rounded bg-[#E9FFEA] p-3">
                  <p class="rounded-xl bg-[#0B9229] p-2.5 py-1.5 text-[white]">Gained</p>
                  <div class="flex gap-2">
                    <p class="text-[24px] font-[600]">0</p>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-between rounded bg-[#FFDBD8] p-2">
                  <p class="rounded-lg bg-[#C70509] px-5 py-1 text-[12px] text-[white]">
                    Lost
                  </p>
                  <div class="flex gap-2">
                    <p class="text-[24px] font-[600]">0</p>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-between rounded bg-[#E1E2FF] p-2">
                  <p class="rounded-lg bg-[#020435] px-5 py-1 text-[12px] text-[white]">
                    Changed
                  </p>
                  <div class="flex gap-2">
                    <p class="text-[24px] font-[600]">0</p>
                  </div>
                </div>
              </div>

              <div class="w-full lg:w-[80%]">

              <table className="w-full text-left rtl:text-right">
                    <thead className="uppercase1 font-semibold ">
                      <tr>
                        <th scope="col" className="px-2 py-3">
                          Source
                        </th>
                        <th scope="col" className="px-2 py-3">
                          New
                        </th>
                        <th scope="col" className="px-2 py-3">
                          Gained
                        </th>
                        <th scope="col" className="px-2 py-3">
                          Lost
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b border-solid border-[#e5e5e1] bg-white">
                        <th
                          scope="row"
                          className="whitespace-nowrap px-6 py-4 text-[#818181] font-[500] text-[14px]"
                        >
                          <span className="text-black flex items-center text-lg font-semibold leading-[13.41px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              className="mr-1 w-6"
                              viewBox="0 0 48 48"
                            >
                              <path
                                fill="#fbc02d"
                                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                              ></path>
                              <path
                                fill="#e53935"
                                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                              ></path>
                              <path
                                fill="#4caf50"
                                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                              ></path>
                              <path
                                fill="#1565c0"
                                d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                              ></path>
                            </svg>
                            Organic Desktop
                          </span>
                        </th>
                        <td className="px-6 py-4">
                          {positional_movement['google'] > 0 ? '1' : '-'}
                        </td>
                        <td className="px-6 py-4">
                          {positional_movement['google'] > 0 ? '1' : '-'}
                        </td>
                        <td className="px-6 py-4">
                          {positional_movement['google'] > 0 ? '1' : '-'}
                        </td>
                      </tr>
                      <tr className="border-b border-solid border-[#e5e5e1] bg-white">
                        <th
                          scope="row"
                          className="whitespace-nowrap px-6 py-4 text-[#818181] font-[500]  text-sm"
                        >
                          <span className="text-black flex items-center text-lg font-semibold leading-[13.41px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              className="mr-1 w-6"
                              viewBox="0 0 48 48"
                            >
                              <path
                                fill="#fbc02d"
                                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                              ></path>
                              <path
                                fill="#e53935"
                                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                              ></path>
                              <path
                                fill="#4caf50"
                                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                              ></path>
                              <path
                                fill="#1565c0"
                                d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                              ></path>
                            </svg>
                            Organic Mobile
                          </span>
                        </th>
                        <td className="px-6 py-4">
                          {positional_movement['google-mobile'] > 0 ? '1' : '-'}
                        </td>
                        <td className="px-6 py-4">
                          {positional_movement['google-mobile'] > 0 ? '1' : '-'}
                        </td>
                        <td className="px-6 py-4">
                          {positional_movement['google-mobile'] > 0 ? '1' : '-'}
                        </td>
                      </tr>

                      <tr className="border-b border-solid border-[#e5e5e1] bg-white">
                        <th
                          scope="row"
                          className="whitespace-nowrap px-6 py-4 1] text-[#818181] font-[500]  text-sm"
                        >
                          <span className="text-black flex items-center text-lg font-semibold leading-[13.41px]">
                            <img
                              src="https://tools.brightlocal.com/assets/core/images/search-engines/google-places.svg"
                              alt=""
                              className="mr-2 w-4"
                            />
                            Local Finder
                          </span>
                        </th>
                        <td className="px-6 py-4">
                          {positional_movement['google-places'] > 0 ? '1' : '-'}
                        </td>
                        <td className="px-6 py-4">
                          {positional_movement['google-places'] > 0 ? '1' : '-'}
                        </td>
                        <td className="px-6 py-4">
                          {positional_movement['google-places'] > 0 ? '1' : '-'}
                        </td>
                      </tr>
                      <tr className="border-b border-solid border-[#e5e5e1] bg-white">
                        <th
                          scope="row"
                          className="whitespace-nowrap px-6 py-4 text-[#818181] font-[500]  text-sm"
                        >
                          <span className="text-black flex items-center text-lg font-semibold leading-[13.41px]">
                            <img
                              src="https://tools.brightlocal.com/assets/core/images/search-engines/bing.svg"
                              alt=""
                              className="mr-2 w-4"
                            />
                            Bing
                          </span>
                        </th>
                        <td className="px-6 py-4">
                          {positional_movement['bing'] > 0 ? '1' : '-'}
                        </td>
                        <td className="px-6 py-4">
                          {positional_movement['bing'] > 0 ? '1' : '-'}
                        </td>
                        <td className="px-6 py-4">
                          {positional_movement['bing'] > 0 ? '1' : '-'}
                        </td>
                      </tr>
                    </tbody>
                  </table>





              </div>
            </div>
          </Card>

          </div>
        </div> 
         
      </div>
    </div>
  );
};
export default SeoInsights;