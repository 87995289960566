import Card from "components/card";
import InputField from "components/fields/InputField";
import Centered from "layouts/auth/types/Centered";
import React,{ useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

  const ForgotPasswordCenter = () => {
    const navigate=useNavigate()
    let user = JSON.parse(localStorage.getItem('auth'));
    const[user_email,setuser_email]=useState(user?.email);
    const [email, setEmail] = useState();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };

      const handleForgetPassword = async(e) => {
        e.preventDefault();
     
        try {
          let url=`https://dcp-backend.onrender.com/wrapper/forgotpassword`;
          // let url=`https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=forget_password`;
          const res = await axios.post(url, {
            email:email,
          });
         
          console.log(res);
          if (res && res.data.status === "success") {
              alert(res.data.message)
              navigate("/login")
          } else {
            console.log(res.data.message)
          }
        } catch (error) {
          console.log(error);
        
        }
      };

  return (
    <Centered
      maincard={
        <Card
          extra={
            "max-w-[405px] md:max-w-[550px] h-max mt-32 md:mt-[120px] mx-2.5 md:mx-auto mb-auto pb-2.5 pt-8 md:pt-2.5 px-4 md:!p-[50px]"
          }
        >
          <p className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
            Forgot Your Password?
          </p>
          <p className="mt-[10px] ml-1 mb-8 text-base text-gray-600">
            No problem. Just let us know your email address and we'll email you
            a password reset link that will allow you to choose a new one.
          </p>
          {/* Email */}
          <div className={'mb-3'}>
            <label className={`text-sm text-navy-700 dark:text-white`}>
              Email
            </label>
            <input
              type="email"
              placeholder={'johndoe@gmail.com'}
              value={email}
              onChange={handleEmailChange}
              className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none 
         `}
            />
          </div>

          {/* button */}
          <button  onClick={handleForgetPassword} className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Submit
          </button>
        </Card>
      }
    />
  );
}

export default ForgotPasswordCenter;
