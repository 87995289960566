import Card from "components/card";
import OrderStep from "components/dataDisplay/OrderStep";
import {
  Md,
  MdShoppingBasket,
  MdArchive,
  Md1 ,
 
  MdLocalShipping,
  MdCheckCircle,
} from "react-icons/md";
import { MdCheck,MdForm, MdLink,MdTimer,MdPending,MdInfo } from "react-icons/md";

const OrderStatus = () => {
  return (
    <Card extra={"w-full h-full p-4"}>
      {/* Order Status */}
      <h4 className="mt-1 text-lg font-semibold text-navy-700 dark:text-white">
      Get Started
      </h4>

      {/* ordersteps */}

      <div className="relative h-full w-full">
        <div
          className={`absolute left-[32px] mt-20 text-brand-500 dark:text-white`}
        >
          <svg
            width="2"
            height="130"
            viewBox="0 0 2 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="63"
              x2="0.999997"
              y2="1"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-dasharray="6 6"
            />
          </svg>
        </div>

        {/* steps */}
        <div className="font-dm font-medium">
          <div>
            <OrderStep
              title="Choose your templates"
              subtitle="Select the template you want to use for this campains"
              bg="bg-white bg-navy-800"
              icon2={<MdInfo  />}
              indexvalue="form"
              icon={<MdLink />}
              border="border-2 border-navy-500"
            />
          </div>
          <div className="mt-9">
            <OrderStep
              title="Select review sites"
              subtitle="Select the review sites"
              bg="bg-white bg-navy-800"
              icon2={<MdInfo />}
              indexvalue="url"
              icon={<MdLink />}
              border=" bg-navy-500"
            />
          </div> 
          <div className={`absolute left-[32px] text-brand-500`}>
            <svg
              width="2"
              height="80"
              viewBox="0 0 2 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="63"
                x2="0.999997"
                y2="1"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="6 6"
              />
            </svg>
          </div>
          <div className="mt-10">
            <OrderStep
              title="Choose when you are notified of feedback by email"
              subtitle="if 3 or below stars are clicked, which email address we should notify?
              this is for internal training and development purpose"
              bg="bg-white bg-navy-800"
              icon2={<MdInfo  />}
              indexvalue="send"
              icon={<MdLink />}
              border="border-2 border-navy-500"
            />
          </div>
          {/* <div
            className={`absolute left-[32px] text-brand-500 dark:text-white`}
          >
            <svg
              width="2"
              height="80"
              viewBox="0 0 2 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="63"
                x2="0.999997"
                y2="1"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="6 6"
              />
            </svg>
          </div> */}
          {/* <div className="mt-10">
            <OrderStep
              title="Shipped"
              subtitle="27 Jul 2022"
              bg="bg-white dark:!bg-navy-800"
              icon2={<MdCheck />}
              icon={<MdLocalShipping />}
              border="border-2 border-green-500"
            />
          </div> */}
          {/* <div className={`absolute left-[32px] text-brand-500`}>
            <svg
              width="2"
              height="80"
              viewBox="0 0 2 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="63"
                x2="0.999997"
                y2="1"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="6 6"
              />
            </svg>
          </div> */}
          {/* <div className="mt-10">

            <button className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Send
          </button>
          </div> */}
        </div>
      </div>
    </Card>
  );
};

export default OrderStatus;
