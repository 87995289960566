// import React from 'react';
// import Dropdown from 'components/dropdown';
// import { FiAlignJustify } from 'react-icons/fi';
// import { Link } from 'react-router-dom';
// import navbarimage from 'assets/img/layout/Navbar.png';
// import { BsArrowBarUp } from 'react-icons/bs';
// import { FiSearch } from 'react-icons/fi';
// import Configurator from './Configurator';
// // import { RiMoonFill, RiSunFill } from 'react-icons/ri';
// // import Configurator from './Configurator';
// import {
//   IoMdNotificationsOutline,
//   IoMdInformationCircleOutline,
// } from 'react-icons/io';
// import avatar from 'assets/img/avatars/avatar4.png';

// const Navbar = (props) => {
//   const {
//     onOpenSidenav,
//     brandText,
//     mini,
//     // setMini,
//     // theme,
//     // setTheme,
//     hovered,
//   } = props;
//   const [darkmode, setDarkmode] = React.useState(
//     document.body.classList.contains('dark')
//   );
//   return (
//     <nav
//       className={`duration-175 linear fixed right-3 top-3 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 transition-all ${
//         mini === false
//           ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
//           : mini === true && hovered === true
//           ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
//           : 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]'
//       }  p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:right-[30px] md:top-4 xl:top-[20px]`}
//     >
//       <div className="ml-[6px]">
//         <div className="h-6  pt-1">
//           {/* <a
//             className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
//             href=" "
//           >
//             Pages
//             <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
//               {" "}
//               /{" "}
//             </span>
//           </a> */}
//           {/* <Link
//             className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
//             to="#"
//           >
//             {brandText}
//           </Link> */}

//             {/* <div class="text-black text-xl font-normal text-navy-700 w-full">
//               Good day, Chess House Dental Practice
//             </div> */}

//         </div>
//         <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
//           <div

//             className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
//           >
//           Good day, Chess House Dental Practice
//           </div>
//         </p>
//       </div>

//       <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
//         <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
//           <p className="pl-3 pr-2 text-xl">
//             <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
//           </p>
//           <input
//             type="text"
//             placeholder="Search..."
//             className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
//           />
//         </div>
//         <span
//           className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
//           onClick={onOpenSidenav}
//         >
//           <FiAlignJustify className="h-5 w-5" />
//         </span>
//         {/* start Notification */}
//         <Dropdown
//           button={
//             <p className="cursor-pointer">
//               <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white" />
//             </p>
//           }
//           animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
//           children={
//             <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
//               <div className="flex items-center justify-between">
//                 <p className="text-base font-bold text-navy-700 dark:text-white">
//                   Notification
//                 </p>
//                 <p className="text-sm font-bold text-navy-700 dark:text-white">
//                   Mark all read
//                 </p>
//               </div>

//               <button className="flex w-full items-center">
//                 <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
//                   <BsArrowBarUp />
//                 </div>
//                 <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
//                   <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
//                     New Update: Horizon UI Dashboard PRO
//                   </p>
//                   <p className="font-base text-left text-xs text-gray-900 dark:text-white">
//                     A new update for your downloaded item is available!
//                   </p>
//                 </div>
//               </button>

//               <button className="flex w-full items-center">
//                 <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
//                   <BsArrowBarUp />
//                 </div>
//                 <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
//                   <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
//                     New Update: Horizon UI Dashboard PRO
//                   </p>
//                   <p className="font-base text-left text-xs text-gray-900 dark:text-white">
//                     A new update for your downloaded item is available!
//                   </p>
//                 </div>
//               </button>
//             </div>
//           }
//           classNames={'py-2 top-4 -left-[230px] md:-left-[440px] w-max'}
//         />
//         {/* start Horizon PRO */}
//         <Dropdown
//           button={
//             <p className="cursor-pointer">
//               <IoMdInformationCircleOutline className="h-4 w-4 text-gray-600 dark:text-white" />
//             </p>
//           }
//           children={
//             <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
//               <div
//                 style={{
//                   backgroundImage: `url(${navbarimage})`,
//                   backgroundRepeat: 'no-repeat',
//                   backgroundSize: 'cover',
//                 }}
//                 className="mb-2 aspect-video w-full rounded-lg"
//               />
//               <a
//                 target="blank"
//                 href="https://horizon-ui.com/pro?ref=live-pro-tailwind-react"
//                 className="px-full linear flex cursor-pointer items-center justify-center rounded-xl bg-brand-500 py-[11px] font-bold text-white transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
//               >
//                 Buy Horizon UI PRO
//               </a>
//               <a
//                 target="blank"
//                 href="https://horizon-ui.com/docs-tailwind/docs/react/installation?ref=live-pro-tailwind-react"
//                 className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
//               >
//                 See Documentation
//               </a>
//               <a
//                 target="blank"
//                 href="https://horizon-ui.com/?ref=live-pro-tailwind-react"
//                 className="hover:bg-black px-full linear flex cursor-pointer items-center justify-center rounded-xl py-[11px] font-bold text-navy-700 transition duration-200 hover:text-navy-700 dark:text-white dark:hover:text-white"
//               >
//                 Try Horizon Free
//               </a>
//             </div>
//           }
//           classNames={'py-2 top-6 -left-[250px] md:-left-[330px] w-max'}
//           animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
//         />
//         {/* <div
//           className="cursor-pointer text-gray-600"
//           onClick={() => {
//             if (darkmode) {
//               document.body.classList.remove('dark');
//               setDarkmode(false);
//             } else {
//               document.body.classList.add('dark');
//               setDarkmode(true);
//             }
//           }}
//         >
//           {darkmode ? (
//             <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
//           ) : (
//             <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
//           )}
//         </div> */}
//         <Configurator
//           mini={props.mini}
//           setMini={props.setMini}
//           theme={props.theme}
//           setTheme={props.setTheme}
//           darkmode={darkmode}
//           setDarkmode={setDarkmode}
//         />
//         <div
//           className="cursor-pointer text-gray-600"
//           onClick={() => {
//             if (darkmode) {
//               document.body.classList.remove('dark');
//               setDarkmode(false);
//             } else {
//               document.body.classList.add('dark');
//               setDarkmode(true);
//             }
//           }}
//         ></div>
//         {/* Profile & Dropdown */}
//         <Dropdown
//           button={
//             <img
//               className="h-10 w-10 rounded-full"
//               src={avatar}
//               alt="Elon Musk"
//             />
//           }
//           children={
//             <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
//               <div className="ml-4 mt-3">
//                 <div className="flex items-center gap-2">
//                   <p className="text-sm font-bold text-navy-700 dark:text-white">
//                     👋 Hey, Adela
//                   </p>{' '}
//                 </div>
//               </div>
//               <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

//               <div className="ml-4 mt-3 flex flex-col">
//                 <a
//                   href=" "
//                   className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
//                 >
//                   Profile Settings
//                 </a>
//                 <a
//                   href=" "
//                   className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
//                 >
//                   Newsletter Settings
//                 </a>
//                 <a
//                   href=" "
//                   className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
//                 >
//                   Log Out
//                 </a>
//               </div>
//             </div>
//           }
//           classNames={'py-2 top-8 -left-[180px] w-max'}
//         />
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useEffect, useState } from 'react';
import Dropdown from 'components/dropdown';
import { FiAlignJustify } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import navbarimage from 'assets/img/layout/Navbar.png';
import { BsArrowBarUp } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import Configurator from './Configurator';
import { IoIosNotifications, IoIosLogOut } from 'react-icons/io';

// import { RiMoonFill, RiSunFill } from 'react-icons/ri';
// import Configurator from './Configurator';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from 'react-icons/io';
import avatar from 'assets/img/avatars/avatar4.png';
import { Pointer } from 'highcharts';

const Navbar = (props) => {
  const {
    onOpenSidenav,
    brandText,
    mini,
    // setMini,
    // theme,
    // setTheme,
    hovered,
  } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains('dark')
  );
  const navigate = useNavigate();
  let user = JSON.parse(localStorage.getItem('auth'));
  let Toplocation=JSON.parse(localStorage.getItem('location'))

  const [top_name, setTop_name] = useState(user?.top_name);
  const [locations, setlocations] = useState(Toplocation?.locations)

  console.log(Toplocation)

  const changelocationfunction = async (e) => {
    setTop_name(e.target.value);
    let loc = document.getElementById('location').value;
    const apiURL_c_u =
      'https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=change_user&loc=' +
      loc;

    try {
      const response_c_u = await fetch(apiURL_c_u);
      if (!response_c_u.ok) {
        // Handle error response
        console.error('Error fetching data:', response_c_u.status);
        return;
      }

      let newdata = await response_c_u.json();
      // console.log(newdata.user);
      const authData = JSON.stringify(newdata.user);
      const authData2=JSON.stringify(newdata.data)
      localStorage.setItem('auth', authData);
      localStorage.setItem('auth2', authData2);
      // setlocations(authData?.locations)
      setTop_name(authData?.top_name);
      // console.log(authData)
     
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('location');
    localStorage.removeItem('auth');
    localStorage.removeItem('auth2');
    navigate('/login');
  };

  return (
    <nav
      className={`mt-1 flex w-full flex-row flex-wrap items-center justify-between p-2 backdrop-blur-xl transition-all dark:bg-[#0b14374d] md:right-[30px] md:top-4 xl:top-[20px]`}
    >
      <div className="ml-[20px]">
        {/* <div className="h-6  pt-0">
            <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a> 
            <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link> 

         <div class="text-black text-xl font-normal text-navy-700 w-full">
              Good day, Chess House Dental Practice
            </div> 
        </div> */}
        <p className="shrink text-[30px] capitalize text-navy-700 dark:text-white">
          <div className="font-bold capitalize hover:text-navy-700 dark:hover:text-white">
            Good Day, {user?.top_name}
          </div>
        </p>
      </div>

      {/* <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
       
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white" />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Mark all read
                </p>
              </div>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brand-400 to-brand-500 py-4 text-2xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    New Update: Horizon UI Dashboard PRO
                  </p>
                  <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                    A new update for your downloaded item is available!
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        />
        
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdInformationCircleOutline className="h-4 w-4 text-gray-600 dark:text-white" />
            </p>
          }
          children={
            <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div
                style={{
                  backgroundImage: `url(${navbarimage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="mb-2 aspect-video w-full rounded-lg"
              />
              <a
                target="blank"
                href="https://horizon-ui.com/pro?ref=live-pro-tailwind-react"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl bg-brand-500 py-[11px] font-bold text-white transition duration-200 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Buy Horizon UI PRO
              </a>
              <a
                target="blank"
                href="https://horizon-ui.com/docs-tailwind/docs/react/installation?ref=live-pro-tailwind-react"
                className="px-full linear flex cursor-pointer items-center justify-center rounded-xl border py-[11px] font-bold text-navy-700 transition duration-200 hover:bg-gray-200 hover:text-navy-700 dark:!border-white/10 dark:text-white dark:hover:bg-white/20 dark:hover:text-white dark:active:bg-white/10"
              >
                See Documentation
              </a>
              <a
                target="blank"
                href="https://horizon-ui.com/?ref=live-pro-tailwind-react"
                className="hover:bg-black px-full linear flex cursor-pointer items-center justify-center rounded-xl py-[11px] font-bold text-navy-700 transition duration-200 hover:text-navy-700 dark:text-white dark:hover:text-white"
              >
                Try Horizon Free
              </a>
            </div>
          }
          classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
          animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        />
   
        <Configurator
          mini={props.mini}
          setMini={props.setMini}
          theme={props.theme}
          setTheme={props.setTheme}
          darkmode={darkmode}
          setDarkmode={setDarkmode}
        />
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        ></div>
   
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, Adela
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <a
                  href=" "
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                <a
                  href=" "
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </a>
                <a
                  href=" "
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div> */}

      {/* <div className="relative mt-[3px] flex h-[61px] w-[300px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 1shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2"> */}

      <div className="relative mt-[3px] flex h-[61px] w-[300px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500  md:flex-grow-0 md:gap-1">

        <div class="w-full rounded-full border border-gray-400 p-3 bg-lightPrimary text-sm font-normal text-gray-800 outline-none">
          <select
            class="text-lightFourth w-full rounded-xl bg-lightPrimary pr-4 text-sm outline-none"
            name="location"
            id="location"
            onChange={(e) => changelocationfunction(e)}
            value={top_name}>
           {locations?.map((location, index) => (
          <option key={index} value={location}>
            {location}
          </option>
        ))}


          </select>
        </div>

        {/* <div className="w-full text-sm font-medium outline-none rounded-full dark:!border-none dark:bg-navy-700 md:dw-fit">
          <select
            className="h-[45px] w-full items-center rounded-full bg-lightPrimary border-none px-5 text-navy-700 dark:bg-navy-900 dark:text-white"
            name="location"
            id="location"
            onChange={(e) => changelocationfunction(e)}
            value={top_name}
          >
            <option value="Chess House Dental Practice" className='px-5 py-3'>
              Chess House Dental
            </option>
            <option value="Harrow Weald Dental Practice" className='px-5 py-3'>
              Harrow Weald Dental
            </option>
          </select>
        </div> */}
        <div className="flex items-center gap-2 ">
          <IoIosLogOut onClick={handleLogout} cursor={"Pointer"} size={24} />
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
            onClick={onOpenSidenav}
          >
            {/* <FiAlignJustify className="h-5 w-5" /> */}
          </span>

          {/* <Dropdown
            button={
              <img
                className="h-10 w-10 rounded-full"
                src={avatar}
                alt="Elon Musk"
              />
            }
            children={
              <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="ml-4 mt-3">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      👋 Hey, {user?.display_username}
                    </p>
                  </div>
                </div>
                <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="ml-4 mt-3 flex flex-col">
               
                  <a
                    href=" "
                    className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                  >
                    Log Out
                  </a>
                </div>
              </div>
            }
            classNames={'py-2 top-8 -left-[180px] w-max'}
          /> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
