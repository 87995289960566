import React, { useState, useEffect } from 'react';
import nft1 from 'assets/img/nfts/NftBanner1.png';
import Nft2 from 'assets/img/nfts/Nft3.png';
import Nft1 from 'assets/img/nfts/Nft1.png';
import CircularProgressMini from 'components/charts/CircularProgressMini';
import CircularProgress from 'components/charts/CircularProgressInfo';
import Modal from './Modal';
import axios from 'axios';
import Card from "components/card";

const Business = () => {
  const [data, setData] = useState([]);
  let user = JSON.parse(localStorage.getItem('auth'));
  let location_id = user?.location_id;

  const getdata = async () => {
    const res = await axios.get(
      `https://app.legaciestechno.com/brightlocal/Examples/Locations/all_locations.php?location_id=${location_id}`
    );
    setData(res?.data);
  };
  useEffect(() => {
    getdata();
  }, []);
  const data1 = [];
  const [popup, setPopup] = useState(false);
  const loadpopup = () => {
    setPopup(!popup);
  };
  return (

    <div className="w-full px-5">

        {/* Claimed start */}
        <div className="lg:flex w-full flex-row rounded-[20px] bg-[#E8EBFF] px-10 py-5">
          <div class="flex">
            <i class="fa-solid fa-check mt-1 h-[30px] w-[30px] rounded-full bg-[#18C385] p-[7px] text-[18px] text-white"></i>
            <strong class="text-black mt-2 pl-2 text-lg font-bold ">
              Claimed
            </strong>
          </div>
          <span class="text-black text-md lg:ml-5 mt-1 mt-2 block bg-white rounded-md border border-solid border-[#d0efdb] p-2 lg:mt-0 lg:inline">
            Congratulations! Your business is claimed and verified on Google.
            {/* It's time to optimize it for ranking higher in search engines. */}
          </span>
        </div>
        {/* Claimed end */}

        <div className="w-full mt-5">

            <div class="justify-between1 flex w-full items-center">
                <h2 class="text-lg font-bold text-navy-700 dark:text-white">Google Business Profile</h2>
                <span class="ml-2">
                  <a href="#!" onClick={loadpopup} class="">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </a>
                </span>
            </div>


            <div className="w-full grid grid-cols-2 lg:grid-cols-2 gap-5 mt-5">

                  {/* Business Details start */}
                  <div className="">
                        <div className="!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !p-5 h-full">
                            
                            <div className="w-full mb-5">
                              <h4 className="flex items-center text-lg font-bold text-navy-700 dark:text-white">
                                <div className="flex items-center bg-lightPrimary dark:!bg-navy-700 h-10 w-10 mr-2 justify-center rounded-full text-brand-500 dark:text-white ">
                                  <i class="fa-solid fa-location-dot"></i>
                                </div>
                                {' '} Business Details
                              </h4>
                              <p className="mt-1 text-base text-gray-800">
                                 Google Business Profile is one of the most important optimization tools that can help your business to appear and rank higher on Google Search and Google Maps. </p>
                            </div>

                            <div className="mt-1 grid grid-cols-1 gap-3">

                              <div className='w-full bg-gray-50 p-2 rounded-lg'> 
                                <span class="text-md block font-bold text-navy-700">Name</span> 
                                <span class="text-sm font-medium text-gray-800">{data["location-name"]}</span> 
                              </div>
                              <div className='w-full bg-gray-50 p-2 rounded-lg'> 
                                <span class="text-md block font-bold text-navy-700">Address</span> 
                                <span class="text-sm font-medium text-gray-800">{data?.address1}</span> 
                              </div>
                              <div className='w-full bg-gray-50 p-2 rounded-lg'> 
                                <span class="text-md block font-bold text-navy-700">City</span> 
                                <span class="text-sm font-medium text-gray-800">{data?.town}</span> 
                              </div>
                              <div className='w-full bg-gray-50 p-2 rounded-lg'> 
                                <span class="text-md block font-bold text-navy-700">State</span> 
                                <span class="text-sm font-medium text-gray-800">{data?.region}</span> 
                              </div>
                              <div className='w-full bg-gray-50 p-2 rounded-lg'> 
                                <span class="text-md block font-bold text-navy-700">Country, Postcode</span> 
                                <span class="text-sm font-medium text-gray-800">{data?.country},{data?.postcode}</span> 
                              </div>
                              <div className='w-full bg-gray-50 p-2 rounded-lg'> 
                                <span class="text-md block font-bold text-navy-700">Phone</span> 
                                <span class="text-sm font-medium text-gray-800">{data?.telephone}</span> 
                              </div>
                              <div className='w-full bg-gray-50 p-2 rounded-lg'> 
                                <span class="text-md block font-bold text-navy-700">Email</span> 
                                <span class="text-sm font-medium text-gray-800">{data["contact-email"]}</span> 
                              </div>
                              <div className='w-full bg-gray-50 p-2 rounded-lg'> 
                                <span class="text-md block font-bold text-navy-700">Website</span> 
                                <span class="text-sm font-medium text-gray-800">{data["location-url"]}</span> 
                              </div> 
                               
                            </div>
                          </div>
                  </div>
                  {/* Business Details end */}
                  {/* Website Speed and Page Performance start */}
                  <div className="">

                    <div className="!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  !p-5 h-full">
                              
                              <div className="w-full mb-5">
                                <h4 className="flex items-center text-lg font-bold text-navy-700 dark:text-white">
                                  <div className="flex items-center bg-lightPrimary dark:!bg-navy-700 h-10 w-10 mr-2 justify-center rounded-full text-brand-500 dark:text-white ">
                                    <i class="fa-solid fa-location-dot"></i>
                                  </div>
                                  Website Speed and Page Performance
                                </h4>
                                {/* <p className="lg:ml-12 text-base font-bold text-gray-800"> Server Response Time</p> */}
                              </div>

                              {/* <div class="flex items-center justify-between space-x-2 bg-gray-50 p-2 rounded-lg mb-6"> */}
                                {/* <p class="mt-1 text-base text-gray-800"> */}
                                  {/* The response time of the server was - sec Response time
                                  Good Should be 2 sec MAX */}
                                {/* </p> */}
                                {/* <i class="fa-solid fa-check rounded-full bg-green-500 p-[5px] text-[12px] text-white"></i> */}
                              {/* </div> */}



                            <div className="w-full mb-8 pb-2 border-b border-gray-200">
                                <h4 className="flex items-center text-lg font-bold text-navy-700 dark:text-white">
                                  <div className="flex items-center bg-lightPrimary dark:!bg-navy-700 h-10 w-10 mr-2 justify-center rounded-full text-brand-500 dark:text-white ">
                                        <i class="fa-solid fa-mobile-screen-button"></i>
                                  </div>
                                  Mobile
                                </h4> 
                            </div>

                            <div className="grid w-full grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 gap-5 2xl:gap-5 3xl:gap-8">
                              <CircularProgress
                                title="Perfomance"
                                percentage={34}
                                changetext="businessinfo"
                              />
                              <CircularProgress
                                title="Accesibility"
                                percentage={82}
                                changetext="businessinfo"
                              />
                              <CircularProgress
                                title="Best Pratices"
                                percentage={96}
                                changetext="businessinfo"
                              />
                              <CircularProgress title="Seo" percentage={92} 
                              changetext="businessinfo"/>
                            </div>


                            <div className="w-full mt-8 mb-5 pb-2 border-b border-gray-200">
                                <h4 className="flex items-center text-lg font-bold text-navy-700 dark:text-white">
                                  <div className="flex items-center bg-lightPrimary dark:!bg-navy-700 h-10 w-10 mr-2 justify-center rounded-full text-brand-500 dark:text-white ">
                                      <i class="fa-solid fa-display"></i>
                                  </div>
                                  Desktop
                                </h4> 
                            </div>

                            <div className="grid w-full grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 gap-5 2xl:gap-5 3xl:gap-8">
                              <CircularProgress
                                title="Perfomance"
                                percentage={88}
                              />

                              <CircularProgress
                                title="Accesibility"
                                percentage={82}
                              />

                              <CircularProgress
                                title="Best Pratices"
                                percentage={96}
                              />

                              <CircularProgress title="Seo" percentage={92} />
                            </div>





                    </div>
                  </div>
                  {/* Website Speed and Page Performance end */}

            </div>


        </div>

 
      {popup ? (
        <Modal popup={popup} setPopup={setPopup} loadpopup={loadpopup} data={data} />
      ) : (
        ''
      )}
    </div>
  );
};

export default Business;
