import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Centered from 'layouts/auth/types/Centered';
import React, { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import Checkbox from 'components/checkbox';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate=useNavigate()
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async(e) => {
    e.preventDefault();
    let url='https://dcp-backend.onrender.com/wrapper/login';
    // let url='https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=userchecknew'
    let psw=btoa(password)
    try {
      const res = await axios.post(url, {
        username:email,
          psw
      });

      
      if (res && res.data.status === "success") {
       if(res.data.user.status==0){
        localStorage.setItem("auth", JSON.stringify(res.data.user));
        localStorage.setItem("auth2", JSON.stringify(res.data.data));
        localStorage.setItem("location", JSON.stringify(res.data.user));
        console.log(res)

        navigate("/reset-password");
       }
       else{
        localStorage.setItem("auth", JSON.stringify(res.data.user));
        localStorage.setItem("auth2", JSON.stringify(res.data.data));
        localStorage.setItem("location", JSON.stringify(res.data.user));
        console.log(res)

        navigate("/");
       }
      } else {
        alert("mismatch data")
      }
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };

  const handleForgetPassword=()=>{
      navigate("/forgetpassword")
  }

  return (
    <Centered
      // bgImage="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      maincard={
        <Card extra="max-w-[90%] md:max-w-[800px] md:w-[90%] h-max mx-auto md:mx-auto mt-[50px] 2xl:mt-[100px] 3xl:mt-[200px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          
            <div className="grid grid-cols-1 lg:grid-cols-5 gap-10 items-center">

                <div className="col-span-3">
                  <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
                    Sign In
                  </h3>
                  <p className="mb-5 ml-1 text-base text-gray-600">
                    Enter your email and password to sign in!
                  </p>
                  {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-700 dark:text-white">
                    <div className="rounded-full text-xl">
                      <FcGoogle />
                    </div>
                    <p className="text-sm font-medium text-navy-700 dark:text-white">
                      Sign In with Google
                    </p>
                  </div> 
                  <div className="mb-4 flex items-center gap-3">
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                    <p className="text-base font-medium text-gray-600"> or </p>
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                  </div>*/}
                  <div className={'mb-3'}>
                    <label className={`text-sm text-navy-700 dark:text-white`}>
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder={'johndoe@gmail.com'}
                      value={email}
                      onChange={handleEmailChange}
                      className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none 
                `}
                    />
                  </div>
                  <div className={'mb-3'}>
                    <label className={`text-sm text-navy-700 dark:text-white`}>
                    Password
                    </label>
                    <input
                      type="password"
                      placeholder={'password'}
                      value={password}
                      onChange={handlePasswordChange}
                      className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none 
                `}
                    />
                  </div>
                
                  <div className="mt-2 flex items-center justify-between px-2">
                    <div className="flex items-center">
                      <Checkbox />
                      <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                        Keep me logged In
                      </p>
                    </div>
                    {/* <a
                      className="text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white cursor-pointer"
                     href='/reset-password'
                    >
                      Reset password
                    </a> */}
                    <a
                      className="text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white cursor-pointer"
                      onClick={handleForgetPassword}
                    >
                      Forgot password?
                    </a>
                  </div>
                  <button
                    className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={handleLogin}
                  >
                    Sign In
                  </button>
                </div>
                <div className="col-span-2">
                    <img src="https://www.qualiconvert.com/images/logo_dark.png" class="w-full" />
                </div>

            </div>
          
          {/* <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Not registered yet?
            </span>
            <a
              href=" "
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
            >
              Create an Account
            </a>
          </div> */}
        </Card>
      }
    />
  );
};

export default Login;
