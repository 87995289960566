import Card from "components/card";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Password = () => {
  const navigate=useNavigate()
  let user = JSON.parse(localStorage.getItem('auth'));
  const[user_email,setuser_email]=useState(user?.email);
  const [changepassword,setchangepassword]=useState('');
  const [confirmpassword,setconfirmpassword]=useState('');

  const handlePasswordChange = (event) => {
    setchangepassword(event.target.value);
  };

  const handlePasswordConfirm = (event) => {
    setconfirmpassword(event.target.value);
  };

  const handleChangePassword=async(e)=>{
    e.preventDefault();
    if(changepassword==confirmpassword){
      try {
        let url="https://dcp-backend.onrender.com/wrapper/resetpassword?";
        // let url="https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=updatepassword&"
        const res = await axios.get(`${url}newpassword=${confirmpassword}&email=${user_email}`);
       
        console.log(res);
        if (res && res.data.status === "success") {
            alert(res.data.status)
            navigate("/login")
        } else {
          console.log(res.data.message)
        }
      } catch (error) {
        console.log(error);
      
      }
    }
    else{
      alert("missmatch")
    }
  }

 

  useEffect(()=>{
   
    setuser_email(user?.email)
    },[])

  return (
    <Card extra={"w-full mt-3 px-6 py-6"}>
      {/* Header */}
      <div className="w-full px-2">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Change Password
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can set your new password
        </p>
      </div>
      {/* inputs */}
      <div className="mt-9 grid grid-cols-1 gap-3">
        {/* <InputField
          label="Old Password"
          placeholder="Your Old Password"
          id="username"
          type="text"
        /> */}
        <InputField
          label="New Password"
          placeholder="Your New Password"
          id="username"
          value={changepassword}
          onChange={handlePasswordChange}
          type="text"
        />
        <InputField
          label="New Password Confirmation"
          placeholder="Confirm New Password"
          id="username"
          value={confirmpassword}
          onChange={handlePasswordConfirm}
          type="text"
        />

        <div className="mt-2 flex w-full justify-end">
          <button  onClick={handleChangePassword} className="rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Change Password
          </button>
        </div>
      </div>
      {/* full width inputs */}
    </Card>
  );
};

export default Password;
