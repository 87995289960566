import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import RTLLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";
import { useState, useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import Login from "components/Authentication/Login";
import ForgotPasswordCenter from "components/Authentication/Forgetpassword"
import ReviewUs from "./views/admin/main/Reputation/ReviewMe/ReviewStatus/Review_us";
import NewUser from "components/Authentication/NewUser";
import PasswordModal from "components/Authentication/PasswordModal";
import ResetPassword from "components/Authentication/ResetPassword";
// import SignInCenter from "views/auth/signIn/SignInCenter";
const App = () => {
  // Create a new context
  const [themeApp, setThemeApp] = useState({
    "--background-100": "#FFFFFF",
    "--background-900": "#070f2e",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#E9E3FF",
    "--color-100": "#C0B8FE",
    "--color-200": "#A195FD",
    "--color-300": "#8171FC",
    "--color-400": "#7551FF",
    "--color-500": "#422AFB",
    "--color-600": "#3311DB",
    "--color-700": "#2111A5",
    "--color-800": "#190793",
    "--color-900": "#11047A",
  });
  const [mini, setMini] = useState(false);



  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    //eslint-disable-next-line
  }, [themeApp]);

  const navigate=useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check for authentication information in localStorage
  useEffect(() => {
    const authToken = localStorage.getItem("auth");

    if (!authToken && !window.location.pathname.includes(`/reviewus`)&&!window.location.pathname.includes(`/forgetpassword`)&&!window.location.pathname.includes(`/newuser`)&&!window.location.pathname.includes(`/login`)&&!window.location.pathname.includes(`/confirm-password`)&&!window.location.pathname.includes(`/newuser`)&&!window.location.pathname.includes(`/login`)&&!window.location.pathname.includes(`/reset-password`)) {
      // If not authenticated and not accessing /reviewus, redirect to "/login"
       navigate("/login");
    }
  }, [navigate]);



  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={
          <AdminLayout
            setMini={setMini}
            mini={mini}
            theme={themeApp}
            setTheme={setThemeApp}
          />
        }
      />
      <Route
        path="rtl/*"
        element={
          <RTLLayout
            setMini={setMini}
            mini={mini}
            theme={themeApp}
            setTheme={setThemeApp}
          />
        }
      />
    <Route path="/reviewus/:params" element={<ReviewUs /> } />
      <Route path="/forgetpassword" element={<ForgotPasswordCenter />} />
      <Route path="/" element={<Navigate to="/admin" replace />} />
      <Route path="/login"  element={<Login/>}/>
      <Route path="/newuser"  element={<NewUser/>}/>
      <Route path="/confirm-password"  element={<PasswordModal/>}/>
      <Route path="/reset-password"  element={<ResetPassword/>}/>
    </Routes>
  );
};

export default App;
