import SearchIcon from "components/icons/SearchIcon";
import React, { useMemo, useState } from "react";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Modal3 from './Modal3';

function SearchTableUsers(props) {
  console.log(props)
  const { columnsData, tableData, status_val } = props;
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [issues_data, setText] = useState([])
  function loadremaining() {
    // document.getElementById('leads_data').innerHTML = rev_data;

    setToggle1(!toggle1);
  }

  function loadpopup(row) {
    console.log(row)
    setText(row);
    setToggle1(!toggle);
  }
  function handleToggle() {
    setToggle1(!toggle1);
  }



  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,
    setPageSize,

    setGlobalFilter,
    state,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <div className="h-full w-full">

      {/* table */}
      <div className="mt-[14px] h-full w-full overflow-x-scroll 2xl:overflow-x-hidden">
        <table {...getTableProps()} className="w-full font-dm">
          <thead className="w-full bg-gray-50">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="items-center 1border-b border-gray-200 dark:!border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-3 py-2.5 "
                    key={index}
                  >
                    <div className="text-left text-sm font-bold uppercase1 tracking-wide text-gray-800">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {toggle1 ?
            <Modal3 toggle1={toggle1} setToggle1={setToggle1} issues_data={issues_data} setText={setText} handleToggle={handleToggle} loadpopup={loadpopup} loadremaining={loadremaining} /> : ""}

          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className="border-b border-gray-200 dark:!border-white/10"
                  {...row.getRowProps()}
                  key={index}
                >

                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Site/Directory") {
                      data = (
                        <div className="flex w-full items-center gap-[14px]">
                          {/* <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-blue-300">
                            <img
                              className="h-full w-full rounded-full"
                              src={cell.value[1]}
                              alt=""
                            />
                          </div> */}
                          <p className=" text-sm text-gray-900">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "Name") {
                      data = (
                        <div className="w-full text-sm text-gray-900">
                          {cell.value=='n/a'||cell.value==''?'--':cell.value}
                        
                        </div>
                      );
                    } else if (cell.column.Header === "Adress") {
                      data = (
                        <div className="w-full text-sm text-gray-900">
                          {cell.value==''?'--':cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Postcode") {
                      data = (
                        <div className="w-full text-sm text-gray-900">
                            {cell.value=='n/a'||cell.value==''?'--':cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Phone") {
                      data = (
                        <div className="w-full text-sm text-gray-900">
                          {cell.value=='n/a'||cell.value==''?'--':cell.value}
                        </div>
                      );

                    } else if (cell.column.Header === "Status") {
                      data = (
                        <div className="w-full text-sm text-gray-900">
                          
                          {cell.value.length > 0 ? (
                            <a href="#!" onClick={() => loadpopup(row.original)}>
                              <i className="fa-solid fa-triangle-exclamation text-red-500 text-[24px]"></i>
                            </a>
                          ) : (
                            cell.row.original['business-name']=='n/a' &&
                            cell.row.original['address'] == ''&&
                            cell.row.original['postcode'] == 'n/a' &&
                            cell.row.original['telephone'] =='n/a' ?(
                              <i className="fa-solid fa-question text-[24px]" ></i>
                              
                            ) : (
                              <i className="fa-solid fa-circle-check text-green-500 text-[24px]" onClick={() => loadpopup(row.original)}></i>
                            )
                          )}
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="px-3 py-2.5 text-sm text-gray-900"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-3">
          <p className="> Show rows per page text-sm text-gray-700">
            Show rows per page{" "}
          </p>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-[32px] w-[75px] rounded-[20px] border border-gray-200 px-2 text-gray-700 dark:!border-white/10 dark:bg-navy-800"
            name=""
            id=""
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>

          </select>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
          >
            <MdChevronLeft />
          </button>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchTableUsers;
