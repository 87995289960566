import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Centered from 'layouts/auth/types/Centered';
import React, { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import Checkbox from 'components/checkbox';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PasswordModal from './PasswordModal';

const NewUser = () => {
  const sampleEmails = ['demo@chesshousedental.com', 'example2@example.com']; 

    const navigate=useNavigate()
  const [email, setEmail] = useState();


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

 const Emailexists=async()=>{
  let url = `https://dcp-backend.onrender.com/wrapper/newuserfindone?useremail=${email}`;
  const res=await axios.get(url)
 return res;
 }
//  useEffect(() => {
//   const delayDebounceFn = setTimeout(() => {
//       Emailexists();
//   }, 1000);

//   return () => clearTimeout(delayDebounceFn);
// }, [email]);

  const handleNext = async(e) => {
    e.preventDefault();
   const res=await  Emailexists();
   const message=res?.data?.message
   console.log(message)

  if (message==="Existing User") {
   
    navigate('/login');
  } else {
    navigate("/confirm-password", { state: { email: email } });
  }
 
  };


  return (
    <Centered
      // bgImage="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      maincard={
        <Card extra="max-w-[90%] md:max-w-[800px] md:w-[90%] h-max mx-auto md:mx-auto mt-[50px] 2xl:mt-[100px] 3xl:mt-[200px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          
            <div className="grid grid-cols-1 lg:grid-cols-5 gap-10 items-center">

                <div className="col-span-3">
                  <h3 className="mb-[10px] text-4xl font-bold text-gray-900 dark:text-white">
                   {/* New User? */}Email
                  </h3>
                  {/* <p className="mb-5 ml-1 text-base text-gray-600">
                    Enter your email to sign in!
                  </p> */}
                  {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-700 dark:text-white">
                    <div className="rounded-full text-xl">
                      <FcGoogle />
                    </div>
                    <p className="text-sm font-medium text-navy-700 dark:text-white">
                      Sign In with Google
                    </p>
                  </div> 
                  <div className="mb-4 flex items-center gap-3">
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                    <p className="text-base font-medium text-gray-600"> or </p>
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                  </div>*/}
                  <div className={'mb-3'}>
                    {/* <label className={`text-sm text-navy-700 dark:text-white`}>
                      Email
                    </label> */}
                    <input
                      type="email"
                      placeholder={'johndoe@gmail.com'}
                      value={email}
                      onChange={handleEmailChange}
                      className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none 
                `}
                    />
                  </div>
                
                
              
                  <button
                    className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={handleNext}
                  >
                 Next
                  </button>
                </div>
                <div className="col-span-2">
                    <img src="https://www.qualiconvert.com/images/logo_dark.png" class="w-full" />
                </div>

            </div>
          
          {/* <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Not registered yet?
            </span>
            <a
              href=" "
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
            >
              Create an Account
            </a>
          </div> */}
         
        </Card>
      }
     
    />
  );
};

export default NewUser;
