import Card from "components/card";
import InputField from "components/fields/InputField";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Centered from 'layouts/auth/types/Centered';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const ResetPassword= () => {
  const navigate=useNavigate()
  let user = JSON.parse(localStorage.getItem('auth'));
  const[user_email,setuser_email]=useState(user?.email);
  const [changepassword,setchangepassword]=useState('');
  const [confirmpassword,setconfirmpassword]=useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const handlePasswordChange = (event) => {
    setchangepassword(event.target.value);
  };

  const handlePasswordConfirm = (event) => {
    setconfirmpassword(event.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChangePassword=async(e)=>{
    e.preventDefault();
    if(changepassword==confirmpassword){
      try {
        let url="https://dcp-backend.onrender.com/wrapper/resetpassword?";
        // let url="https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=updatepassword&"
        const res = await axios.get(`${url}newpassword=${confirmpassword}&email=${user_email}`);
       
        console.log(res);
        if (res && res.data.status === "success") {
            alert(res.data.status)
            navigate("/")
        } else {
          console.log(res.data.message)
        }
      } catch (error) {
        console.log(error);
      
      }
    }
    else{
      alert("missmatch")
    }
  }

 

  useEffect(()=>{
   
    setuser_email(user?.email)
    },[])

  return (
    


<>

    <Centered
      // bgImage="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      maincard={
        <Card extra="max-w-[90%] md:max-w-[800px] md:w-[90%] h-max mx-auto md:mx-auto mt-[50px] 2xl:mt-[100px] 3xl:mt-[200px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <div className="grid grid-cols-1 items-center gap-10 lg:grid-cols-5">
            <div className="col-span-3">
              {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-700 dark:text-white">
                    <div className="rounded-full text-xl">
                      <FcGoogle />
                    </div>
                    <p className="text-sm font-medium text-navy-700 dark:text-white">
                      Sign In with Google
                    </p>
                  </div> 
                  <div className="mb-4 flex items-center gap-3">
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                    <p className="text-base font-medium text-gray-600"> or </p>
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                  </div>*/}
              <div className={'mb-3'}>
                <label className={`text-sm text-navy-700 dark:text-white`}>
                  Enter password
                </label>
                <div class="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={''}
                    value={changepassword}
                    onChange={handlePasswordChange}
                    className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none 
                `}
                  />
                  <span className="absolute right-0 top-0 pr-3 pt-2">
                    <button onClick={togglePasswordVisibility}>
                      {!showPassword ? <FaEye/> : <FaEyeSlash/>}
                    </button>
                  </span>
                </div>
              </div>
              <div className={'mb-3'}>
                <label className={`text-sm text-navy-700 dark:text-white`}>
                  Please re-enter your password
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder={''}
                    value={confirmpassword}
                    onChange={handlePasswordConfirm}
                    className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                  />
                  <span className="absolute right-0 top-0 pr-3 pt-3">
                    <button onClick={toggleConfirmPasswordVisibility}>
                      {!showConfirmPassword ? <FaEye/> : <FaEyeSlash/>}
                    </button>
                  </span>
                </div>
              </div>

              <button
                className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleChangePassword}
              >
                Continue
              </button>
              {error && <p className="text-red-600">{error}</p>}
            </div>
            <div className="col-span-2">
              <img
                src="https://www.qualiconvert.com/images/logo_dark.png"
                class="w-full"
              />
            </div>
          </div>

          {/* <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Not registered yet?
            </span>
            <a
              href=" "
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
            >
              Create an Account
            </a>
          </div> */}
        </Card>
      }
    />


</>


  );
};

export default ResetPassword;
