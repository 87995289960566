import { MdShare, MdEdit, MdDownload } from "react-icons/md";
import Content from "./Content";
import Card from "components/card";
const Receipt = () => {
  return (
    <Card extra={"w-full h-full px-6 py-6"}>
  
      {/* content */}
      <div className="h-full w-full">
        <Content />
      </div>
    </Card>
  );
};

export default Receipt;
