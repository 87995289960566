import { tableColumnsReceipt } from "views/admin/main/ecommerce/orderDetails/variables/tableColumnsReceipt";
import tableDataReceipt from "views/admin/main/ecommerce/orderDetails/variables/tableDataReceipt.json";
import ReceiptTable from "./ReceiptTable";

const Content = () => {
  return (
    <div className="w-full bg-white pb-[57px] dark:!bg-navy-800">
    
      {/* Note */}
      <div className="mt-3 flex h-fit flex-col items-stretch md:!flex-row md:!px-[34px] lg:!mt-8 lg:gap-5  xl:!mt-4 xl:justify-between xl:gap-0">
        <div>
          <p className="text-lg font-bold text-navy-700 dark:text-white mb-3">
            Take control of your reputation 
          </p>
          <p className=" w-full text-gray-800 mb-3">
           BrightLocal makes it easy for you / yours clients to get more reviews and enhance your online reputation
          </p>

          <p className=" w-full text-gray-800 ">
        You can send personalized campaigns that gather valuable feedback and guide customers to write reviews on the sitesthat are most important to you
          </p>
        </div>

      
      </div>
    </div>
  );
};

export default Content;
