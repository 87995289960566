import Banner from "./components/Banner";
import Information from "./components/Information";
import Password from "./components/Password";
import Social from "./components/Social";
import React, { useEffect, useState } from 'react';

const ProfileSetting = () => {
  let user = JSON.parse(localStorage.getItem('auth'));
  const[user_email,setuser_email]=useState(user?.email);
  const [changepassword,setchangepassword]=useState('');
  const [confirmpassword,setconfirmpassword]=useState('');
  

  useEffect(()=>{
   
    setuser_email(user?.email)
    },[])
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 lg:grid-cols-2">
      <div className="rounded-[20px]">
        <div>
          <Banner />
        </div>
        <div>
          <Password />
        </div>
      </div>
      <div className="">
      <div className="mt-3">
          <Information />
        </div>
        
      </div>
    </div>
  );
};

export default ProfileSetting;
