import React from 'react';
import ReactApexChart from 'react-apexcharts';

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    const { all_data } = this.props;
  console.log(all_data)
    // Aggregate data by month and calculate averages
    const monthlyData = all_data.reduce((acc, entry) => {
      const month = entry.date.substring(0, 7); // Extract YYYY-MM
      if (!acc[month]) {
        acc[month] = { date: month, views: 0, sessions: 0, users: 0, count: 0 };
      }
      acc[month].views += entry.views;
      acc[month].sessions += entry.sessions;
      acc[month].users += entry.users;
      acc[month].count += 1;
      return acc;
    }, {});
  
    const averagedData = Object.values(monthlyData).map((month) => ({
      name: month.date, // Assigning the date as the 'name'
      views: month.views / month.count,
      sessions: month.sessions / month.count,
      users: month.users / month.count,
    }));
    // Dynamically extract and sort month names
    const monthNames = Object.keys(monthlyData).sort((a, b) => a.localeCompare(b));
  
    // Update x-axis categories with dynamically determined month names
    const newOptions = {
      ...this.props.chartOptions,
      xaxis: {
        ...this.props.chartOptions.xaxis,
        categories: monthNames,
        labels: {
          style: {
            colors: "#A3AED0",
            fontSize: "12px",
            fontWeight: "500",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    };
  
    this.setState({
      chartData: averagedData,
      chartOptions: newOptions,
    });
  }
  

  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="line"
        width="100%"
        height="100%"
      />
    );
  }
}

export default LineChart;
