import React, { useEffect,useState } from 'react';
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import Card from "components/card";
import image2 from '../../../../../../assets/img/users/logo.png';
import ReactStars from "react-rating-stars-component";
import Modal from './Modal';
import { useParams } from 'react-router-dom';

const ReviewUs = (props) => {
  let user = JSON.parse(localStorage.getItem('auth'));
  let [toggle1,setToggle1]=useState(false);
  const [text,setText]=useState();
  let { username } = useParams();
  const [decodedData,setdecodedData]=useState()

  // console.log(username)

  useEffect(() => {
    // Decode and parse the URL parameter to get the object
    const params = new URLSearchParams(window.location.search);
    const encodedData = params.get('data');
    const decodedDatavalue = JSON.parse(decodeURIComponent(encodedData));
    setdecodedData(decodedDatavalue)
    
  }, []);

  const ratingChanged = (newRating) => {
    var roundedRating = Math.round(newRating);
     console.log(roundedRating)
    if (roundedRating <= 3) {
      console.log(roundedRating);
      setText("form");
      setToggle1(!toggle1);
    } else {
      console.log(roundedRating);
      setText("");
      setToggle1(!toggle1);
    }
  };
  return (
    <div className="bg-gradient-to-r from-[#060017] via-[#1d1752] to-[#0792d1] h-[100vh] flex flex-col md:flex-row items-center justify-center ">

          <div className="!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-100 dark:shadow-none  dark:!bg-navy-800 dark:text-white  max-w-[90%] md:max-w-[800px] md:w-[90%] h-max mx-auto md:mx-auto mt-[50px] 2xl:mt-[100px] 3xl:mt-[200px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
               
                <div className="w-64 text-center p-2.5 shadow-xl bg-white mx-auto mb-10">
                  <img src={user?.logo_path} className="w-full h-auto mx-auto" />
                </div>
                <h2 className="text-5xl font-bold text-navy-700 text-center mb-3">Review Us</h2> 
                <h4 className="text-xl font-medium text-gray-700 text-center mb-4">Please leave us a review and let us know about your experience.</h4> 
                <div className="w-full text-center">
                      <ReactStars
                        classNames={'mx-auto'}
                        count={5}
                        onChange={ratingChanged}
                        size={120}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#FFD700"
                      />
                </div>               

          </div> 
          {toggle1 ? (
              <Modal
              toggle1={toggle1}
              setToggle1={setToggle1}
              ratingChanged={ratingChanged}
              setText={setText}
              text={text}
              decodedData={decodedData}
              />
            ) : (
              ''
            )}
    </div>
  );
};
export default ReviewUs;