import React from 'react';
const Modal3 = (props) => {
    console.log(props+"sm");
    return (
      <div className="!z-10 fixed inset-0 flex items-center justify-center overflow-y-auto">
        <div className="!z-10 fixed inset-0 bg-gray-800 bg-opacity-20 backdrop-blur-sm backdrop-filter"></div> 
  
                 
   
     
      <div class="!z-10 relative w-full lg:w-1/2 rounded bg-white p-8 shadow-md h-fit">
      <h2 class="text-black text-base font-[700] mb-2">Business Details </h2>  
      <button
              className="absolute right-2 top-2 text-gray-600 hover:text-gray-800"
              onClick={props.handleToggle}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          <table class="w-full text-left rtl:text-right">
              <thead class="font-semibold uppercase1 bg-gray-50 ">
                  <tr>
                      <th scope="col" class="px-6 py-3">
                          Name
                      </th>
                      <th scope="col" class="px-6 py-3">
                          Address
                      </th>
                      <th scope="col" class="px-6 py-3">
                          
                          Postcode
                      </th>                        
                      <th scope="col" class="px-6 py-3">
                          Phone
                      </th>                         
                  </tr>
              </thead>
              <tbody>
                  <tr class="bg-white border-b border-solid border-[#e5e5e1]">
                    {console.log(props.issues)}
                      <th scope="row" class="px-6 py-4 text-gray-900">
                          { props.issues_data['need_to_fix'] && props.issues_data['need_to_fix'].includes("name") ?(
                              <a href="{props.issues_data['url']}" target="_blank" class="text-red-500 break-all">{props.issues_data['business-name'] }</a>)
                          :(
                              <a href="{props.issues_data['url']}" target="_blank" class="text-black-500 break-all">{props.issues_data['business-name'] }</a>
                          )}
                      </th>
                      <td class="px-6 py-4">
                          { props.issues_data['need_to_fix'] && props.issues_data['need_to_fix'].includes("address") ?(
                              <span  class="text-red-500 break-all">{props.issues_data['address'] }</span>)
                          
                          :(    <span  class="text-black-500 break-all">{props.issues_data['address'] }</span>
                          )}
                      </td>
                      <td class="px-6 py-4">
                          {['need_to_fix'] && props.issues_data['need_to_fix'].includes("postcode") ?(
                              <span  class="text-red-500 break-all">{props.issues_data['postcode'] }</span>)
                          :(
                              <span  class="text-black-500 break-all">{props.issues_data['postcode'] }</span>
                          )}
                      </td>
                      <td class="px-6 py-4">
                          {props.issues_data['need_to_fix'] && props.issues_data['need_to_fix'].includes("telephone")?(
                          <span  class="text-red-500 break-all">{props.issues_data['telephone'] }</span>)
                      
                      : (   <span  class="text-black-500 break-all">{props.issues_data['telephone'] }</span>)
                      }
                      </td> 
                  </tr> 
                   
                   
  
  
  
              </tbody>
          </table>
      </div>
          
  
          
       
      </div>
    );
  };

  export default Modal3;