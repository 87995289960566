import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Centered from 'layouts/auth/types/Centered';
import React, { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import Checkbox from 'components/checkbox';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
const PasswordModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state.email;
  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    if (password !== confirmpassword) {
      setError('Passwords do not match');
      return; // Prevent further execution
    }
    let url = 'https://dcp-backend.onrender.com/wrapper/newuserpasswordupdate';
    // let url='https://app.legaciestechno.com/qualiconvert_dcp/api/get_data.php?type=userchecknew'

    try {
      const res = await axios.post(url, {
        useremail: email,
        password: password,
      });
      if (res && res.data.status === "success") {
      
        localStorage.setItem("auth", JSON.stringify(res?.data?.user));
        localStorage.setItem("auth2", JSON.stringify(res?.data?.data));
        localStorage.setItem("location", JSON.stringify(res?.data?.user));
      
        navigate("/");
      } else {
        alert("something went wrong")
      }
      // navigate("/")
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Centered
      // bgImage="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
      maincard={
        <Card extra="max-w-[90%] md:max-w-[800px] md:w-[90%] h-max mx-auto md:mx-auto mt-[50px] 2xl:mt-[100px] 3xl:mt-[200px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <div className="grid grid-cols-1 items-center gap-10 lg:grid-cols-5">
            <div className="col-span-3">
              {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-700 dark:text-white">
                    <div className="rounded-full text-xl">
                      <FcGoogle />
                    </div>
                    <p className="text-sm font-medium text-navy-700 dark:text-white">
                      Sign In with Google
                    </p>
                  </div> 
                  <div className="mb-4 flex items-center gap-3">
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                    <p className="text-base font-medium text-gray-600"> or </p>
                    <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                  </div>*/}
              <div className={'mb-3'}>
                <label className={`text-sm text-navy-700 dark:text-white`}>
                  Enter password
                </label>
                <div class="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={''}
                    value={password}
                    onChange={handlePasswordChange}
                    className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none 
                `}
                  />
                  <span className="absolute right-0 top-0 pr-3 pt-2">
                    <button onClick={togglePasswordVisibility}>
                      {!showPassword ? <FaEye/> : <FaEyeSlash/>}
                    </button>
                  </span>
                </div>
              </div>
              <div className={'mb-3'}>
                <label className={`text-sm text-navy-700 dark:text-white`}>
                  Please re-enter your password
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder={''}
                    value={confirmpassword}
                    onChange={handleConfirmPasswordChange}
                    className={`mt-1 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none `}
                  />
                  <span className="absolute right-0 top-0 pr-3 pt-3">
                    <button onClick={toggleConfirmPasswordVisibility}>
                      {!showConfirmPassword ? <FaEye/> : <FaEyeSlash/>}
                    </button>
                  </span>
                </div>
              </div>

              <button
                className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleContinue}
              >
                Continue
              </button>
              {error && <p className="text-red-600">{error}</p>}
            </div>
            <div className="col-span-2">
              <img
                src="https://www.qualiconvert.com/images/logo_dark.png"
                class="w-full"
              />
            </div>
          </div>

          {/* <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Not registered yet?
            </span>
            <a
              href=" "
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
            >
              Create an Account
            </a>
          </div> */}
        </Card>
      }
    />
  );
};

export default PasswordModal;
